.ps-post {
    margin-bottom: 30px;

    &__badge {
        width: 40px;
        height: 40px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        background-color: #000;

        i {
            color: #ffffff;
            font-size: 20px;
        }
    }

    &__image {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            max-width: 100%;
        }
    }

    &__overlay {
        @include max-area;
        border-radius: var(--border-radius-medium);
    }

    &__author {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
            max-width: 38px;
            border-radius: 50%;
        }

        .ps-post__author-img {
        }

        .ps-post__author-name {
            padding-left: 0.5em;

            a {
                color: $color-heading;
            }
        }
    }

    &__categories {
        border-radius: var(--border-radius-small);

        a {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-1st);
            text-transform: uppercase;
        }
    }

    &__meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 10px;

        .ps-post__categories {
            position: relative;
            padding-right: 10px;
            margin-right: 10px;

            &:after {
                content: '';
                @include vertical-align();
                right: 0;
                width: 1px;
                height: 16px;
                background-color: var(--color-gray);
            }
        }

        span {
            font-size: 13px;
            color: var(--color-text-2);
        }
    }

    &__title {
        margin-bottom: 0;
        font-size: 18px;
        color: var(--color-heading);
        line-height: 1.2em;
        font-weight: 500;

        a {
            display: block;
            font-size: inherit;
            color: inherit;
            position: relative;
            /*--max-lines: 2;*/
            /*min-height: calc(1.6em * var(--max-lines));*/
            &:hover {
                color: $color-1st;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__desc {
        p {
            color: $color-text;
            font-family: $font-2nd;

            a {
                color: #000000;

                &:hover {
                    color: $color-1st;
                }
            }
        }
    }

    &__morelink {

        font-size: 14px;
        font-weight: 400;
        color: $color-text;
        vertical-align: middle;
        text-transform: uppercase;
        transition: all 0.4s ease;

        &:hover {
            color: $color-1st;
        }

        &.line {
            position: relative;
            padding-left: 26px;
            font-size: 14px;

            &:before {
                content: '';
                @include vertical-align();
                left: 0;
                width: 20px;
                height: 1px;
                background-color: $color-heading;
                transition: all 0.25s ease;
            }

            i {
                transition: all 0.4s ease;
                @include hidden;
                transform: translateX(-100%);
                font-size: 16px;
            }

            &:hover {
                padding-left: 0;
                transition-delay: 0.1s;

                &:before {
                    @include hidden();
                }

                i {
                    @include show;
                    transform: translateX(0);
                }
            }
        }
    }

    &__image {
        overflow: hidden;
        border-radius: var(--border-radius-medium);
    }

    &__author {
        font-size: 13px;
        color: var(--color-text);

        a {

            margin-left: 0.25em;
            font-size: inherit;
            font-weight: 500;
            color: #000000;

            &:hover {
                color: var(--color-1st);
            }
        }

        i {
            margin-right: 12px;
        }
    }

    &__counter {
        font-size: 13px;
        color: var(--color-text);

        i {
            margin-right: 12px;
        }
    }

    &__bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ps-post__thumbnail {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            transform-origin: 50% 50%;
            transition: all 0.4s $timing-1;
        }

        .ps-post__badge {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 20;
        }

        &:hover {
            .ps-post__overlay {
                background-color: alpha(var(--color-1st), 0.25);
            }
        }
    }

    .ps-post__content {
        padding: 20px 0 0;
    }

    &:hover {
        .ps-post__thumbnail {
            .ps-post__overlay {
                &:after {
                    @include show;
                    background: #ffccf3;
                    background: -moz-linear-gradient(to bottom, #ffccf3 0%, #ffb7ba 100%);
                    background: -webkit-linear-gradient(to bottom, #ffccf3 0%, #ffb7ba 100%);
                    background: linear-gradient(to bottom, #ffccf3 0%, #ffb7ba 100%);
                    opacity: 0.75;
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        &__title {
            font-size: 24px;
        }
    }
}

.ps-post--grid {
    border-radius: 4px;
    overflow: hidden;

    transition: all 0.4s ease;

    .ps-post__title {
        margin-bottom: 20px;
        font-size: 20px;

        a {

            font-weight: 500;
            line-height: 1.4em;
        }
    }

    .ps-post__meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 10px;
    }

    .ps-post__desc {
        margin-bottom: 40px;

        p {
            margin: 0;
        }
    }

    .ps-post__thumbnail {
        position: relative;
        z-index: 9;

        .ps-post__categories {
            position: absolute;
            top: 16px;
            left: 16px;
            z-index: 99;
            padding: 4px 16px;
            background-color: #fff;

            a {
                font-weight: 400;
                text-transform: capitalize;
                color: $color-heading;
            }
        }
    }

    .ps-post__morelink {
        font-weight: 400;
        position: relative;
        line-height: 1.6em;
        color: $color-heading;

        &:hover {
            color: $color-1st;
        }
    }

    &.ps-post--text-only {
        padding: 30px;
        border-radius: 0;
    }

    &.large {
        .ps-post__title {
            font-size: 24px;
        }

        .ps-post__desc {
            p {
                margin-bottom: 0;
            }
        }
    }

    &:hover {
        .ps-post__thumbnail {
            img {
                transform: scale3d(1.05, 1.05, 1.05);
            }
        }

        .ps-post__title {
            a {
                color: $color-1st;
            }
        }
    }

    @media screen and (min-width: 1040px) {
        &.large {
            .ps-post__title {
                font-size: 30px;
            }
        }
    }
}

.ps-post--horizontal {

    display: grid;

    .ps-post__image {

    }

    .ps-post__title {
        margin-bottom: 10px;
        font-size: 18px;

        a {

            font-weight: 500;
            line-height: 1.4em;
        }
    }

    .ps-post__desc {
        p {
            font-size: 14px;
        }
    }

    .ps-post__footer {
        width: 100%;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--color-border);
    }


    &.small {
        grid-template-columns: 60px 1fr;
        grid-gap: 10px;

        .ps-post__categories {
            &:after {
                display: none;
            }

            a {
                font-size: 11px;
                color: var(--color-text);
            }
        }

        .ps-post__meta {
            padding-bottom: 0;
        }

        .ps-post__title {
            margin-bottom: 0;
            overflow: hidden;
            font-size: 13px;
            --max-lines: 2;
            line-height: 1.4em;
            max-height: calc(1.4em * var(--max-lines));
        }

        .ps-post__wrapper {

        }
    }

    @media screen and (min-width: 768px) {
        grid-template-columns: 200px 1fr;
        grid-gap: 20px;

        .ps-post__wrapper {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: 300px 1fr;

        .ps-post__title {
            margin-bottom: 20px;
            font-size: 24px;
        }

        .ps-post__desc {
            p {
                font-size: 15px;
            }
        }
    }

    @media screen and (min-width: 1366px) {
        grid-template-columns: 355px 1fr;
        grid-gap: 40px;

        &.small {
            grid-template-columns: 100px 1fr;
            grid-gap: 20px;

            .ps-post__title {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .ps-post__wrapper {
            padding-top: 20px;
        }
    }
}

.ps-post--only-text {
    display: grid;
    grid-template-columns: 125px 1fr;

    .ps-post__created {
        .ps-post__date {
            display: block;
            margin-bottom: 10px;
            color: var(--color-text-2);
            font-size: 60px;
            font-weight: 300;
            line-height: 1em;
        }

        .ps-post__month-and-year {
            display: block;
            color: var(--color-text-2);
            font-size: 12px;
            font-weight: 500;
            line-height: 1em;
            text-transform: uppercase;
        }
    }

    .ps-post__title {
        margin-bottom: 25px;
        font-weight: 500;

        a {
            font-weight: inherit;
        }
    }


    @media screen and (max-width: 480px) {
        grid-template-columns: 80px 1fr;
        grid-gap: 10px;
        .ps-post__title {

        }
    }
}

/*Detail*/

.ps-post--detail {
    .ps-post__tags {
        padding-top: 25px;

        a {
            display: inline-block;
            vertical-align: top;
            text-align: center;
            margin-right: 10px;
            padding: 4px 10px;
            line-height: 18px;
            font-size: 12px;
            color: var(--color-text);
            border-radius: var(--border-radius-medium);
            background-color: alpha(var(--color-1st), 0.05);

            &:hover {
                color: #ffffff;
                background-color: var(--color-1st);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ps-post__title {
        margin-bottom: 25px;
        font-size: 30px;
    }

    .ps-post__bottom {
        margin-bottom: 25px;
    }

    .ps-document {
        h4 {
            font-size: 18px;

            .ps-highlight {
                font-weight: inherit;
                color: #f5480c;
            }
        }

        ul, ol {

            li {
                font-size: 16px;
                color: var(--color-text);
            }
        }

        p {
            font-size: 16px;

            a {
                color: #00ccff;
                font-weight: inherit;
                font-size: inherit;

                &:hover {
                    text-decoration: underline;
                    color: var(--color-1st);
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-post__tags {
            padding-top: 50px;
        }

        .ps-post__title {
            margin-bottom: 50px;
            text-align: left;
            font-size: 48px;
        }

        .ps-post__bottom {
            margin-bottom: 50px;
        }

        .ps-document {
            h4 {
                font-size: 24px;
            }
        }

    }

}

.ps-post--detail-simple {
    h2 {
        font-family: $font-2nd;
    }

    .ps-post__category {
        padding-bottom: 16px;
        color: $color-heading;
        text-transform: uppercase;
        font-size: 12px;
    }

    .ps-post__meta {
        p {
            margin-bottom: 6px;
            font-size: 12px;
            font-weight: 300;
            text-transform: uppercase;

            a {
                color: $color-heading;

                &:hover {
                    color: $color-1st;
                }
            }
        }

        span {
            font-weight: inherit;
            margin-right: 10px;
            font-size: 12px;

            &:after {
                content: '-';
                padding-left: 10px;
            }

            &:last-child {
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

            strong {
                font-weight: 500;
                display: inline-block;
                margin-left: 0.5em;
                color: $color-heading;
            }
        }
    }

    .ps-post__header {
        text-align: left;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #ddd;
    }
}

.ps-post--detail-has-bg {
    h2 {
        color: var(--color-text);
        font-weight: 600;
    }

    .ps-post__category {
        display: inline-block;
        margin-bottom: 10px;
        color: $color-1st;
        text-transform: uppercase;
    }

    .ps-post__meta {
        span {
            a {
                color: var(--color-text);
            }
        }
    }

    .ps-post__header {
        min-height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
    }

    .ps-post__header-wrapper {
        padding: 50px 30px;
        width: 100%;
        max-width: 960px;
        background-color: rgba(#000, 0.85);
    }

    .ps-post__wrapper {
        max-width: 960px;
        margin: 0 auto;
        padding: 0 15px;
    }

    @media screen and (min-width: 768px) {
        h2 {
            font-size: 30px;
        }
    }

    @media screen and (min-width: 1200px) {
        h2 {
            font-size: 36px;
        }
    }
}

.ps-post--detail-with-hero {
    .ps-post__thumbnail {
        min-height: 50vh;
        position: relative;
        z-index: 10;

        &:before {
            content: '';
            @include max-area();
            z-index: 1;
            @include linear-gradient(to top, rgba(#000, 0.15) 10%, rgba(#000, 0.45) 50%);
        }
    }

    .ps-post__wrapper {
        max-width: 960px;
        margin: 0 auto;
        border-radius: 0;
    }

    .ps-post__meta {
        span {
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.2em;
            text-transform: uppercase;
        }
    }

    .ps-post__header {
        margin-bottom: 20px;

        h2 {
            font-family: $font-2nd;
            margin-bottom: 0;
        }
    }

    .ps-post__content {
        padding: 30px 15px;
        background-color: #fff;
    }

    .ps-post__wrapper {
        padding: 50px 0 0;
    }

    &.ps-second {
        h2 {
            margin-bottom: 20px;
        }

        .ps-post__meta {
            span {
                a {
                    color: #fff;
                }
            }
        }

        .ps-breadcrumb {
            position: relative;
            z-index: 99;
            padding: 0;
            background-color: transparent;

            .container {
                padding-left: 0;
            }

            .breadcrumb {
                li {
                    color: var(--color-text);

                    a {
                        color: var(--color-text);
                    }
                }
            }
        }

        .ps-post__thumbnail {
            position: relative;
            z-index: 9;
        }

        .ps-post__header {
            text-align: left;
            position: relative;
            z-index: 99;
            margin: 0 0 30px;
            width: 100%;
            max-width: 960px;
        }
    }

    @media screen and (min-width: 1200px) {
        h2 {
            color: $color-contrast;
            margin-bottom: 0;
        }

        .ps-post__meta {
            span {
                color: var(--color-text);
            }
        }

        .ps-post__thumbnail {
            min-height: 600px;
        }

        .ps-post__header {
            text-align: left;
            padding-bottom: 30px;
            margin-bottom: 0;
        }

        .ps-post__content {
            padding: 50px 30px;
        }

        .ps-post__wrapper {
            position: relative;
            top: -400px;
            margin-bottom: -400px;
            z-index: 99;
        }

        &.ps-second {
            .ps-post__thumbnail {
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-end;
                align-items: flex-start;
                flex-basis: 100%;
                height: 100%;
                padding-left: calc((100% - 960px) / 2);
                padding-right: calc((100% - 960px) / 2);
            }

            .ps-post__wrapper {
                top: 0;
                margin-bottom: 0;
            }

            .ps-post__content {
                padding: 0;
            }
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-post__header {
            h2 {
                font-size: 50px;
            }
        }

        .ps-post__content {
            padding: 60px 40px;
        }
    }
}
