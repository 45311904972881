.table-responsive {
    @media (min-width: 1200px) {
        overflow-x: initial;
    }
}

.ps-table {
    thead {
        > tr {
            > th {
                padding: 10px 10px;
                font-size: 16px;
                font-weight: 700;
                color: #515356;
                text-transform: uppercase;
                border-bottom: 1px solid #ebebeb;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    tbody {
        > tr {
            > td {
                vertical-align: middle;
                padding: 20px 30px;
                border: none;
                font-size: 14px;
                font-weight: 600;
                color: $color-text;
                border-bottom: 1px solid #ddd;
            }
        }
    }
}

.ps-table--faq {
    border-bottom: 1px solid #eaeaea;

    tbody {
        tr {
            td {
                padding: 20px 15px;
                border: none;
                color: $color-text;
                line-height: 1.8em;

                strong {
                    font-weight: 300;
                    color: $color-heading;
                    font-size: 16px;
                }

                &:first-child {
                    min-width: 270px;

                    h4 {
                        text-transform: uppercase;
                        color: $color-1st;
                    }
                }

                &:nth-child(2) {
                    min-width: 350px;
                    max-width: 375px;
                }
            }
        }
    }
}

.ps-table--whishlist {
    .ps-product--sidebar {
        align-items: center;

        .ps-product__title {
            font-weight: 400;
            font-size: 16px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .ps-btn--outline {
        border-width: 1px;
    }

    .ps-instock {
        text-transform: capitalize;
        color: #690;
        font-weight: 500;
    }

    thead {
        > tr {
            > th {
                border: none;
                border-bottom: 1px solid #eaeaea;
                text-transform: none;
                font-weight: 400;
                color: #bbbbbb;
                letter-spacing: 0.05em;
            }
        }
    }

    tbody {
        > tr {
            > td {
                &:first-child {
                    min-width: 50px;
                    padding-left: 0;

                    a {
                        font-size: 20px;

                        i {
                        }
                    }
                }

                &:last-child {
                    text-align: right;
                    min-width: 240px;
                }
            }
        }
    }
}

.ps-table--shopping-cart {
    .form-group--number {
        max-width: 120px;
    }

    .ps-product--sidebar {
        align-items: center;
    }

    strong {
        font-weight: 500;
        color: $color-heading;
    }

    thead {
        tr {
            th {
                font-size: 12px;
                font-weight: 600;
                color: #ccc;
                border: none;
                text-transform: uppercase;
                border-bottom: 1px solid #eaeaea;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    tbody {
        > tr {
            > td {
                padding: 30px 15px;
                border: none;
                vertical-align: middle;

                &:first-child {
                    min-width: 350px;
                    padding-left: 0;
                }

                &:last-child {
                    text-align: right;

                    a {
                        font-size: 24px;

                        i {
                            font-size: inherit;
                        }
                    }
                }
            }
        }
    }
}

.ps-table--product-info {
    border: 1px solid #eaeaea;

    tbody {
        tr {
            td {
                padding: 10px 15px;
                color: $color-heading;
                font-weight: 400;

                &:first-child {
                    max-width: 150px;
                    width: 150px;
                    font-weight: 600;
                    background-color: #eaeaea;
                }
            }
        }
    }
}

.ps-table--specification {
    tbody {
        tr {
            td {
                font-weight: 400;
                color: var(--color-heading);
                padding-left: 0;
                padding-right: 0;
                font-size: var(--font-size-default);
                &:first-child {
                    width: 40%;
                }
                &:last-child {
                    width: 60%;
                    font-weight: 600;
                }
            }
        }
    }
}
