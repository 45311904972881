// @import url("assets/fonts/fontawesome-pro/css/all.min.css");

@import 'variables';
@import 'styles-finderland';

// html,
// body {
//   height: 100%;
// }

// body {
//   font-family: Arial;
//   margin: 0;
//   font-size: 16px;
//   line-height: 2;
// }

// * {
//   outline: none !important;
//   -webkit-overflow-scrolling: touch;
// }

// img {
//   max-width: 100%;
// }

// .ssr-not-loaded {
//   display: none !important;
// }

// /* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";
// @import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~font-awesome/scss/font-awesome.scss";

// //@import "assets/findhouse/css/theme-style.scss";
// //@import "assets/findhouse/css/responsive.scss";

// // REM - #ff4772;

// .text-primary {
//   color: $rem-pink !important;
// }

// .envelope-icon {
//   font-size: 1.6rem;
// }

// // FindHouse - $rem-pink;

// ng-select.ng-select {
//   > div.ng-select-container {
//     // &.ng-has-value {
//     border: 1px solid rgb(235, 235, 235);
//     border-radius: 8px;
//     color: #484848;
//     font-size: 14px;
//     font-family: "Nunito";
//     height: 52px;
//     line-height: 42px;
//     padding-left: 10px;
//   }
//   //   }
// }

// ng-select.ng-select .ng-select-container .ng-value-container .ng-placeholder {
//   color: #484848;
// }

// ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
//   color: #484848;
//   opacity: 1; /* Firefox */
// }

// :-ms-input-placeholder { /* Internet Explorer 10-11 */
//   color: #484848;
// }

// ::-ms-input-placeholder { /* Microsoft Edge */
//   color: #484848;
// }

// ng-select.ng-select {
//   &.ng-select-opened {
//     > .ng-select-container {
//         .ng-arrow {
//           top: -2px !important;
//           border-color: transparent transparent #484848  !important;
//           border-width: 0 4px 4px  !important;
//         }
//     }
//   }

//   .ng-arrow-wrapper {
//     &:hover {
//       border-top-color: #666;
//     }

//     .ng-arrow {
//       display: inline-block;
//       height: 0;
//       pointer-events: none;
//       position: relative;
//       width: 0;

//       border-color: #484848 transparent transparent;
//       border-style: solid;
//       border-width: 4px 4px 2.5px;
//     }
//   }
// }

// .ng-select.ng-select-single .ng-select-container {
//   height: auto;
// }

// .ng-select-sort{
//   .ng-select.ng-select-single {
//     display:inline-block;
//      .ng-select-container {
//       height: auto;
//       border: none;
//     }

//       .ng-dropdown-panel {
//         .ng-option {
//           font-size: 14px;
//           text-align: left;
//           &.ng-option-marked {
//             background-color: #f7f7f7;
//             color: #484848;
//           }
//           &.ng-option-selected {
//             background-color: #f7f7f7;
//             color: #484848;
//           }
//         }

//     }
//   }
// }

// // ng-select.ng-select.ng-select-opened>.ng-select-container .ng-arrow{
// //   top:-2px;border-color:transparent transparent #484848;border-width:0 4px 4px;
// // }

// // .ng-select > .ng-select-container .ng-arrow {
// //   top: -2px;
// //   border-color: transparent transparent #484848;
// //   border-width: 0 4px 4px;
// // }

// .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
//   // top: -2px;
//   // border-color: transparent transparent #484848;
//   // border-width: 0 4px 4px;
// }

// .ng-select .ng-arrow-wrapper:hover .ng-arrow {
// }

// div.lightboxOverlay {
//   display:block;
// }

// .scrolltop-button {
//   box-shadow: none !important;
// }

// .mbp_pagination ul.page_navigation li.page-item {
//   cursor:pointer;
// }

// // .custom-pagination {
// //   margin-left: 0;
// //   margin-bottom: 1rem; }
// //   .custom-pagination::before, .custom-pagination::after {
// //     content: ' ';
// //     display: table; }
// //   .custom-pagination::after {
// //     clear: both; }
// //   .custom-pagination li {
// //     -moz-user-select: none;
// //     -webkit-user-select: none;
// //     -ms-user-select: none;
// //     margin-right: 0.0625rem;
// //     border-radius: 0; }
// //   .custom-pagination li {
// //     display: inline-block; }
// //   .custom-pagination a,
// //   .custom-pagination button {
// //     color: #0a0a0a;
// //     display: block;
// //     padding: 0.1875rem 0.625rem;
// //     border-radius: 0; }
// //     .custom-pagination a:hover,
// //     .custom-pagination button:hover {
// //       background: #e6e6e6; }
// //   .custom-pagination .current {
// //     padding: 0.1875rem 0.625rem;
// //     background: #2199e8;
// //     color: #fefefe;
// //     cursor: default; }
// //   .custom-pagination .disabled {
// //     padding: 0.1875rem 0.625rem;
// //     color: #cacaca;
// //     cursor: default; }
// //     .custom-pagination .disabled:hover {
// //       background: transparent; }
// //   .custom-pagination a, .custom-pagination button {
// //     cursor: pointer; }
// // .custom-pagination .pagination-previous a::before,
// // .custom-pagination .pagination-previous.disabled::before {
// //   content: '«';
// //   display: inline-block;
// //   margin-right: 0.5rem; }
// // .custom-pagination .pagination-next a::after,
// // .custom-pagination .pagination-next.disabled::after {
// //   content: '»';
// //   display: inline-block;
// //   margin-left: 0.5rem; }
// // .custom-pagination .show-for-sr {
// //   position: absolute !important;
// //   width: 1px;
// //   height: 1px;
// //   overflow: hidden;
// //   clip: rect(0, 0, 0, 0); }
// // .custom-pagination .small-screen {
// //   display: none; }
// // @media screen and (max-width: 601px) {
// //   .custom-pagination.responsive .small-screen {
// //     display: inline-block; }
// //   .custom-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
// //     display: none; }
// // }

// // ul.ngx-pagination li span  {
// //   position: relative;
// //   display: inline-block;
// //   padding: 0.5rem 0.75rem;
// //   margin-left: -1px;
// //   background-color: #ffffff;
// //   border: 1px solid #ebebeb;
// //   border-radius: 50%;
// //   font-size: 14px;
// //   font-family: "Nunito";
// //   color: #777777;
// //   line-height: 1.2;
// //   height: 45px;
// //   line-height: 30px;
// //   overflow: hidden;
// //   text-align: center;
// //   width: 45px;
// //   margin-right: 6px;
// //   // /* margin-top: 12px;
// // }
// //     position: relative;
// //     display: inline-grid;
// //     padding: 0.5rem 0.75rem;
// //     margin-left: -1px;
// //     background-color: #ffffff;
// //     border: 1px solid #ebebeb;
// //     border-radius: 50%;
// //     font-size: 14px;
// //     font-family: "Nunito";
// //     color: #777777;
// //     line-height: 1.2;
// //     height: 45px;
// //     line-height: 30px;
// //     overflow: hidden;
// //     text-align: center;
// //     width: 45px;
// // }

// // .ngx-pagination .current {
// //   background: pink;
// // }

// .pagination-previous {

// }

// .pagination-next {

// }

// .sasw_list > .min_area {
//   margin-right: 0.5rem;
// }

// .wrapper {
//   overflow-x: hidden;
// }

// app-home-slider {

//   .ng-select.ng-select-multiple .ng-select-container
//    {
//      border-radius: 25px;
//    }
//   .ng-select.ng-select-multiple .ng-select-container
//    {
//     &> .ng-value-container {
//       flex-wrap: nowrap !important;
//     }
//   }
// }

// ng-select.ng-select > div.ng-select-container {
//   border-radius: 25px;
// }

// .ng-select .ng-select-container > .ng-value-container {
//   flex-wrap: nowrap !important;
// }

// .ng-select .ng-select-container .ng-value-container {
//   padding-top: 5px;
//   padding-left: 7px;
// }

// .ng-select.ng-select-single .ng-select-container {
//   height: 52px;
// }

// .home1-advnc-search ul {
//   display: flex;
// }

// .home1-advnc-search ul li {
//   flex: 1;
//   max-width: 200px;
// }

// .home1-advnc-search ul li:last-child {
//   text-align: center;
//   margin-right: 0 !important;
// }

// @media only screen and (max-width: 991px) {

//   .home1-advnc-search ul {
//     display: block;
//   }

//   .home1-advnc-search ul li {
//     flex: unset;
//     display: inline-block;
//     min-width: 100%;
//     margin-bottom: 5px;
//     max-width: 100%;
//   }

//   .home1-advnc-search ul li:last-child {
//     min-height:auto;
//     margin-top: 10px;
//   }

//   .home_adv_srch_opt.home4 .home1_adsrchfrm {
//     display: block !important;
//     height: auto !important;
//   }
// }

// /**
// PROEPRTY CARDS
// **/

// .feat_property.home7 .thumb {
//   height: 250px;
// }

// .feat_property .thumb {
//   height: 300px;
// }

// .inner_page_breadcrumb
// {
//   background-image:url('assets/images/background/rem-banner-img.jpg');
//   background-position: 0 20%;
// }
// .order_list.list-inline-item
// {
//   column-count: 3;
//   width: 100%;
// }
// // Media Query blah blah

// // Where did you find these 601 and 1200px ?
// // 601 is not standard
// @include media-breakpoint-down(lg) {
//   .order_list.list-inline-item {
//     column-count: 2;
//   }
// }

// @include media-breakpoint-down(md) {
//   .order_list.list-inline-item {
//         column-count: 1;
//   }
// }

// /**
// * FOOTER
// */

// .footer_qlink_widget {
//   .list-unstyled {
//     column-count : 2;
//   }
// }

// // @media only screen and (max-width: 767px) {
// //   .footer_qlink_widget {
// //     .list-unstyled {
// //       column-count : 1;
// //     }
// //   }
// // }

// @include media-breakpoint-down(md) {
//   .footer_qlink_widget {
//         .list-unstyled {
//           column-count : 1;
//         }
//       }
// }

// .order_list li span i {
//   color: #ff4772 !important;
//   margin-right:10px !important;

//   }

//   .order_list li span
//   {
//     font-size: 14px;
//     font-family: "Nunito";
//     color: #484848;
//     line-height: 2.857;
//   }

//   .form-group.search_option_two {
//     text-align: center;

//     span.custom-control {
//         display: inline-block;
//         &:first-child {
//           padding-right: 1.5rem;
//         }
//     }
//   }

//   .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
//     color: #fff;
//     background-color: lighten($rem-pink, 20%);
//     border-color:lighten($rem-pink, 20%);
// }

//   .custom-control-input:checked ~ .custom-control-label::before {
//     color: #fff;
//     border-color: $rem-pink;
//     background-color: $rem-pink;
// }
// .custom-radio .custom-control-label::before {
//     border-radius: 50%;
// }
// .custom-control-label::before {
//     background-color: #ffffff;
//     border: 1px solid #dddddd;
//     height: 18px;
//     top: 0.25rem;
//     width: 18px;
// }

// .custom-control-label::after {
//   position: absolute;
//   top: 0.3rem;
//   left: -1.425rem;
//   display: block;
//   width: 1rem;
//   height: 1rem;
//   content: "";
//   background: no-repeat 50%/50% 50%;
// }

// /**
// FILTERS
// **/
// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
//   border-right: 1px solid lighten($rem-pink, 15%);
// }

// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
//   background-color: lighten($rem-pink, 30%);
// }

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
//   // color: #333;
//   background-color: lighten($rem-pink, 30%);
// }

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  // color: #333;
  background-color: lighten($rem-pink, 30%);
}

//   // color: #333;
// }

// .home4_iconbox li {
//   width: unset;
// }

// .home4_iconbox .icon p {
//   display: inline;
// }

// .whychose_us:before {
//   background-image:url('assets/images/background/rem-banner-img.jpg') im !important;
// }

// @media only screen and (max-width: 767.98px)
// {
//   // .home_content .home4, .home-text h1 {
//   //   font-size: 30px;
//   // }
//   .home_iconbox_container {
//     top: 35% !important;
//   }
//   .home-text
//   {
//     display: none;
//   }
//   .about-us-rem-logo
//   {
//     display: none;
//   }
// }

// /**
// *IMAGES
// **/

// .properti_city  {
//   &:hover {
//     .thumb img {
//       transform: scale(1.1) rotate(-1deg) translate(-50%, -50%);
//     }
//   }

//   .thumb {
//     height:300px;

//     img {
//       position: absolute;
//       left: 50%;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       object-fit: contain;
//       height: 100%;
//       padding:10px;
//     }
//   }
// }

// .feat_property.agency .thumb img {
//   object-fit: scale-down;
// }

// .feat_property.agent .thumb img {
//   object-fit: fill;
// }

// .feat_property.agency .thumb img {
//   object-fit: scale-down;
//   height: 260px;
// }

// .feat_property.office .thumb img {
//   -o-object-fit: scale-down;
//   object-fit: scale-down;
//   background-color: #fff;
//   object-position: center;
//   height: 260px;
// }

// .feat_property .details .tc_content {
//   min-height: 9rem;
// }

// .feat_property .thumb .thmb_cntnt ul.tag li, .feat_property.home8 ul.tag li, .properti_city.home6 .thumb .thmb_cntnt ul.tag li, .feat_property.list .dtls_headr ul.tag li {
//   width: 100px;
// }

// //= PAGINATION
// .mbp_pagination ul.page_navigation li .page-link {
//   min-width: min-content;
// }

// .agency-small
// {
//   line-height: 30px;
// }

// .agent-image
// {
//   width: 50px !important;
//   height: 50px  !important;
//   border-radius: 50px  !important;
//   object-fit: cover  !important;
//   object-position: top  !important;
// }

// .ref-number
// {
//     font-style: italic;
//     font-size: 12px !important;
//     color: #afafaf !important;
// }

// .feat_property .thumb a {
//   width: 100%;
//   }

// .grid_list_search_result {
//   .ng-select-container {
//   min-width:10rem !important
// }
// }

// .about-us-rem-logo
// {
//   height: 185px;
//   float: right;
//   margin-left: 50px;
//   margin-bottom: 15px;
// }

// .team_member .thumb {
//   height:250px;
// }

// .team_member .thumb img {
//   object-fit: fill;
//   // object-position: top;
// }

// .parner_reg_btn a.btn {
//   color: white;
// }

// .property-city .properti_city .thumb img {
//   object-fit: cover;
//   padding: 0;
// }

// .text-white
// {
//   color:#fff !important;
// }
// .lb-nav a.lb-next, .lb-nav a.lb-prev
// {
// opacity: 0.5;
// }

// @keyframes placeHolderShimmer{
//   0%{
//       background-position: -468px 0
//   }
//   100%{
//       background-position: 468px 0
//   }
// }

// .animated-background {
//   animation-duration: 1.25s;
//   animation-fill-mode: forwards;
//   animation-iteration-count: infinite;
//   animation-name: placeHolderShimmer;
//   animation-timing-function: linear;
//   background: #F7F7F7;
//   // background: $rem-pink;
//   background: linear-gradient(to right, #F7F7F7 8%, #F0F0F0 18%, #F7F7F7 33%);
//   // background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
//   background-size: 800px 104px;
//   height: 14px;
//   position: relative;
// }

// .format-white-space {
//   white-space: pre-wrap;
// }

@import 'assets/finderland/css/style.css';
@import 'assets/finderland/plugins/bootstrap4/css/bootstrap.min.css';
@import 'assets/finderland/plugins/owl-carousel/assets/owl.carousel.css';
@import 'assets/finderland/plugins/leaflet/dist/leaflet.css';
@import 'assets/finderland/plugins/nouslider/nouislider.min.css';
@import 'assets/finderland/plugins/apexcharts-bundle/dist/apexcharts.css';
@import 'assets/finderland/fonts/Linearicons/Linearicons/Font/demo-files/demo.css';
@import 'assets/finderland/fonts/LineIcons/Pro-Light/font-css/LinIconsPro-Light.css';
@import 'assets/finderland/fonts/LineIcons/Pro-Regular/font-css/LineIcons.css';

// Add ng-select styles
@import '~@ng-select/ng-select/themes/default.theme.css';

.note-container {
 box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  padding: 10px;

  & + .note-container {
    margin-top: 10px;
  }
}
