.ps-dashboard {
    display: grid;
    grid-template-columns: 280px minmax(0, 1fr);
    margin-bottom: var(--section-padding-bottom);
    @extend %list-reset;

    .ps-dashboard__sidebar {
        height: 100%;
        padding: 32px 16px;
        background-color: var(--color-bg-gray);
        border-radius: var(--border-radius-medium);

        .menu--single {
            a {
                color: var(--color-heading);
                height: 48px;
                padding: 0 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: var(--border-radius-medium);

                i {
                    margin-right: 16px;
                }

                span {
                    font-weight: 400;
                }

                &:hover {
                    color: var(--color-1st);
                    background-color: alpha(var(--color-1st), 0.075);
                }
            }
        }
    }

    .ps-dashboard__right {
        min-height: 600px;
    }
}
