.ps-product--detail {
    // images/

    .ps-product__variant {
        text-align: center;

        img {
            max-height: 100px;
            width: auto;
            border: 2px solid var(--color-border);
            border-radius: var(--border-radius-medium);
            transition: all 0.4s ease;
            cursor: pointer;

            &.active {
                border-color: $color-1st;
            }
        }

    }

    .ps-product__variants {
        .item {
            margin-bottom: 10px;
            border: 2px solid transparent;
            cursor: pointer;

            img {
                max-height: 100px;
                width: auto;
                border: 2px solid var(--color-border);
                border-radius: var(--border-radius-medium);
                transition: all 0.4s ease;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                img {
                    border-color: var(--color-1st);
                }
            }
        }

        .slick-current {
            img {
                border-color: var(--color-1st);
            }
        }

        &.without-carousel {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;

            .ps-product__variant {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .ps-product__gallery {
        position: relative;
        width: 100%;
        border: 5px solid var(--color-bg-gray);
        border-radius: var(--border-radius-large);
        padding: 20px;
        margin-bottom: 30px;

        img {
            width: 100%;
        }

        .slick-arrow {
            @include vertical-align();
            z-index: 100;
            width: 46px;
            height: 46px;
            color: #000;
            font-size: 20px;
            border-radius: var(--border-radius-medium);
            border: none;
            background-color: #fff;
            @include hidden;

            i {
                @include center();
            }

            &:first-child {
                left: 20px;
            }

            &:last-child {
                right: 10px;
            }

            &:hover {
                background-color: var(--color-1st);
                color: #fff;
            }
        }

        .slick-slide {
            a {
                position: relative;
                display: block;
            }
        }

        &:hover {
            .item {
                &:before {
                    @include show;
                }
            }

            .slick-arrow {
                @include show;
            }
        }
    }

    .ps-gallery__item {
        display: block;
    }

    .ps-product__variations {
        margin-bottom: 2rem;
        padding-bottom: 20px;

        figure {
            margin-bottom: 30px;

            figcaption {
                margin-bottom: 10px;
                font-size: 14px;
                color: $color-heading;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .ps-product__thumbnail {

        padding: 0;

        figure {
            position: relative;
            display: block;
            max-width: 600px;
        }

        .ps-product__variants {
            width: 100%;
            min-width: 80px;
        }

        .ps-product__video {
            position: absolute;
            bottom: 20px;
            right: 20px;
            z-index: 20;
        }

        .ps-wrapper {
            position: relative;
        }
    }

    // information
    .ps-product__log-status {
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-weight: 600;
        color: #ffffff;
        background-color: #12a05c;
        line-height: 20px;
        padding: 0 16px;
        border-radius: 50px;
        font-size: 12px;

        &.outstock {
            background-color: #752A2B;
        }
    }

    .ps-product__title {
        font-size: 24px;
        font-weight: 600;
        color: var(--color-heading);
        line-height: 1.3em;
        text-transform: none;
    }

    .ps-product__price {
        margin-bottom: 10px;
        font-weight: 600;
        line-height: 1em;
        color: var(--color-heading);

        del {
            font-weight: 400;
            font-size: 0.75em;
        }
    }

    .ps-product__desc {
        margin-bottom: 20px;

        p {
            margin-bottom: 0;
            color: #777777;
            font-size: 14px;
        }
    }

    .ps-product__rating {
        display: flex;
        width: 100%;


        span {
            color: #5B6C8F;
            font-size: 14px;
        }
    }

    .ps-product__specification {
        p {
            font-size: 16px;

            strong {
                margin-right: 5px;
                font-weight: 400;
                color: var(--color-text);
            }

            a {
                display: inline-block;
                margin-right: 10px;
                font-weight: 500;
                color: var(--color-heading);

                &:hover {
                    color: var(--color-1st);
                    text-decoration: underline;
                }

                &:after {
                    content: ",";
                }

                &:last-child {
                    margin-right: 0;

                    &:after {
                        content: "";
                    }
                }
            }
        }
    }

    .ps-product__top-info {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--color-border);

        figure {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        .ps-product__meta {
            margin-bottom: 10px;
        }
    }

    .ps-product__availability {
        margin-bottom: 0;
        font-size: 16px;

        strong {
            font-weight: 600;
            color: var(--color-heading);
        }
    }

    .ps-product__meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ps-product__brand {
            position: relative;
            margin-right: 10px;
            padding-right: 10px;

            a {
                color: var(--color-1st);
                line-height: 20px;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:after {
                content: '';
                @include vertical-align();
                right: 0;
                height: 15px;
                width: 1px;
                background-color: var(--color-text);
            }
        }

        .ps-product__rating {
            i {
                line-height: 20px;
            }
        }
    }

    .ps-product__attribute {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        figcaption {
            margin-right: 10px;
        }

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    .ps-product__attributes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        margin-bottom: 20px;

    }

    // shopping
    .ps-product__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: block;
            color: var(--color-1st);
            vertical-align: middle;

            i {
                margin-right: 0.25em;
            }

            &:first-child {
                margin-right: 25px;
            }

            &:hover {
                color: lightness(var(--color-1st), 0.75);
            }
        }
    }

    .ps-product__add-cart {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 20px;

        .form-group--number {
            margin-bottom: 0;

            input {
                height: 46px;
                border-radius: var(--border-radius-medium);
            }
        }

        figure {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }
    }

    .ps-product__label {
        font-size: 14px;
        font-weight: 600;
        color: var(--color-1st);
        line-height: 1em;
    }

    .ps-product__categories {
        a {
            font-size: 14px;
            line-height: 1em;
            font-weight: 400;
            color: var(--color-1st);
        }
    }

    .ps-product__sizes,
    .ps-product__colors {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        figcaption {
            margin-right: 10px;
            line-height: 26px;
        }
    }

    .ps-product__sizes {
        a {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 5px 18px;
            font-size: 13px;
            line-height: 16px;
            font-weight: 600;
            color: var(--color-1st);
            border-radius: 30px;
            border: 1px solid $color-border;

            &:hover {
                border-color: var(--color-1st);
            }

            &.active {
                border-color: var(--color-1st);
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }

    .ps-product__colors {
        .color {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }

    .ps-product__shopping {
        padding-bottom: 20px;
        margin-bottom: 10px;
        border-bottom: 1px solid $color-border;
    }

    .ps-product__short-desc {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-border);

        p {
            color: var(--color-text);
            line-height: 1.6em;
            font-size: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .ps-product__info {
        text-align: left;
        padding-left: 0;
        margin-bottom: 0;
    }

    .ps-product__header {
        @extend %list-reset;
    }

    .ps-product__review {
        h4 {
            margin-bottom: 30px;
            font-weight: 400;
            font-size: 18px;
            color: $color-heading;
        }
    }

    //content

    .ps-product__tabs {
        border: none;
        padding-bottom: 20px;

        .ant-tabs-nav .ant-tabs-tab {
            padding: 0 16px;
            height: 48px;
            font-size: 16px;
            font-weight: 600;
            color: var(--color-heading);
            border-radius: var(--border-radius-medium);
            background-color: var(--color-bg-gray);
            button {
                font-weight: inherit;
            }
            &-active {
                background-color: var(--color-1st);

                .ant-tabs-tab-btn {
                    color: #fff;
                    font-weight: 500;
                }
            }
        }

        .ant-tabs-tab {
            padding: 0;
            line-height: 1.3em;
            margin-right: 16px;
        }

        .ant-tabs-ink-bar {
            background-color: transparent;
        }

        .ant-tabs-nav {
            margin-bottom: 30px;

            &:before {
                display: none;
            }
        }
    }

    .ps-product__content {
        padding-top: 50px;
    }

    .ps-product__content-wrapper {
        padding: 30px;
        border: 3px solid var(--color-border);
        border-radius: var(--border-radius-large);
        min-height: 300px;
    }

    .ps-product__description {
        .ps-document {
            padding: 0;
        }
    }

    @media (min-width: 768px) {
    }

    @media (min-width: 992px) {
        .ps-product__title {
            font-size: 30px;
        }

        .ps-product__desc {
            p {
                font-size: 16px;
            }
        }

        .ps-product__price {
            font-size: 30px;
        }

        .ps-product__sharing {
            padding-top: 20px;
        }

        .ps-product__header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 60px;
        }

        .ps-product__review {
            h4 {
                font-size: 24px;
            }
        }

        .ps-product__thumbnail {
            margin-bottom: 0;
            padding-right: 0;
        }

        .ps-product__content {
            padding-top: 80px;
        }

        // html
        .ps-document {
            .ps-list {
                .d-inline-block {
                    width: auto;
                    margin-right: 30px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        max-width: 320px;
        margin: 0 auto;
        .ps-product__shopping {
            figure {
                flex-flow: row wrap;

                .form-group--number {
                    margin-right: 0;
                    max-width: 100%;
                    width: 100%;
                }

                > * {
                    margin-bottom: 10px;
                }

                .ps-btn {
                    flex-grow: 2;
                    text-align: center;
                }
            }
        }

        .ps-product__thumbnail {
            margin-bottom: 30px;
        }

        .ps-product__content {
            .ps-title {
                font-size: 24px;
            }

            .ps-subtitle {
                font-size: 16px;
                font-weight: 600;
                color: $color-heading;
            }
        }

    }
}
