@import "variables";


@import "assets/finderland/fonts/Linearicons/Linearicons/Font/demo-files/demo.css";
@import "assets/finderland/fonts/LineIcons/Pro-Light/font-css/LinIconsPro-Light.css";
@import "assets/finderland/fonts/LineIcons/Pro-Regular/font-css/LineIcons.css";

@import "assets/finderland/plugins/bootstrap4/css/bootstrap.min.css";
@import "assets/finderland/plugins/owl-carousel/assets/owl.carousel.css";
@import "assets/finderland/plugins/leaflet/dist/leaflet.css";
@import "assets/finderland/plugins/nouslider/nouislider.min.css";
@import "assets/finderland/plugins/apexcharts-bundle/dist/apexcharts.css";

@import "assets/finderland/css/style.css";



.alert{
    position:relative;
    padding:0;
    border:0px;
}
