.form-control {
    outline: none;
    height: var(--input-height);
    font-size: 16px;
    padding: 0 20px;
    border: none;
    height: 48px;
    font-weight: 500;
    border-radius: var(--border-radius-medium);
    border: 1px solid var(--color-border);
    transition: all .4s ease;
    box-shadow: 0 0 0 transparent;
    background-color: transparent;

    @include placeholder() {
        opacity: 1;
        font-size: 16px;
        font-weight: 400;
        color: var(--color-text);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 #000;
        border-color: var(--color-border-active);
    }
}

textarea.form-control {
    height: auto;
    padding: 2rem;
    resize: none;
}

.ps-checkbox {
    position: relative;
    display: block;

    > input {
        position: absolute;
        visibility: hidden;
    }

    label {
        margin-bottom: 0;
        position: relative;
        padding-left: 36px;
        color: var(--color-text);
        font-weight: 400;
        cursor: pointer;
        line-height: 20px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
            height: 20px;
            width: 20px;
            z-index: 10;
            border-radius: 2px;
            border: 1px solid #666;
            transition: all .25s ease;

        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 7px;
            width: 6px;
            height: 10px;
            border: 2px solid #fff;
            border-top: none;
            border-left: none;
            z-index: 10;
            opacity: 0;
            transform: rotate(0deg);
            transition: all .25s ease;
        }

        a {
            color: var(--color-1st);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    input[type=checkbox]:checked ~ label {
        &:before {
            background-color: var(--color-1st);
            border-color: var(--color-1st);
        }

        &:after {
            @include transform(rotate(45deg));
            opacity: 1;
        }
    }

}

.ps-radio {
    position: relative;

    > input {
        position: absolute;
        visibility: hidden;
    }

    label {
        position: relative;
        margin-bottom: 0;
        padding-left: 30px;
        font-family: $font-1st;
        color: #737373;
        font-weight: 400;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0px;
            height: 20px;
            width: 20px;
            z-index: 10;
            border: 1px solid rgba(19, 8, 1, 0.2);
            background-color: #fff;
            transition: all .4s ease;
            border-radius: 50%;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            z-index: 15;
            opacity: 0;
            @include transform(scale(1.5, 1.5));
            transition: all .6s ease;
        }
    }

    input[type=radio]:checked ~ label {
        &:before {
            background-color: transparent;
        }

        &:after {
            background-color: $color-1st;
            border-radius: 50%;
            border: none;
            width: 8px;
            height: 8px;
            top: 6px;
            left: 6px;
            opacity: 1;
        }
    }

    &--inline {
        display: inline-block;
        margin-right: 20px;
    }

    &--color {
        margin-right: 1rem;

        > label {
            margin-bottom: 0;
            width: 20px;
            height: 20px;
            padding-left: 0;
            background-color: transparent;
            border-radius: 50%;
            transition: all .4s ease;

            &:before {
                width: 16px;
                height: 16px;
                @include center();
                border: none;
            }

            &:after {
                display: none !important;
            }

        }

        input[type=radio]:checked ~ label {
            background-color: rgba(#3dc47e, .5);
        }
    }
}

.form-group {
    margin-bottom: 25px;

    > label {
        margin-bottom: 15px;
        font-weight: 400;
        color: #000000;
        line-height: 1em;
        font-size: var(--font-size-default);
        color: var(--color-text);

        sup {
            color: $color-danger;
        }
    }
}

.form-group--inline {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    > label {
        margin-bottom: 0;
        font-weight: 700;
        max-width: 160px;
    }

    .form-group__content {
        width: 100%;
    }


}

.ps-form {
    position: relative;
    top: 0;

    label {
        position: absolute;
        display: inline-block;
        top: 0;
        top: 50%;
        left: 20px;
        margin: 0;
        z-index: 1;
        padding: 0 6px;
        transform: translateY(-50%);
        transition: all .2s ease;
        color: var(--color-text-2);

        .required {
            color: var(--color-danger);
        }
    }

    .form-control {
        position: relative;
        z-index: 9;
    }

    .form-control:not(:placeholder-shown) + label, .form-control:focus + label {
        top: 0;
        z-index: 10;
    }

    &.underline {
        .form-control {
            border-radius: 0;
            padding-left: 0;
            border: none;
            border-bottom: 1px solid var(--color-border);
        }

        label {
            left: 0;
            padding: 0;
        }
    }

    &.text-area {
        label {
            top: 20px;
        }

        textarea.form-control {

        }

        .form-control:not(:placeholder-shown) + label, .form-control:focus + label {
            top: 0;
            z-index: 10;
        }
    }

    &.select {
        padding-right: 20px;

        select {
            appearance: none;
        }

        > i {
            @include vertical-align();
            right: 0;
            z-index: 9;
        }
    }

    &.with-icon {
        border-bottom: 1px solid var(--color-border);
        padding-right: 0;

        .form-control {
            border-bottom: none;
            text-indent: 30px;
            z-index: 1;
        }

        .ps-form__icon {
            display: inline-block;
            max-width: 24px;
            @include vertical-align();
            left: 0;
            z-index: 9;
        }
    }
}

.form-group--number {
    display: inline-block;
    position: relative;
    z-index: 10;

    button {
        @include vertical-align();
        width: 20px;
        height: 20px;
        max-width: 20px;
        color: $color-heading;
        font-size: 30px;
        border: none;
        background: none;
        z-index: 20;

        &.up {
            right: 12px;

            &:before, &:after {
                position: absolute;
                top: 50%;
                left: 50%;

                display: inline-block;
                content: '';
                width: 14px;
                height: 1px;
                background-color: $color-text;
            }

            &:before {
                @include transform(translate(-50%, -50%) rotate(90deg));
            }

            &:after {
                @include transform(translate(-50%, -50%) rotate(0deg));
            }
        }

        &.down {
            left: 12px;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                content: '';
                width: 14px;
                height: 1px;
                background-color: $color-text;
                @include transform(translate(-50%, -50%) rotate(0deg));
            }
        }
    }

    .form-control {
        border: 2px solid #eaeaea;
        height: 45px;
        padding: 0 25px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: $color-heading;
        background-color: transparent;
        @include placeholder() {
            color: $color-heading;
        }
    }
}

.form-group--inside {
    position: relative;

    button {
        right: 10px;
        border: none;
        color: #ce873a;
        background-color: transparent;
        @include vertical-align();

        &:hover {
            color: darken(#ce873a, 10%);
        }
    }
}

.ps-dropdown--ant {
    .ant-dropdown-menu {
        border-radius: var(--border-radius-medium);
        background-color: transparent;
        padding: 0;
        border: 2px solid var(--color-border);
        box-shadow: none;
        overflow: hidden;
    }

    li {
        padding: 0;

        a {
            margin: 0;
            padding: 12px 20px;
            font-size: 16px;
            font-weight: 600;
            color: $color-text;
            background-color: #fff;

            &:hover {
                color: var(--color-1st);
                background-color: var(--color-bg-gray);
            }
        }

        &:first-child {
            a {
                //border-top-left-radius: var(--border-radius-medium);
                //border-top-right-radius: var(--border-radius-medium);
            }
        }

        &:last-child {
            a {
                border-bottom: none;
                border-bottom-left-radius: var(--border-radius-medium);
                border-bottom-right-radius: var(--border-radius-medium);
            }
        }
    }
}

.ps-form--slider {
    .ps-form__range {
        padding-bottom: 20px;
    }

    .ps-form__attribute {
        font-weight: 400;
        color: var(--color-heading);
    }

    .ps-form__attributes {
        line-height: 1em;
        margin-bottom: 0;
        font-size: 14px;

        > span {
            margin-right: 10px;
        }

        .ps-form__min {
            margin-right: 0.25em;
        }

        .ps-form__max {
            margin-left: 0.25em;
        }
    }
}

.ps-nouislider {
    width: 100%;
    margin: 10px auto;

}

.noUi-connect {
    background-color: $color-1st;
    height: 12px;
}

.noUi-horizontal {
    height: 4px;
    border-radius: 0;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
}

.noUi-handle:before,
.noUi-handle:after {
    display: none;
}

.noUi-handle {
    border: 3px solid $color-1st;
    border-radius: 50%;
    box-shadow: none;
}

.noUi-horizontal .noUi-handle {
    width: 12px;
    height: 18px;
    left: 0px;
    top: -8px;
    border-radius: 0;
    border-radius: 2px;
    border: 1px solid var(--color-border);
    cursor: pointer;

    &:hover {
        background-color: #fff;
    }
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -7px;
}




