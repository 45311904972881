.ps-project {
    transition: all 0.25s ease;
    cursor: default;

    &__image {
        overflow: hidden;

        img {
            width: 100%;
            transition: all 0.25s ease-out;
        }
    }

    &__badge {
        display: inline-block;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        color: #ffffff;
        vertical-align: top;
        border-radius: var(--border-radius-small);

        &.featured {
            background-color: var(--color-2nd);
        }

        &:hover {
            color: #fff;
        }
    }

    &__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: var(--border-radius-small);
            background-color: rgba(#000, 0.25);
            transition: all .25s ease;
            color: #ffffff;

            &:hover {
                background-color: var(--color-2nd);
                color: #ffffff;
            }
        }
    }

    &__name {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 18px;
        color: var(--color-heading);
        line-height: 1.4em;

        > a {
            font: inherit;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__addess {
        p {
            margin-bottom: 0;
            font-size: 13px;
        }
    }

    &__price {
        line-height: 1em;

        strong {
            font-size: 18px;
            font-weight: 500;
            color: #669900;
        }

        span {
            text-transform: capitalize;
            font-size: 14px;
            color: var(--color-text-2);
        }
    }

    &__meta {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        figcaption {
            font-size: 13px;
            color: var(--color-text-2);
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
            color: var(--color-heading);
        }
    }

    &__services {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        p {
            margin: 0;
        }

        span {
            font-size: 13px;

            i {
                margin-right: 10px;
                font-size: 14px;
            }
        }
    }

    &__thumbnail {
        position: relative;
        z-index: 9;

        .ps-project__overlay {
            @include max-area();
            z-index: 1;
        }

        .ps-project__actions {
            position: absolute;
            top: 20px;
            right: 26px;
            z-index: 9;
        }

        .ps-project__badges {
            position: absolute;
            top: 20px;
            left: 26px;
            z-index: 9;
        }

        &:hover {

        }
    }

    &__content {

    }

    &:hover {
        .ps-project__overlay {
            background-color: alpha((var(--color-1st)), 0.25);
        }

        .ps-project__image {
            img {
                transform: scale3d(1.05, 1.05, 1.05);
            }
        }
    }

    @media screen and (min-width: 480px) {
        &__name {
            font-size: 24px;
        }
    }
}

.ps-project--grid {
    border: 1px solid #ccc;
    border-radius: var(--border-radius-medium);
    overflow: hidden;

    .ps-project__address {
        margin-bottom: 20px;
        font-size: 13px;
    }

    .ps-project__price {
        margin-bottom: 26px;
    }

    .ps-project__meta {
        padding-bottom: 26px;
        margin-bottom: 26px;
        border-bottom: 1px dashed var(--color-border);
    }

    .ps-project__content {
        padding: 16px;
        background-color: #fff;
    }

    &.with-simple {
        .ps-project__meta, .ps-project__services {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (min-width: 480px) {
            .ps-project__name {
                font-size: 20px;
            }
        }
    }

    &.with-full {
        @media screen and (min-width: 480px) {
            .ps-project__name {
                font-size: 20px;
            }
        }


        @media screen and (min-width: 1366px) {
            .ps-project__meta, .ps-project__services {
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }

    @media screen and (min-width: 1600px) {
        .ps-project__content {
            padding: 26px;
            background-color: #fff;
        }
    }

    @media screen and (max-width: 479px) {
        .ps-project__content {
            padding: 26px 16px;
        }
    }
}

.ps-project--list {
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);

    .ps-project__meta {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px dashed var(--color-border);
    }

    .ps-project__name {
        margin-bottom: 0;
    }

    .ps-project__top {
        display: grid;
        grid-template-columns: 1fr;

        .ps-project__address {
            margin-bottom: 0;
        }

    }

    .ps-project__wrapper {
        padding: 30px 30px 25px;
    }

    @media screen and (min-width: 768px) {
        .ps-project__top {
            display: grid;
            grid-template-columns: 1fr 200px;
            padding-bottom: 30px;
            grid-column-gap: 10px;

            .ps-project__top-right {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 350px 1fr;
        .ps-project__image {
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .ps-project__meta {
            grid-template-columns: repeat(5, 1fr);
        }

        .ps-project__services {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media screen and (max-width: 480px) {
        .ps-project__top-right {
            padding-top: 20px;
        }
    }

}

// Projects

.ps-projects {
    &__layout-switchers {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            color: var(--color-text);
            border-radius: var(--border-radius-medium);
            margin-right: 10px;

            i {
                display: block;
                line-height: 1em;
                font-size: 18px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover, &.active {
                color: var(--color-heading);
            }

            &:hover {
                background-color: alpha(var(--color-1st), 0.1);
            }
        }
    }

    &__sorting {
        max-width: 250px;

        select {
            height: 36px;
            border: none;
        }

        select:focus + i {
            @include show;
        }
    }

    &__top-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
            margin-bottom: 0;

            strong {
                font-weight: 500;
                color: var(--color-1st);
            }
        }
    }

    &__top-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ps-projects__sorting {
            margin-right: 30px;
            padding-right: 30px;
            border-right: 1px solid var(--color-border);
        }
    }

    &__top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 20px;
    }

    &__pagination {
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__items {
        .ps-project {
            height: 100%;
        }

        .ps-project--grid {
            //margin-bottom: 30px;
        }

        .ps-project--list {
            margin-bottom: 40px;
        }

        &.ps-grid {
            grid-gap: 20px;
        }

        &[data-columns='5'] {
            grid-gap: 16px;
        }
    }

    @media screen and (min-width: 768px) {
        &__items {
            grid-gap: 30px;
        }

        &__top {
            padding-bottom: 40px;
        }
    }

    @media screen and (min-width: 1024px) {
        &__items {
            grid-gap: 16px;

            &[data-columns='5'] {

                .ps-project {
                    height: 100%;
                }
            }
        }

        &__pagination {
            padding-top: 70px;
        }
    }

    @media screen and (min-width: 1366px) {
        &__items {
            grid-gap: 24px;

            &[data-columns='5'] {
                grid-gap: 24px;
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }

    @media screen and (min-width: 1600px) {
        &__items {
            grid-gap: 40px;

            &[data-columns='5'] {
                grid-gap: 16px;
                grid-template-columns: repeat(5, minmax(0, 1fr));
            }
        }
    }

    @media screen and (max-width: 479px) {
        &__top {
            display: grid;
            display: flex;
            flex-flow: column nowrap;

            .ps-projects__sorting {
                margin-right: 0;
                padding-right: 10px;

                select {
                    padding-left: 0;
                }
            }

            .ps-projects__top-left {
                padding-bottom: 10px;
            }

            .ps-projects__top-right {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }

}

.ps-projects--with-sidebar {
    display: grid;

    .ps-widget--project {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--color-border);

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 255px 1fr;
        grid-column-gap: 30px;

        &.ps-reverse {
            grid-template-columns:  1fr 255px;

            .ps-projects__left {
                grid-column-start: 2;
            }

            .ps-projects__right {
                grid-column-start: 1;
                grid-row-start: 1;
            }
        }
    }

    @media screen and (min-width: 1366px) {
        grid-column-gap: 60px;
    }

    @media screen and (min-width: 1600px) {
        grid-column-gap: 100px;
    }
}

.ps-projects--with-map {
    .ps-projects__left {
        padding: 16px;
    }

    .ps-projects__pagination {
        padding-top: 40px;
    }

    #map {
        width: 100%;
        height: 100%;
        min-height: 500px;
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .ps-projects__pagination {
            padding-top: 70px;
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-projects__left {
            padding: 40px;
        }
    }

    @media screen and (max-width: 479px) {
        display: flex;
        flex-flow: column-reverse wrap;

        .ps-projects__right {
            margin-bottom: 30px;
        }
    }
}

.ps-project--detail {
    .ps-project__gallery {
        display: block;
        max-width: 100vw;
        margin-bottom: 40px;

        .img {
            max-width: 100%;
        }
    }

    .ps-project__actions {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            width: 50px;
            height: 50px;
            color: var(--color-heading);
            background-color: alpha(var(--color-1st), 0.1);

            &:hover {
                color: #ffffff;
                background-color: alpha(var(--color-1st), 1);
            }
        }
    }

    .ps-project__price {

        strong {
            font-size: 30px;
        }

        span {
            font-size: 24px;
        }

    }

    .ps-project__name {
        font-size: 24px;

        &:hover {
            text-decoration: none;
        }
    }

    .ps-project__meta {
        margin-bottom: 30px;
    }

    .ps-project__services {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid var(--color-border);

        p {
            padding: 24px;
            width: 150px;
            min-height: 100px;
            font-weight: 400;
            font-size: 14px;
            line-height: 1em;
            color: var(--color-heading);
            border: 1px solid var(--color-border);
            border-radius: var(--border-radius-large);


            i {
                display: block;
                font-size: 24px;
                margin-bottom: 30px;
                line-height: 1em;
            }
        }
    }

    .ps-project__heading {
        margin-bottom: 36px;
        font-size: 18px;
        line-height: 1em;
        font-weight: 500;
        color: var(--color-heading);
    }

    .ps-project__desc {
        > * {
            margin-bottom: 40px;
        }
    }

    .ps-project__header {
        display: grid;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 40px;

        .ps-project__address {
            margin-bottom: 40px;
        }
    }

    .ps-project__nearby {
        .ps-block--project-nearby {
            margin-bottom: 50px;
        }
    }

    .ps-project__review {
        padding-bottom: 20px;


    }

    .ps-project__amenities {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 20px;

        a {
            color: var(--color-heading);
            line-height: 20px;

            &:hover {
                color: var(--color-1st);
            }
        }
    }

    .ps-project__content {
        > * {
            margin-bottom: 32px;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-project__heading {
            font-size: 24px;
        }

        .ps-project__name {
            font-size: 36px;
        }

        .ps-project__amenities {
            grid-template-columns: repeat(4, 1fr);
        }

        .ps-project__meta, .ps-project__services {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-project__gallery {
            margin-bottom: 80px;
        }

        .ps-project__meta, .ps-project__services {
            grid-template-columns: repeat(5, 1fr);
        }

        .ps-project__meta {
            margin-bottom: 65px;
        }

        .ps-project__heading {
            font-size: 30px;
        }

        .ps-project__services {
            padding-bottom: 65px;
            margin-bottom: 65px;
        }

        .ps-project__name {
            font-size: 48px;
        }

        .ps-project__header {
            grid-template-columns: 4fr 1fr;
            padding-bottom: 45px;
            margin-bottom: 65px;

        }

        .ps-project__content {
            > * {
                margin-bottom: 64px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .ps-project__gallery {
            max-width: calc(1170px - 385px);
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-project__gallery {
            max-width: calc(1250px - 415px);
        }
        .ps-project__services {
            grid-column-gap: 20px;

            p {
                min-height: 130px;
            }
        }

        .ps-project__nearby {

            .row {
                margin: 0 -50px;

                > * {
                    padding: 0 50px;
                }
            }

            .ps-block--project-nearby {
                margin-bottom: 50px;
            }
        }

        .ps-project__meta, .ps-project__services {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media screen and (min-width: 1440px) {

    }
    @media screen and (min-width: 1600px) {
        .ps-project__gallery {
            max-width: calc(1510px - 455px);

            .ps-project__services {

                p {
                    width: 200px;
                }
            }
        }
    }

    @media screen and (max-width: 479px) {
        .ps-project__services {
            grid-template-columns: 1fr 1fr;
            width: 100%;
            max-width: 100px;
        }
    }
}





