.ps-pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            font-weight: 500;
            border-radius: var(--border-radius-medium);

            &:hover {
                color: #ffffff;
                background-color: var(--color-1st);
            }
        }

        &.active {
            a {
                color: #ffffff;
                background-color: var(--color-1st);
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}


