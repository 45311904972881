/*
    Project: Finderland HTML
    Version: 1.0.0
    Date Created: 2021-07-01T22:30:17+07:00
    Date Updated: n/a
    Author: nouthemes
    Developed by: diaryforlife
*/
:root {
  --color-1st: #BED62F;
  --color-1st-h: 69;
  --color-1st-s: 67%;
  --color-1st-l: 51%;
  --color-2nd: hsl(40, 100%, 49%);
  --color-2nd-h: 40;
  --color-2nd-s: 100%;
  --color-2nd-l: 49%;
  --color-success: hsl(80, 100%, 30%);
  --color-success-h: 80;
  --color-success-s: 100%;
  --color-success-l: 30%;
  --color-info: hsl(0, 0%, 85%);
  --color-info-h: 0;
  --color-info-s: 0%;
  --color-info-l: 85%;
  --color-warning: hsl(29, 98%, 57%);
  --color-warning-h: 29;
  --color-warning-s: 98%;
  --color-warning-l: 57%;
  --color-danger: hsl(1, 77%, 55%);
  --color-danger-h: 1;
  --color-danger-s: 77%;
  --color-danger-l: 55%;
  --color-border: hsl(214, 8%, 82%);
  --color-border-h: 214;
  --color-border-s: 8%;
  --color-border-l: 82%;
  --color-border-active: hsl(223, 10%, 52%);
  --color-border-active-h: 223;
  --color-border-active-s: 10%;
  --color-border-active-l: 52%;
  --color-text: hsl(0, 0%, 40%);
  --color-text-h: 0;
  --color-text-s: 0%;
  --color-text-l: 40%;
  --color-text-2: hsl(0, 0%, 60%);
  --color-text-2-h: 0;
  --color-text-2-s: 0%;
  --color-text-2-l: 60%;
  --color-heading: hsl(0, 0%, 0%);
  --color-heading-h: 0;
  --color-heading-s: 0%;
  --color-heading-l: 0%;
  --color-gray: hsl(0, 0%, 80%);
  --color-gray-h: 0;
  --color-gray-s: 0%;
  --color-gray-l: 80%;
  --color-bg-primary: hsl(202, 28%, 62%);
  --color-bg-primary-h: 202;
  --color-bg-primary-s: 28%;
  --color-bg-primary-l: 62%;
  --color-bg-gray: hsl(210, 10%, 96%);
  --color-bg-gray-h: 210;
  --color-bg-gray-s: 10%;
  --color-bg-gray-l: 96%;
  --border-radius-large: 8px;
  --border-radius-medium: 5px;
  --border-radius-small: 3px;
  --border-radius-tiny: 1px;
  --section-padding-top: 65px;
  --section-padding-bottom: 35px;
  --section-header-padding-bottom: 32px;
  --font-size-default: 16px;
  --input-height: 48px;
  --section-padding: 80px;
}

@media screen and (min-width: 1024px) {
  :root {
    --section-padding-top: 90px;
    --section-padding-bottom: 60px;
    --section-header-padding-bottom: 32px;
  }
}

@media screen and (min-width: 1366px) {
  :root {
    --section-padding-top: 130px;
    --section-padding-bottom: 100px;
    --section-header-padding-bottom: 65px;
  }
}

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  font-family: "Jost", sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  position: relative;
  color: var(--color-heading);
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p {
  font-size: var(--font-size-default);
  line-height: 1.6em;
  color: var(--color-text);
}

p span {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;
  font-size: var(--font-size-default);
}

a:hover {
  color: var(--color-1st);
}

a, button {
  cursor: pointer;
  transition: all .4s ease;
}

a,
input,
textarea,
button,
select {
  outline: none;
  font-size: var(--font-size-default);
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
  text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
}

iframe {
  border: 0;
  max-width: 100%;
  width: 100%;
}

figure {
  margin: 0;
}

.ps-document h1, .ps-document h2, .ps-document h3, .ps-document h4, .ps-document h5, .ps-document h6 {
  margin-bottom: 20px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-document ul, .ps-document ol {
  padding-left: 20px;
}

.ps-document ul li, .ps-document ol li {
  font-size: var(--font-size-default);
  color: var(--color-text);
}

.ps-document > * {
  margin-bottom: 40px;
}

p a {
  font-size: inherit;
}

p .ps-highlight {
  color: #06f;
  font-weight: inherit;
  font-size: inherit;
}

p .ps-highlight:hover {
  text-decoration: underline;
}

.ps-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 28px;
  max-width: 100px;
  padding: 0 18px;
  border-radius: var(--border-radius-medium);
  font-weight: 500;
  text-transform: capitalize;
}

.ps-badge--info {
  color: var(--color-info);
  background-color: hsla(var(--color-info-h), var(--color-info-s), var(--color-info-l), 0.15);
}

.ps-badge--success {
  color: var(--color-success);
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0.15);
}

.ps-badge--waring {
  color: var(--color-warning);
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), 0.15);
}

.ps-badge--danger {
  color: var(--color-danger);
  background-color: hsla(var(--color-danger-h), var(--color-danger-s), var(--color-danger-l), 0.15);
}

html, body {
  min-height: 100%;
  position: relative;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
  .row--5-columns .col-xl-3 {
    max-width: 20%;
  }
}

body {
  overflow-x: hidden;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media screen and (min-width: 1366px) {
  .container {
    max-width: 1280px;
  }
}

@media screen and (min-width: 1680px) {
  .container {
    max-width: 1540px;
  }
}

.ps-logo {
  transform: translateZ(0);
}

.ps-list--social {
  margin: 0;
  padding: 0;
}

.ps-list--social li {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  vertical-align: top;
}

.ps-list--social li a {
  display: inline-block;
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #636363;
  border: 1px solid #404040;
}

.ps-list--social li a i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.ps-list--social li a:hover {
  color: var(--color-1st);
  border-color: var(--color-1st);
  background-color: var(--color-1st);
}

.ps-list--social li a:hover i {
  color: #ffffff;
}

.ps-list--social li a.facebook {
  background-color: #3b5998;
}

.ps-list--social li a.facebook i {
  color: #ffffff;
}

.ps-list--social li a.twitter {
  background-color: #0084b4;
}

.ps-list--social li a.twitter i {
  color: #ffffff;
}

.ps-list--social li a.google-plus {
  background-color: #FF0000;
}

.ps-list--social li a.google-plus i {
  color: #ffffff;
}

.ps-list--social li a.instagram i {
  color: #C32AA3;
}

.ps-list--social li:last-child {
  margin-right: 0;
}

.ps-list--social.simple li {
  margin-right: 0;
}

.ps-list--social.simple li a {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
}

.ps-list--social.square li {
  margin-right: 10px;
}

.ps-list--social.square li a {
  width: 85px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: none;
}

.ps-list--social-color {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ps-list--social-color li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.ps-list--social-color li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 38px;
  height: 38px;
  color: #ffffff;
}

.ps-list--social-color li a.facebook {
  background-color: #1b4e9b;
}

.ps-list--social-color li a.facebook:hover {
  background-color: #296fda;
}

.ps-list--social-color li a.twitter {
  background-color: #00aeef;
}

.ps-list--social-color li a.twitter:hover {
  background-color: #3dcaff;
}

.ps-list--social-color li a.google-plus {
  background-color: #cc0000;
}

.ps-list--social-color li a.google-plus:hover {
  background-color: #ff1a1a;
}

.ps-list--social-color li a.linkedin {
  background-color: #2d567f;
}

.ps-list--social-color li a.linkedin:hover {
  background-color: #417cb7;
}

.ps-list--social-color li a.feed {
  background-color: #f86a02;
}

.ps-list--social-color li a.feed:hover {
  background-color: #fe9549;
}

.ps-list--checked li {
  margin-bottom: 20px;
}

.ps-list--checked li a {
  position: relative;
  display: block;
  padding-left: 30px;
  font-size: 14px;
  color: #313131;
}

.ps-list--checked li a:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 20px;
  height: 20px;
  border: solid 1px #8d8d8d;
  transition: all .4s ease;
}

.ps-list--checked li a:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 8px;
  color: #fff;
  width: 6px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  visibility: hidden;
  opacity: 0;
}

.ps-list--checked li a:hover:before {
  background-color: var(--color-1st);
  border-color: var(--color-1st);
}

.ps-list--checked li a:hover:after {
  visibility: visible;
  opacity: 1;
}

.ps-list--checked li.current a:before {
  background-color: var(--color-1st);
  border-color: var(--color-1st);
}

.ps-list--checked li.current a:after {
  visibility: visible;
  opacity: 1;
}

.ps-list--checked.ps-list--checked-circle li a:before {
  border-radius: 50%;
}

.ps-list--checked.ps-list--checked-circle li a:hover:before {
  background-color: #a7a7a7;
  border-color: #a7a7a7;
}

.ps-list--arrow a {
  position: relative;
  display: block;
  padding-left: 15px;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #555555;
}

.ps-list--arrow a:before {
  content: "\f105";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  font-family: FontAwesome;
}

.ps-list--arrow a:hover {
  color: var(--color-1st);
}

.ps-list--arrow a:hover .circle {
  background-color: var(--color-2nd);
  border-color: var(--color-2nd);
}

.ps-list--arrow a:hover .circle:before {
  color: #fff;
  visibility: visible;
  opacity: 1;
}

.ps-list--arrow li {
  margin-bottom: 20px;
}

.ps-list--arrow li.current a {
  color: var(--color-2nd);
}

.ps-list--arrow li.current .circle {
  background-color: var(--color-2nd);
}

.ps-list--arrow li.current .circle:before {
  visibility: visible;
  opacity: 1;
  color: #fff;
}

.ps-list--dot {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  list-style-type: none;
}

.ps-list--dot li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  color: #000000;
  line-height: 20px;
}

.ps-list--dot li:before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: var(--color-1st);
  border-radius: 50px;
}

.ps-list--plus li {
  margin: 10px 0;
}

.ps-list--plus li a {
  display: block;
  position: relative;
  padding-left: 15px;
  line-height: 20px;
}

.ps-list--plus li a:before {
  content: '+';
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}

.ps-list--line li a {
  position: relative;
  display: inline-block;
  padding: 5px 0;
  line-height: 20px;
  color: var(--color-text);
}

.ps-list--line li a:before {
  content: '';
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 1px;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
}

.ps-list--line li a:hover {
  color: var(--color-heading);
}

.ps-list--line li a:hover:before {
  transform-origin: 100% 50%;
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.ps-social-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-social-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-medium);
}

.ps-social-links a:hover {
  text-decoration: none;
}

.ps-social-links.light a {
  color: #ffffff;
}

.ps-social-links.light a:hover {
  color: var(--color-1st);
  background-color: rgba(255, 255, 255, 0.1);
}

.ps-social-links--rounded a {
  margin-right: 20px;
  color: var(--color-text);
  border-radius: 50%;
  border: 1px solid var(--color-border);
}

.ps-social-links--rounded a i {
  color: inherit;
}

.ps-social-links--rounded a:last-child {
  margin-right: 0;
}

.ps-btn, button.ps-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  height: 48px;
  font-size: 16px;
  line-height: 20px;
  border: none;
  font-weight: 500;
  transition: all .4s ease;
  color: #ffffff;
  background-color: var(--color-1st);
  cursor: pointer;
  border-radius: var(--border-radius-medium);
}

.ps-btn span, button.ps-btn span {
  font-weight: inherit;
}

.ps-btn:hover, .ps-btn:active, button.ps-btn:hover, button.ps-btn:active {
  color: #fff;
  background-color: hsl(var(--color-1st-h), var(--color-1st-s), calc(var(--color-1st-l) * 0.85));
}

.ps-btn:hover.ps-btn--black, .ps-btn:active.ps-btn--black, button.ps-btn:hover.ps-btn--black, button.ps-btn:active.ps-btn--black {
  background-color: var(--color-1st);
}

.ps-btn--rounded, button.ps-btn--rounded {
  border-radius: 50px;
}

.ps-btn--curve, button.ps-btn--curve {
  border-radius: 5px;
}

.ps-btn--outline, button.ps-btn--outline {
  color: var(--color-heading);
  border: 2px solid var(--color-border);
  background-color: transparent;
}

.ps-btn--outline:hover, button.ps-btn--outline:hover {
  color: var(--color-heading);
  background-color: #fff;
  border-color: var(--color-border-active);
}

.ps-btn--fullwidth, button.ps-btn--fullwidth {
  width: 100%;
  text-align: center;
}

.ps-btn--sm, button.ps-btn--sm {
  padding: .5rem 2rem;
  font-size: 1.2rem;
}

.ps-btn--sm.ps-btn--curve, button.ps-btn--sm.ps-btn--curve {
  border-radius: 3px;
}

.ps-btn--lg, button.ps-btn--lg {
  padding: 1.5rem 4rem;
}

.ps-btn--xl, button.ps-btn--xl {
  padding: 2rem 6rem;
  font-size: 1.6rem;
}

.ps-btn--reverse, button.ps-btn--reverse {
  background-color: #576391;
}

.ps-btn--reverse:hover, button.ps-btn--reverse:hover {
  background-color: var(--color-2nd);
}

.ps-btn--gray, button.ps-btn--gray {
  color: var(--color-text);
  background-color: #f0f5fb;
}

.ps-btn--gray:hover, button.ps-btn--gray:hover {
  color: #fff;
  background-color: var(--color-1st);
}

.ps-btn--black, button.ps-btn--black {
  background-color: var(--color-heading);
}

.ps-btn--black.ps-btn--outline, button.ps-btn--black.ps-btn--outline {
  background-color: transparent;
  border: 2px solid var(--color-heading);
  color: var(--color-heading);
}

.ps-btn--black.ps-btn--outline:hover, button.ps-btn--black.ps-btn--outline:hover {
  background-color: var(--color-heading);
  color: #ffffff;
}

.ps-btn--with-opacity, button.ps-btn--with-opacity {
  color: var(--color-1st);
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.2);
}

.ps-btn--with-opacity:hover, button.ps-btn--with-opacity:hover {
  color: #fff;
  background-color: var(--color-1st);
}

.ps-btn--close {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  transition: all .4s ease;
  border-radius: 50%;
}

.ps-btn--close:before, .ps-btn--close:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 50%;
  background-color: #9f9a98;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  transition: all .4s ease;
}

.ps-btn--close:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  -o-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.ps-btn--close:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -moz-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  -o-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ps-btn--close:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ps-btn--close:hover:before, .ps-btn--close:hover:after {
  background-color: #f44336;
  transition-delay: .3s;
}

.ps-btn--backtop {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 36px;
  height: 36px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #eaeaea;
}

.ps-btn--backtop:hover {
  background-color: var(--color-1st);
  border-color: var(--color-1st);
  color: #fff;
}

.ant-back-top {
  right: 30px !important;
  bottom: 30px !important;
}

.form-control {
  outline: none;
  height: var(--input-height);
  font-size: 16px;
  padding: 0 20px;
  border: none;
  height: 48px;
  font-weight: 500;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
  transition: all .4s ease;
  box-shadow: 0 0 0 transparent;
  background-color: transparent;
}

.form-control::-webkit-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
}

.form-control::-moz-placeholder {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
}

.form-control:-moz-placeholder {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
}

.form-control:-ms-input-placeholder {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
}

.form-control:focus {
  outline: none;
  box-shadow: 0 0 0 #000;
  border-color: var(--color-border-active);
}

textarea.form-control {
  height: auto;
  padding: 2rem;
  resize: none;
}

.ps-checkbox {
  position: relative;
  display: block;
}

.ps-checkbox > input {
  position: absolute;
  visibility: hidden;
}

.ps-checkbox label {
  margin-bottom: 0;
  position: relative;
  padding-left: 36px;
  color: var(--color-text);
  font-weight: 400;
  cursor: pointer;
  line-height: 20px;
}

.ps-checkbox label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 10;
  border-radius: 2px;
  border: 1px solid #666;
  transition: all .25s ease;
}

.ps-checkbox label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  z-index: 10;
  opacity: 0;
  transform: rotate(0deg);
  transition: all .25s ease;
}

.ps-checkbox label a {
  color: var(--color-1st);
}

.ps-checkbox label a:hover {
  text-decoration: underline;
}

.ps-checkbox input[type=checkbox]:checked ~ label:before {
  background-color: var(--color-1st);
  border-color: var(--color-1st);
}

.ps-checkbox input[type=checkbox]:checked ~ label:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 1;
}

.ps-radio {
  position: relative;
}

.ps-radio > input {
  position: absolute;
  visibility: hidden;
}

.ps-radio label {
  position: relative;
  margin-bottom: 0;
  padding-left: 30px;
  font-family: "Jost", sans-serif;
  color: #737373;
  font-weight: 400;
  cursor: pointer;
}

.ps-radio label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 10;
  border: 1px solid rgba(19, 8, 1, 0.2);
  background-color: #fff;
  transition: all .4s ease;
  border-radius: 50%;
}

.ps-radio label:after {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 15;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease;
}

.ps-radio input[type=radio]:checked ~ label:before {
  background-color: transparent;
}

.ps-radio input[type=radio]:checked ~ label:after {
  background-color: var(--color-1st);
  border-radius: 50%;
  border: none;
  visibility: visible;
  opacity: 1;
}

.ps-radio--inline {
  display: inline-block;
  margin-right: 20px;
}

.form-group {
  margin-bottom: 25px;
}

.form-group > label {
  margin-bottom: 15px;
  font-weight: 400;
  color: #000000;
  line-height: 1em;
  font-size: var(--font-size-default);
  color: var(--color-text);
}

.form-group > label sup {
  color: var(--color-danger);
}

.form-group--inline {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-group--inline > label {
  margin-bottom: 0;
  font-weight: 700;
  max-width: 160px;
}

.form-group--inline .form-group__content {
  width: 100%;
}

.ps-form {
  position: relative;
  top: 0;
}

.ps-form label {
  position: absolute;
  display: inline-block;
  top: 0;
  top: 50%;
  left: 20px;
  margin: 0;
  z-index: 1;
  padding: 0 6px;
  transform: translateY(-50%);
  transition: all .2s ease;
  color: var(--color-text-2);
}

.ps-form label .required {
  color: var(--color-danger);
}

.ps-form .form-control {
  position: relative;
  z-index: 9;
  font-weight: 400;
}

.ps-form .form-control:not(:placeholder-shown) + label, .ps-form .form-control:focus + label {
  top: 0;
  z-index: 10;
}

.ps-form.underline .form-control {
  border-radius: 0;
  padding-left: 0;
  border: none;
  border-bottom: 1px solid var(--color-border);
}

.ps-form.underline label {
  left: 0;
  padding: 0;
}

.ps-form.text-area label {
  top: 20px;
}

.ps-form.text-area .form-control:not(:placeholder-shown) + label, .ps-form.text-area .form-control:focus + label {
  top: 0;
  z-index: 10;
}

.ps-form.select select {
  appearance: none;
}

.ps-form.select > i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  z-index: 99;
  font-size: 12px;
}

.ps-form.select:not(.unnerline) > i {
  right: 8px;
}

.ps-form.select:not(.unnerline) select {
  border-color: var(--color-border);
}

.ps-form.select:not(.unnerline) label {
  top: 0;
  z-index: 10;
}

.ps-form.with-icon {
  border-bottom: 1px solid var(--color-border);
  padding-right: 0;
}

.ps-form.with-icon .form-control {
  border-bottom: none;
  text-indent: 30px;
  z-index: 1;
}

.ps-form.with-icon .ps-form__icon {
  display: inline-block;
  max-width: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  z-index: 9;
}

.ps-form:not(.underline) > label {
  background-color: #fff;
  padding: 0 8px;
  color: var(--color-heading);
  left: 10px;
}

.ps-form:not(.underline) .form-control:focus, .ps-form:not(.underline) .form-control:not(:placeholder-shown) {
  border: 1px solid var(--color-border);
}

.ps-form:not(.underline) .form-control:not(:placeholder-shown) + label, .ps-form:not(.underline) .form-control:focus + label {
  color: var(--color-heading);
}

.form-group--number {
  display: inline-block;
  position: relative;
  z-index: 10;
}

.form-group--number button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  max-width: 20px;
  color: var(--color-heading);
  font-size: 30px;
  border: none;
  background: none;
  z-index: 20;
}

.form-group--number button.up {
  right: 12px;
}

.form-group--number button.up:before, .form-group--number button.up:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--color-text);
}

.form-group--number button.up:before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -moz-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  -o-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.form-group--number button.up:after {
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
  -moz-transform: translate(-50%, -50%) rotate(0deg);
  -ms-transform: translate(-50%, -50%) rotate(0deg);
  -o-transform: translate(-50%, -50%) rotate(0deg);
  transform: translate(-50%, -50%) rotate(0deg);
}

.form-group--number button.down {
  left: 12px;
}

.form-group--number button.down:before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--color-text);
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
  -moz-transform: translate(-50%, -50%) rotate(0deg);
  -ms-transform: translate(-50%, -50%) rotate(0deg);
  -o-transform: translate(-50%, -50%) rotate(0deg);
  transform: translate(-50%, -50%) rotate(0deg);
}

.form-group--number .form-control {
  border: 2px solid #eaeaea;
  height: 45px;
  padding: 0 25px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: var(--color-heading);
  background-color: transparent;
}

.form-group--number .form-control::-webkit-input-placeholder {
  color: var(--color-heading);
}

.form-group--number .form-control::-moz-placeholder {
  color: var(--color-heading);
}

.form-group--number .form-control:-moz-placeholder {
  color: var(--color-heading);
}

.form-group--number .form-control:-ms-input-placeholder {
  color: var(--color-heading);
}

.form-group--inside {
  position: relative;
}

.form-group--inside button {
  right: 10px;
  border: none;
  color: #ce873a;
  background-color: transparent;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form-group--inside button:hover {
  color: #ab6d2a;
}

.form-group--radio-inline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
}

@media screen and (max-width: 480px) {
  .form-group--radio-inline {
    flex-flow: column nowrap;
  }
}

.ps-dropdown--ant .ant-dropdown-menu {
  border-radius: var(--border-radius-medium);
  background-color: transparent;
  padding: 0;
  border: 2px solid var(--color-border);
  box-shadow: none;
  overflow: hidden;
}

.ps-dropdown--ant li {
  padding: 0;
}

.ps-dropdown--ant li a {
  margin: 0;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text);
  background-color: #fff;
}

.ps-dropdown--ant li a:hover {
  color: var(--color-1st);
  background-color: var(--color-bg-gray);
}

.ps-dropdown--ant li:last-child a {
  border-bottom: none;
  border-bottom-left-radius: var(--border-radius-medium);
  border-bottom-right-radius: var(--border-radius-medium);
}

.ps-form--slider .ps-form__range {
  padding-bottom: 20px;
}

.ps-form--slider .ps-form__attribute {
  font-weight: 400;
  color: var(--color-heading);
}

.ps-form--slider .ps-form__attributes {
  line-height: 1em;
  margin-bottom: 0;
  font-size: 14px;
}

.ps-form--slider .ps-form__attributes > span {
  margin-right: 10px;
}

.ps-form--slider .ps-form__attributes .ps-form__min {
  margin-right: 0.25em;
}

.ps-form--slider .ps-form__attributes .ps-form__max {
  margin-left: 0.25em;
}

.ps-nouislider {
  width: 100%;
  margin: 10px auto;
}

.noUi-connect {
  background-color: var(--color-1st);
  height: 12px;
}

.noUi-horizontal {
  height: 4px;
  border-radius: 0;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

.noUi-handle:before,
.noUi-handle:after {
  display: none;
}

.noUi-handle {
  border: 3px solid var(--color-1st);
  border-radius: 50%;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle {
  width: 12px;
  height: 18px;
  left: 0px;
  top: -8px;
  border-radius: 0;
  border-radius: 2px;
  border: 1px solid var(--color-border);
  cursor: pointer;
}

.noUi-horizontal .noUi-handle:hover {
  background-color: #fff;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -7px;
}

.ps-post {
  margin-bottom: 30px;
}

.ps-post__badge {
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.ps-post__badge i {
  color: #ffffff;
  font-size: 20px;
}

.ps-post__image {
  position: relative;
  overflow: hidden;
}

.ps-post__image img {
  width: 100%;
  max-width: 100%;
}

.ps-post__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: var(--border-radius-medium);
}

.ps-post__author {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ps-post__author img {
  max-width: 38px;
  border-radius: 50%;
}

.ps-post__author .ps-post__author-name {
  padding-left: 0.5em;
}

.ps-post__author .ps-post__author-name a {
  color: var(--color-heading);
}

.ps-post__categories {
  border-radius: var(--border-radius-small);
}

.ps-post__categories a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1st);
  text-transform: uppercase;
}

.ps-post__meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
}

.ps-post__meta .ps-post__categories {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

.ps-post__meta .ps-post__categories:after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 16px;
  background-color: var(--color-gray);
}

.ps-post__meta span {
  font-size: 13px;
  color: var(--color-text-2);
}

.ps-post__title {
  margin-bottom: 0;
  font-size: 18px;
  color: var(--color-heading);
  line-height: 1.2em;
  font-weight: 500;
}

.ps-post__title a {
  display: block;
  font-size: inherit;
  color: inherit;
  position: relative;
  /*--max-lines: 2;*/
  /*min-height: calc(1.6em * var(--max-lines));*/
}

.ps-post__title a:hover {
  color: var(--color-1st);
}

.ps-post__title:hover {
  text-decoration: underline;
}

.ps-post__desc p {
  color: var(--color-text);
  font-family: "Jost", sans-serif;
}

.ps-post__desc p a {
  color: #000000;
}

.ps-post__desc p a:hover {
  color: var(--color-1st);
}

.ps-post__morelink {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text);
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.4s ease;
}

.ps-post__morelink:hover {
  color: var(--color-1st);
}

.ps-post__morelink.line {
  position: relative;
  padding-left: 26px;
  font-size: 14px;
}

.ps-post__morelink.line:before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1px;
  background-color: var(--color-heading);
  transition: all 0.25s ease;
}

.ps-post__morelink.line i {
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
  font-size: 16px;
}

.ps-post__morelink.line:hover {
  padding-left: 0;
  transition-delay: 0.1s;
}

.ps-post__morelink.line:hover:before {
  visibility: hidden;
  opacity: 0;
}

.ps-post__morelink.line:hover i {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.ps-post__image {
  overflow: hidden;
  border-radius: var(--border-radius-medium);
}

.ps-post__author {
  font-size: 13px;
  color: var(--color-text);
}

.ps-post__author a {
  margin-left: 0.25em;
  font-size: inherit;
  font-weight: 500;
  color: #000000;
}

.ps-post__author a:hover {
  color: var(--color-1st);
}

.ps-post__author i {
  margin-right: 12px;
}

.ps-post__counter {
  font-size: 13px;
  color: var(--color-text);
}

.ps-post__counter i {
  margin-right: 12px;
}

.ps-post__bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-post__bottom span {
  margin-right: 20px;
}

.ps-post__bottom span:last-child {
  margin-right: 0;
}

.ps-post .ps-post__thumbnail {
  position: relative;
  overflow: hidden;
}

.ps-post .ps-post__thumbnail img {
  width: 100%;
  transform-origin: 50% 50%;
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}

.ps-post .ps-post__thumbnail .ps-post__badge {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;
}

.ps-post .ps-post__thumbnail:hover .ps-post__overlay {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.25);
}

.ps-post .ps-post__content {
  padding: 20px 0 0;
}

.ps-post:hover .ps-post__thumbnail .ps-post__overlay:after {
  visibility: visible;
  opacity: 1;
  background: #ffccf3;
  background: -moz-linear-gradient(to bottom, #ffccf3 0%, #ffb7ba 100%);
  background: -webkit-linear-gradient(to bottom, #ffccf3 0%, #ffb7ba 100%);
  background: linear-gradient(to bottom, #ffccf3 0%, #ffb7ba 100%);
  opacity: 0.75;
}

@media screen and (min-width: 1024px) {
  .ps-post__title {
    font-size: 24px;
  }
}

.ps-post--grid {
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.4s ease;
}

.ps-post--grid .ps-post__title {
  margin-bottom: 20px;
  font-size: 20px;
}

.ps-post--grid .ps-post__title a {
  font-weight: 500;
  line-height: 1.4em;
}

.ps-post--grid .ps-post__meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
}

.ps-post--grid .ps-post__desc {
  margin-bottom: 40px;
}

.ps-post--grid .ps-post__desc p {
  margin: 0;
}

.ps-post--grid .ps-post__thumbnail {
  position: relative;
  z-index: 9;
}

.ps-post--grid .ps-post__thumbnail .ps-post__categories {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 99;
  padding: 4px 16px;
  background-color: #fff;
}

.ps-post--grid .ps-post__thumbnail .ps-post__categories a {
  font-weight: 400;
  text-transform: capitalize;
  color: var(--color-heading);
}

.ps-post--grid .ps-post__morelink {
  font-weight: 400;
  position: relative;
  line-height: 1.6em;
  color: var(--color-heading);
}

.ps-post--grid .ps-post__morelink:hover {
  color: var(--color-1st);
}

.ps-post--grid.ps-post--text-only {
  padding: 30px;
  border-radius: 0;
}

.ps-post--grid.large .ps-post__title {
  font-size: 24px;
}

.ps-post--grid.large .ps-post__desc p {
  margin-bottom: 0;
}

.ps-post--grid:hover .ps-post__thumbnail img {
  transform: scale3d(1.05, 1.05, 1.05);
}

.ps-post--grid:hover .ps-post__title a {
  color: var(--color-1st);
}

@media screen and (min-width: 1040px) {
  .ps-post--grid.large .ps-post__title {
    font-size: 30px;
  }
}

.ps-post--horizontal {
  display: grid;
}

.ps-post--horizontal .ps-post__title {
  margin-bottom: 10px;
  font-size: 18px;
}

.ps-post--horizontal .ps-post__title a {
  font-weight: 500;
  line-height: 1.4em;
}

.ps-post--horizontal .ps-post__desc p {
  font-size: 14px;
}

.ps-post--horizontal .ps-post__footer {
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border);
}

.ps-post--horizontal.small {
  grid-template-columns: 60px 1fr;
  grid-gap: 10px;
}

.ps-post--horizontal.small .ps-post__categories:after {
  display: none;
}

.ps-post--horizontal.small .ps-post__categories a {
  font-size: 11px;
  color: var(--color-text);
}

.ps-post--horizontal.small .ps-post__meta {
  padding-bottom: 0;
}

.ps-post--horizontal.small .ps-post__title {
  margin-bottom: 0;
  overflow: hidden;
  font-size: 13px;
  --max-lines: 2;
  line-height: 1.4em;
  max-height: calc(1.4em * var(--max-lines));
}

.ps-post--horizontal.without-thumbnail {
  grid-template-columns: minmax(0, 1fr);
}

.ps-post--horizontal.without-thumbnail .ps-post__thumbnail {
  display: none;
}

@media screen and (min-width: 768px) {
  .ps-post--horizontal {
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
  }
  .ps-post--horizontal .ps-post__wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1200px) {
  .ps-post--horizontal {
    grid-template-columns: 300px 1fr;
  }
  .ps-post--horizontal .ps-post__title {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .ps-post--horizontal .ps-post__desc p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-post--horizontal {
    grid-template-columns: 355px 1fr;
    grid-gap: 40px;
  }
  .ps-post--horizontal.small {
    grid-template-columns: 100px 1fr;
    grid-gap: 20px;
  }
  .ps-post--horizontal.small .ps-post__title {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .ps-post--horizontal .ps-post__wrapper {
    padding-top: 20px;
  }
}

.ps-post--only-text {
  display: grid;
  grid-template-columns: 125px 1fr;
}

.ps-post--only-text .ps-post__created .ps-post__date {
  display: block;
  margin-bottom: 10px;
  color: var(--color-text-2);
  font-size: 60px;
  font-weight: 300;
  line-height: 1em;
}

.ps-post--only-text .ps-post__created .ps-post__month-and-year {
  display: block;
  color: var(--color-text-2);
  font-size: 12px;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
}

.ps-post--only-text .ps-post__title {
  margin-bottom: 25px;
  font-weight: 500;
}

.ps-post--only-text .ps-post__title a {
  font-weight: inherit;
}

@media screen and (max-width: 480px) {
  .ps-post--only-text {
    grid-template-columns: 80px 1fr;
    grid-gap: 10px;
  }
}

/*Detail*/
.ps-post--detail .ps-post__tags {
  padding-top: 25px;
}

.ps-post--detail .ps-post__tags a {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-right: 10px;
  padding: 4px 10px;
  line-height: 18px;
  font-size: 12px;
  color: var(--color-text);
  border-radius: var(--border-radius-medium);
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.05);
}

.ps-post--detail .ps-post__tags a:hover {
  color: #ffffff;
  background-color: var(--color-1st);
}

.ps-post--detail .ps-post__tags a:last-child {
  margin-right: 0;
}

.ps-post--detail .ps-post__title {
  margin-bottom: 25px;
  font-size: 30px;
}

.ps-post--detail .ps-post__bottom {
  margin-bottom: 25px;
}

.ps-post--detail .ps-document h4 {
  font-size: 18px;
}

.ps-post--detail .ps-document h4 .ps-highlight {
  font-weight: inherit;
  color: #f5480c;
}

.ps-post--detail .ps-document ul li, .ps-post--detail .ps-document ol li {
  font-size: 16px;
  color: var(--color-text);
}

.ps-post--detail .ps-document p {
  font-size: 16px;
}

.ps-post--detail .ps-document p a {
  color: #00ccff;
  font-weight: inherit;
  font-size: inherit;
}

.ps-post--detail .ps-document p a:hover {
  text-decoration: underline;
  color: var(--color-1st);
}

@media screen and (min-width: 1024px) {
  .ps-post--detail .ps-post__tags {
    padding-top: 50px;
  }
  .ps-post--detail .ps-post__title {
    margin-bottom: 50px;
    text-align: left;
    font-size: 48px;
  }
  .ps-post--detail .ps-post__bottom {
    margin-bottom: 50px;
  }
  .ps-post--detail .ps-document h4 {
    font-size: 24px;
  }
}

.ps-post--detail-simple h2 {
  font-family: "Jost", sans-serif;
}

.ps-post--detail-simple .ps-post__category {
  padding-bottom: 16px;
  color: var(--color-heading);
  text-transform: uppercase;
  font-size: 12px;
}

.ps-post--detail-simple .ps-post__meta p {
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}

.ps-post--detail-simple .ps-post__meta p a {
  color: var(--color-heading);
}

.ps-post--detail-simple .ps-post__meta p a:hover {
  color: var(--color-1st);
}

.ps-post--detail-simple .ps-post__meta span {
  font-weight: inherit;
  margin-right: 10px;
  font-size: 12px;
}

.ps-post--detail-simple .ps-post__meta span:after {
  content: '-';
  padding-left: 10px;
}

.ps-post--detail-simple .ps-post__meta span:last-child {
  margin-right: 0;
}

.ps-post--detail-simple .ps-post__meta span:last-child:after {
  display: none;
}

.ps-post--detail-simple .ps-post__meta span strong {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.5em;
  color: var(--color-heading);
}

.ps-post--detail-simple .ps-post__header {
  text-align: left;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.ps-post--detail-has-bg h2 {
  color: var(--color-text);
  font-weight: 600;
}

.ps-post--detail-has-bg .ps-post__category {
  display: inline-block;
  margin-bottom: 10px;
  color: var(--color-1st);
  text-transform: uppercase;
}

.ps-post--detail-has-bg .ps-post__meta span a {
  color: var(--color-text);
}

.ps-post--detail-has-bg .ps-post__header {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.ps-post--detail-has-bg .ps-post__header-wrapper {
  padding: 50px 30px;
  width: 100%;
  max-width: 960px;
  background-color: rgba(0, 0, 0, 0.85);
}

.ps-post--detail-has-bg .ps-post__wrapper {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 15px;
}

@media screen and (min-width: 768px) {
  .ps-post--detail-has-bg h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .ps-post--detail-has-bg h2 {
    font-size: 36px;
  }
}

.ps-post--detail-with-hero .ps-post__thumbnail {
  min-height: 50vh;
  position: relative;
  z-index: 10;
}

.ps-post--detail-with-hero .ps-post__thumbnail:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.15);
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0.45) 50%);
  background-image: linear-gradient(to top,rgba(0, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0.45) 50%);
}

.ps-post--detail-with-hero .ps-post__wrapper {
  max-width: 960px;
  margin: 0 auto;
  border-radius: 0;
}

.ps-post--detail-with-hero .ps-post__meta span {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.ps-post--detail-with-hero .ps-post__header {
  margin-bottom: 20px;
}

.ps-post--detail-with-hero .ps-post__header h2 {
  font-family: "Jost", sans-serif;
  margin-bottom: 0;
}

.ps-post--detail-with-hero .ps-post__content {
  padding: 30px 15px;
  background-color: #fff;
}

.ps-post--detail-with-hero .ps-post__wrapper {
  padding: 50px 0 0;
}

.ps-post--detail-with-hero.ps-second h2 {
  margin-bottom: 20px;
}

.ps-post--detail-with-hero.ps-second .ps-post__meta span a {
  color: #fff;
}

.ps-post--detail-with-hero.ps-second .ps-breadcrumb {
  position: relative;
  z-index: 99;
  padding: 0;
  background-color: transparent;
}

.ps-post--detail-with-hero.ps-second .ps-breadcrumb .container {
  padding-left: 0;
}

.ps-post--detail-with-hero.ps-second .ps-breadcrumb .breadcrumb li {
  color: var(--color-text);
}

.ps-post--detail-with-hero.ps-second .ps-breadcrumb .breadcrumb li a {
  color: var(--color-text);
}

.ps-post--detail-with-hero.ps-second .ps-post__thumbnail {
  position: relative;
  z-index: 9;
}

.ps-post--detail-with-hero.ps-second .ps-post__header {
  text-align: left;
  position: relative;
  z-index: 99;
  margin: 0 0 30px;
  width: 100%;
  max-width: 960px;
}

@media screen and (min-width: 1200px) {
  .ps-post--detail-with-hero h2 {
    color: var(--color-contract);
    margin-bottom: 0;
  }
  .ps-post--detail-with-hero .ps-post__meta span {
    color: var(--color-text);
  }
  .ps-post--detail-with-hero .ps-post__thumbnail {
    min-height: 600px;
  }
  .ps-post--detail-with-hero .ps-post__header {
    text-align: left;
    padding-bottom: 30px;
    margin-bottom: 0;
  }
  .ps-post--detail-with-hero .ps-post__content {
    padding: 50px 30px;
  }
  .ps-post--detail-with-hero .ps-post__wrapper {
    position: relative;
    top: -400px;
    margin-bottom: -400px;
    z-index: 99;
  }
  .ps-post--detail-with-hero.ps-second .ps-post__thumbnail {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    flex-basis: 100%;
    height: 100%;
    padding-left: calc((100% - 960px) / 2);
    padding-right: calc((100% - 960px) / 2);
  }
  .ps-post--detail-with-hero.ps-second .ps-post__wrapper {
    top: 0;
    margin-bottom: 0;
  }
  .ps-post--detail-with-hero.ps-second .ps-post__content {
    padding: 0;
  }
}

@media screen and (min-width: 1366px) {
  .ps-post--detail-with-hero .ps-post__header h2 {
    font-size: 50px;
  }
  .ps-post--detail-with-hero .ps-post__content {
    padding: 60px 40px;
  }
}

.ps-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 4px;
  list-style-type: none;
}

.ps-pagination li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-weight: 500;
  border-radius: var(--border-radius-medium);
}

.ps-pagination li a:hover {
  color: #ffffff;
  background-color: var(--color-1st);
}

.ps-pagination li.active a {
  color: #ffffff;
  background-color: var(--color-1st);
}

.ps-pagination li:last-child {
  margin-right: 0;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb li {
  position: relative;
  display: inline-block;
  color: var(--color-heading);
  font-size: 14px;
}

.breadcrumb li:before {
  content: '/';
  margin: 0 10px;
  color: var(--color-text-2);
}

.breadcrumb li a {
  color: var(--color-text-2);
  font-size: 14px;
}

.breadcrumb li a:hover {
  color: var(--color-heading);
}

.breadcrumb li:first-child:before {
  display: none;
}

.ps-countdown {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 250px;
  grid-gap: 10px;
}

.ps-countdown__number {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
}

.ps-countdown__number > span {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-small);
  width: 34px;
  height: 36px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--color-warning);
}

.ps-block--site-features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 30px 0;
  grid-gap: 30px;
}

.ps-block--site-features .ps-block__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid var(--color-border);
}

.ps-block--site-features .ps-block__item i {
  font-size: 48px;
  color: var(--color-1st);
}

.ps-block--site-features .ps-block__item p {
  margin-bottom: 0;
  padding-left: 20px;
}

.ps-block--site-features .ps-block__item p strong {
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  color: var(--color-heading);
}

.ps-block--site-features .ps-block__item p span {
  display: block;
  font-size: 14px;
}

.ps-block--site-features .ps-block__item:last-child {
  border-right: none;
}

.ps-block--category {
  position: relative;
  z-index: 9;
  overflow: hidden;
}

.ps-block--category > * {
  border-radius: var(--border-radius-large);
}

.ps-block--category img {
  width: 100%;
  transition: all .4s ease;
  border-radius: var(--border-radius-large);
}

.ps-block--category .ps-block__image {
  overflow: hidden;
  border-radius: var(--border-radius-large);
}

.ps-block--category .ps-block__name {
  width: 100%;
  background-color: #fff;
  color: var(--color-heading);
}

.ps-block--category .ps-block__name:hover {
  color: #fff;
  background-color: var(--color-1st);
}

.ps-block--category .ps-block__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 1;
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.25);
  visibility: hidden;
  opacity: 0;
}

.ps-block--category .ps-block__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  z-index: 10;
  text-align: center;
}

.ps-block--category:hover img {
  transform: scale(1.08);
}

.ps-block--category:hover .ps-block__overlay {
  visibility: visible;
  opacity: 1;
}

.ps-block--category:hover .ps-block__name {
  color: #fff;
  background-color: var(--color-1st);
  transition-delay: 0.1s;
}

.ps-block--property-type {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  background-color: #e5eff6;
  border-radius: var(--border-radius-medium);
  max-height: 270px;
  transition: all .4s ease;
  cursor: pointer;
}

.ps-block--property-type .ps-block__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 9;
}

.ps-block--property-type h5 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1em;
  color: var(--color-heading);
}

.ps-block--property-type p {
  font-size: 13px;
  color: var(--color-text);
}

.ps-block--property-type .ps-block__image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 30px 16px 0;
}

.ps-block--property-type .ps-block__image img {
  max-height: 80px;
  width: auto;
}

.ps-block--property-type .ps-block__content {
  padding: 30px 10px;
}

.ps-block--property-type:hover {
  background-color: #fff;
  box-shadow: 0 20px 40px 0 rgba(92, 117, 134, 0.25);
}

@media screen and (min-width: 1024px) {
  .ps-block--property-type .ps-block__image {
    padding: 60px 16px 0;
  }
  .ps-block--property-type .ps-block__content {
    padding: 36px 24px 42px;
  }
}

.ps-block--property-type.second {
  display: block;
  background-color: transparent;
  text-align: left;
}

.ps-block--property-type.second .ps-block__image {
  padding: 0 24px;
  justify-content: flex-start;
}

.ps-block--property-type.second .ps-block__content {
  padding-bottom: 0;
}

.ps-block--property-type.second:hover {
  box-shadow: none;
}

.ps-block--neighborhood {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 26px;
}

.ps-block--neighborhood h3 {
  line-height: 1.2em;
  margin-bottom: 20px;
}

.ps-block--neighborhood h3 a {
  font-size: 22px;
  font-weight: 500;
}

.ps-block--neighborhood p {
  margin-bottom: 0;
  line-height: 1.6em;
}

.ps-block--neighborhood p strong {
  color: var(--color-heading);
  font-weight: 500;
}

.ps-block--neighborhood .ps-block__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 9;
  transition: all .25s ease;
  border-radius: var(--border-radius-medium);
}

.ps-block--neighborhood .ps-block__image {
  position: relative;
  border-radius: var(--border-radius-medium);
}

.ps-block--neighborhood:hover .ps-block__overlay {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.25);
}

.ps-block--destination {
  transition: all .4s ease;
  border-radius: var(--border-radius-medium);
}

.ps-block--destination h4 {
  line-height: 1.2em;
  margin-bottom: 8px;
}

.ps-block--destination h4 a {
  font-size: 22px;
  font-weight: 500;
}

.ps-block--destination p {
  margin-bottom: 0;
  line-height: 1.6em;
}

.ps-block--destination p strong {
  color: var(--color-heading);
  font-weight: 500;
}

.ps-block--destination .ps-block__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 9;
  transition: all .25s ease;
  border-radius: var(--border-radius-medium);
}

.ps-block--destination .ps-block__image {
  position: relative;
  border-radius: var(--border-radius-medium);
}

.ps-block--destination .ps-block__content {
  padding: 30px;
  background-color: #fff;
  border-bottom-left-radius: var(--border-radius-medium);
  border-bottom-right-radius: var(--border-radius-medium);
}

.ps-block--destination:hover {
  box-shadow: 0 20px 40px 0 rgba(92, 117, 134, 0.25);
}

.ps-block--destination:hover .ps-block__overlay {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.25);
}

@media screen and (max-width: 479px) {
  .ps-block--destination h4 {
    font-size: 18px;
  }
  .ps-block--destination h4 a {
    font-size: inherit;
  }
  .ps-block--destination .ps-block__content {
    padding: 16px;
  }
}

@media screen and (max-width: 1366px) {
  .ps-block--destination .ps-block__content {
    padding: 20px;
  }
}

.ps-block--site-score {
  max-width: 355px;
  text-align: center;
  padding: 90px 30px 70px;
}

.ps-block--site-score h3 {
  font-size: 130px;
  font-weight: 300;
  line-height: 1em;
  margin-bottom: 40px;
}

.ps-block--site-score .ps-block__ratings {
  padding-bottom: 60px;
}

.ps-block--site-score .ps-block__ratings .ps-block__rating-items i {
  color: #00991d;
  margin-right: 4px;
  font-size: 20px;
}

.ps-block--site-score .ps-block__ratings .ps-block__rating-items i:last-child {
  margin-right: 0;
}

.ps-block--site-score .ps-block__ratings p {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--color-heading);
  font-weight: 500;
}

.ps-block--site-score .ps-block__footer p {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 18px;
  font-weight: 500;
  color: #999;
}

.ps-block--testimonial h4 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--testimonial .ps-block__bottom {
  padding-top: 60px;
}

.ps-block--testimonial .ps-block__bottom p {
  margin-bottom: 0;
  line-height: 1.6em;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--testimonial .ps-block__bottom p span {
  font-size: 14px;
  color: #999999;
}

@media screen and (min-width: 1024px) {
  .ps-block--testimonial h4 {
    font-size: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-block--testimonial h4 {
    font-size: 36px;
  }
}

.ps-block--stat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-block--stat .ps-block__right {
  flex-basis: 100%;
}

.ps-block--stat h3 {
  margin-right: 30px;
  margin-bottom: 0;
  line-height: 1em;
  font-size: 48px;
  color: var(--color-heading);
  font-weight: 400;
}

.ps-block--stat p {
  margin: 0;
  color: #999999;
  font-size: 16px;
  text-transform: uppercase;
}

@media screen and (min-width: 1024px) {
  .ps-block--stat h3 {
    margin-right: 45px;
    font-size: 72px;
  }
  .ps-block--stat p {
    font-size: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .ps-block--stat {
    display: grid;
  }
  .ps-block--stat h3 {
    margin-bottom: 10px;
    font-size: 48px;
  }
  .ps-block--stat p {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .ps-block--stat h3 {
    font-size: 36px;
  }
  .ps-block--stat p {
    font-size: 14px;
  }
}

.ps-block--post-author {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 30px;
  padding: 25px 0;
}

.ps-block--post-author figure figcaption {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-heading);
  line-height: 1em;
}

.ps-block--post-author figure p {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color-text-2);
}

.ps-block--post-author .ps-block__links {
  position: relative;
  left: -10px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-block--post-author .ps-block__links a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-medium);
}

.ps-block--post-author .ps-block__links a:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.05);
}

.ps-block--post-author .ps-block__links a:last-child {
  margin-right: 0;
}

.ps-block--post-author .ps-block__left img {
  max-width: 80px;
  border-radius: 50%;
}

@media screen and (min-width: 1024px) {
  .ps-block--post-author {
    padding: 50px 0;
  }
  .ps-block--post-author .ps-block__right p {
    font-size: 16px;
  }
}

.ps-block-post-comment {
  display: grid;
}

.ps-block-post-comment .ps-block__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.ps-block-post-comment .ps-block__top h4 {
  margin: 0;
  line-height: 1em;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block-post-comment .ps-block__top span {
  color: var(--color-text-2);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1em;
}

.ps-block-post-comment .ps-block__action {
  display: inline-block;
  vertical-align: top;
  padding: 0 1em;
  font-size: 12px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--color-heading);
  border: 1px solid #ccc;
  border-radius: var(--border-radius-medium);
  background-color: transparent;
}

.ps-block-post-comment .ps-block__action:hover {
  color: var(--color-1st);
  border-color: var(--color-1st);
}

.ps-block-post-comment .ps-block__children {
  padding-top: 30px;
}

.ps-block-post-comment .ps-block__content p {
  font-size: 16px;
}

.ps-block-post-comment .ps-block__left img {
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .ps-block-post-comment {
    grid-template-columns: 80px 1fr;
    grid-gap: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-block-post-comment .ps-block__content p {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .ps-block-post-comment .ps-block__left {
    padding-bottom: 20px;
  }
  .ps-block-post-comment .ps-block__children {
    padding-left: 20px;
  }
}

.ps-block--team-member {
  cursor: default;
  box-shadow: 0 20px 50px 0 rgba(70, 82, 91, 0.1);
  transition: all 0.25s ease;
  position: relative;
}

.ps-block--team-member .ps-block__image {
  border-radius: var(--border-radius-medium);
}

.ps-block--team-member .ps-block__top {
  position: static;
  z-index: 1;
}

.ps-block--team-member .ps-block__top .ps-block__information {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  z-index: 9;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease;
}

.ps-block--team-member .ps-block__top .ps-block__information p {
  font-size: 16px;
}

.ps-block--team-member .ps-block__content {
  padding: 16px 10px;
  position: relative;
  z-index: 1;
}

.ps-block--team-member .ps-block__content h4 {
  margin-bottom: 0;
  line-height: 1.4em;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--team-member .ps-block__content p {
  margin-bottom: 0;
}

.ps-block--team-member:hover {
  box-shadow: 0 50px 80px 0 rgba(70, 82, 91, 0.25);
}

.ps-block--team-member:hover .ps-block__information {
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .ps-block--team-member .ps-block__content {
    padding: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-block--team-member .ps-block__content p {
    font-size: 16px;
  }
}

.ps-block--service h4 {
  font-size: 24px;
}

.ps-block--service .ps-block__image img {
  height: 120px;
}

.ps-block--service .ps-block__top {
  padding-bottom: 30px;
}

.ps-block--service .ps-block__content h4 {
  margin-bottom: 30px;
  line-height: 1.2em;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--service .ps-block__content p {
  font-size: 14px;
}

@media screen and (min-width: 1024px) {
  .ps-block--service .ps-block__top {
    padding-bottom: 60px;
  }
  .ps-block--service h4 {
    font-size: 30px;
  }
  .ps-block--service .ps-block__content h4 {
    font-size: 30px;
  }
  .ps-block--service .ps-block__content p {
    font-size: 16px;
  }
}

.ps-block--contact-offices .ps-block__heading {
  margin-bottom: 0;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-text-2);
  font-weight: 400;
}

.ps-block--contact-offices .ps-block__item {
  max-width: 250px;
}

.ps-block--contact-offices .ps-block__item figcaption {
  line-height: 1.2em;
  font-weight: 500;
  color: var(--color-heading);
  margin-bottom: 26px;
  font-size: 18px;
}

.ps-block--contact-offices .ps-block__item p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.8em;
  color: var(--color-text);
}

.ps-block--contact-offices .ps-block__item p span {
  display: block;
}

.ps-block--contact-offices .ps-block__item p span a:hover {
  text-decoration: underline;
  color: var(--color-1st);
}

.ps-block--contact-offices .ps-block__header {
  padding-bottom: 30px;
}

.ps-block--contact-offices .ps-block__column {
  margin-bottom: 35px;
}

.ps-block--contact-offices .ps-block__columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (min-width: 768px) {
  .ps-block--contact-offices .ps-block__item p {
    font-size: 16px;
  }
  .ps-block--contact-offices .ps-block__item p a {
    font-size: inherit;
  }
  .ps-block--contact-offices .ps-block__column {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-block--contact-offices .ps-block__header {
    padding-bottom: 60px;
  }
  .ps-block--contact-offices .ps-block__item figcaption {
    font-size: 24px;
  }
}

.ps-block--404 {
  text-align: center;
}

.ps-block--404 h3 {
  margin-bottom: 20px;
  font-size: 36px;
}

.ps-block--404 p {
  margin-bottom: 30px;
  font-size: 14px;
}

.ps-block--404 .ps-btn {
  margin: 0 auto;
  max-width: 230px;
}

@media screen and (min-width: 768px) {
  .ps-block--404 h3 {
    margin-bottom: 20px;
    font-size: 48px;
  }
  .ps-block--404 p {
    font-size: 16px;
    margin-bottom: 60px;
  }
  .ps-block--404 .ps-block__content {
    position: relative;
    z-index: 10;
    top: -100px;
    margin-bottom: -100px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-block--404 h3 {
    font-size: 54px;
  }
}

.ps-block--faqs .ps-block__top {
  padding-bottom: 25px;
}

.ps-block--faqs .ps-block__top h4 {
  margin-bottom: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--color-text-2);
  line-height: 1em;
  font-weight: 400;
}

.ps-block--faqs .ps-accordion {
  margin-bottom: 0;
}

.ps-block--faqs .ps-accordion:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .ps-block--faqs .ps-block__top {
    padding-top: 50px;
  }
}

.ps-block--job {
  max-width: 370px;
}

.ps-block--job .ps-block__name a {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--job .ps-block__name a:hover {
  text-decoration: underline;
}

.ps-block--job .ps-block__desc p {
  font-size: 14px;
}

.ps-block--job .ps-block__bottom {
  padding-top: 30px;
}

.ps-block--job .ps-block__bottom p {
  text-transform: uppercase;
  color: var(--color-heading);
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .ps-block--job .ps-block__desc p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-block--job .ps-block__name a {
    font-size: 24px;
  }
}

.ps-block--career-detail .ps-block__top {
  padding-bottom: 40px;
}

.ps-block--career-detail .ps-block__top h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.ps-block--career-detail .ps-block__top p {
  font-size: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: var(--color-heading);
}

.ps-block--career-detail .ps-block__content p {
  font-size: 14px;
  color: var(--color-heading);
}

.ps-block--career-detail .ps-block__content li {
  color: var(--color-heading);
}

.ps-block--career-detail .ps-block__bottom {
  padding-top: 40px;
}

.ps-block--career-detail .ps-block__bottom .ps-btn {
  max-width: 200px;
}

@media screen and (min-width: 768px) {
  .ps-block--career-detail .ps-block__content p {
    font-size: 16px;
  }
  .ps-block--career-detail .ps-block__content li {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-block--career-detail .ps-block__top {
    padding-bottom: 80px;
  }
  .ps-block--career-detail .ps-block__top h3 {
    margin-bottom: 30px;
    font-size: 48px;
  }
}

.ps-block--agent {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
}

.ps-block--agent .ps-social-links a {
  color: var(--color-text-2);
}

.ps-block--agent .ps-social-links a:hover {
  color: var(--color-1st);
}

.ps-block--agent .ps-block__name {
  display: block;
  line-height: 1.2em;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--agent .ps-block__name:hover {
  text-decoration: underline;
}

.ps-block--agent .ps-block__position {
  font-size: 14px;
  margin-bottom: 0;
}

.ps-block--agent .ps-block__position a {
  color: var(--color-1st);
  display: inline-block;
  padding-left: 0.25em;
}

.ps-block--agent .ps-block__meta {
  padding: 20px 0 10px;
}

.ps-block--agent .ps-block__image {
  border-radius: 5px 5px 0 0;
}

.ps-block--agent .ps-block__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 9;
  background-color: #99cdff;
  font-size: 80px;
  transition: all .25s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-block--agent .ps-block__overlay span {
  color: #fff;
}

.ps-block--agent .ps-block__thumbnail {
  position: relative;
}

.ps-block--agent .ps-block__content {
  padding: 24px 28px;
}

.ps-block--agent:hover .ps-block__overlay {
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .ps-block--agent .ps-block__name {
    font-size: 22px;
  }
}

.ps-block--agent-information .ps-block__top {
  display: grid;
  padding-bottom: 30px;
}

.ps-block--agent-information .ps-block__top-left h3 {
  font-size: 24px;
  line-height: 1em;
}

.ps-block--agent-information .ps-block__top-left p {
  margin-bottom: 0;
  font-size: 14px;
}

.ps-block--agent-information .ps-block__top-left p a {
  color: var(--color-1st);
  font-size: inherit;
}

.ps-block--agent-information .ps-block__top-left p a:hover {
  text-decoration: underline;
}

.ps-block--agent-information .ps-block__rating {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-block--agent-information .ps-block__rating span {
  font-size: 13px;
  line-height: 1em;
  color: var(--color-text-2);
  margin-left: 0.5em;
}

.ps-block--agent-information .ps-block__top-right h4 {
  line-height: 1em;
  color: #669900;
  font-weight: 500;
}

.ps-block--agent-information .ps-block__social-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-block--agent-information .ps-block__social-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-radius: var(--border-radius-medium);
}

.ps-block--agent-information .ps-block__social-links a:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
}

.ps-block--agent-information .ps-block__social-links a:hover:hover {
  text-decoration: none;
}

.ps-block--agent-information .ps-block__actions {
  display: flex;
}

.ps-block--agent-information .ps-block__actions .ps-btn {
  max-width: 200px;
  width: 150px;
  margin-right: 10px;
}

.ps-block--agent-information .ps-block__bottom p strong {
  font-weight: 500;
  color: #000000;
  padding-right: 0.5em;
}

.ps-block--agent-information .ps-block__bottom p a:hover {
  text-decoration: underline;
}

.ps-block--agent-information .ps-block__bottom figure {
  padding-bottom: 20px;
}

.ps-block--agent-information .ps-block__bottom figure:first-child {
  margin-bottom: 30px;
  border-bottom: 1px dashed var(--color-border);
}

@media screen and (min-width: 768px) {
  .ps-block--agent-information .ps-block__top-left h3 {
    font-size: 36px;
  }
  .ps-block--agent-information .ps-block__top-left p {
    font-size: 16px;
  }
  .ps-block--agent-information .ps-block__top-right {
    text-align: right;
  }
  .ps-block--agent-information .ps-block__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .ps-block--agent-information .ps-block__bottom p {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .ps-block--agent-information .ps-block__top-right .ps-block__rating {
    justify-content: flex-start;
  }
}

.ps-block--agent-review {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-column-gap: 30px;
}

.ps-block--agent-review .ps-block__image img {
  border-radius: 50%;
}

.ps-block--agent-review .ps-block__header {
  display: grid;
  grid-template-columns: 1fr 70px;
  padding-bottom: 16px;
}

.ps-block--agent-review .ps-block__header h4 {
  margin-bottom: 0;
  font-size: 16px;
}

.ps-block--agent-review .ps-block__header .ps-rating i {
  font-size: 12px;
  margin-right: 2px;
}

.ps-block--agent-review .ps-block__bottom {
  padding-top: 15px;
}

.ps-block--agent-review .ps-block__bottom p {
  font-size: 14px;
}

.ps-block--agent-review .ps-block__bottom p strong {
  font-weight: 500;
  color: var(--color-heading);
}

@media screen and (min-width: 768px) {
  .ps-block--agent-review {
    grid-column-gap: 35px;
  }
  .ps-block--agent-review .ps-block__header {
    padding-bottom: 25px;
  }
  .ps-block--agent-review .ps-block__header h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .ps-block--agent-review {
    grid-column-gap: 16px;
  }
  .ps-block--agent-review .ps-block__header {
    grid-template-columns: 1fr;
  }
}

.ps-block--agent-quick-info {
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-column-gap: 28px;
}

.ps-block--agent-quick-info h4 {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.2em;
}

.ps-block--agent-quick-info p {
  margin-bottom: 0;
}

.ps-block--agent-quick-info a {
  color: var(--color-1st);
  text-decoration: underline;
}

.ps-block--agent-quick-info .ps-block__image {
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.ps-block--agent-quick-info .ps-block__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.ps-block--project-nearby .ps-block__heading {
  margin-bottom: 40px;
  font-size: 20px;
  fw4500display: grid;
  font-weight: 500;
  color: var(--color-heading);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-block--project-nearby .ps-block__heading i {
  margin-right: 20px;
}

.ps-block--project-nearby .ps-block__item-left p {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--project-nearby .ps-block__item-left span {
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: var(--color-text);
}

.ps-block--project-nearby .ps-block__item-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-block--project-nearby .ps-block__item-right .ps-rating i {
  font-size: 12px;
}

.ps-block--project-nearby .ps-block__item-right small {
  display: inline-block;
  font-size: 13px;
  margin-left: 6px;
  color: var(--color-text-2);
}

.ps-block--project-nearby .ps-block__item {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-column-gap: 30px;
  margin-bottom: 20px;
}

.ps-block--project-nearby .ps-block__item:last-child {
  margin-bottom: 0;
}

.ps-block--project-reviews > .ps-block__top {
  padding-bottom: 25px;
}

.ps-block--project-reviews > .ps-block__top p {
  margin: 0;
}

.ps-block--project-reviews > .ps-block__top p strong {
  color: var(--color-success);
  margin-right: 10px;
  font-weight: 500;
  font-size: 20px;
}

.ps-block--project-reviews > .ps-block__top p span {
  font-size: 13px;
}

.ps-block--project-reviews > .ps-block__top p .ps-rating {
  margin-right: 10px;
}

.ps-block--project-reviews > .ps-block__top p .ps-rating i {
  font-size: 13px;
}

.ps-block--project-reviews > .ps-block__bottom .ps-form--agent-review {
  margin-top: 35px;
}

.ps-block--project-reviews .ps-block--agent-review {
  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: 1px solid var(--color-border);
}

.ps-block--project-reviews .ps-block--agent-review:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .ps-block--project-reviews > .ps-block__top {
    padding-bottom: 50px;
  }
  .ps-block--project-reviews > .ps-block__bottom .ps-form--agent-review {
    margin-top: 70px;
  }
}

.ps-block--walkscore .ps-block__top {
  padding-bottom: 20px;
}

.ps-block--walkscore .ps-block__top p {
  margin-bottom: 0;
  color: var(--color-heading);
}

.ps-block--walkscore .ps-block__item figure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  width: 90px;
  height: 90px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.ps-block--walkscore .ps-block__item figure span {
  margin-bottom: 5px;
  color: var(--color-success);
  font-size: 42px;
  line-height: 1em;
}

.ps-block--walkscore .ps-block__item figure small {
  font-size: 10px;
  color: var(--color-heading);
  text-transform: uppercase;
}

.ps-block--walkscore .ps-block__item .ps-block__item-bottom h5 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.4em;
}

.ps-block--walkscore .ps-block__item .ps-block__item-bottom p {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--color-text-2);
}

.ps-block--walkscore .ps-block__links {
  margin-bottom: 20px;
}

.ps-block--walkscore .ps-block__links a {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--color-text);
  margin-right: 40px;
}

.ps-block--walkscore .ps-block__links a.active, .ps-block--walkscore .ps-block__links a:hover {
  color: var(--color-1st);
}

.ps-block--walkscore .ps-block__links a:last-child {
  margin-right: 0;
}

.ps-block--walkscore .ps-block__items {
  display: grid;
  grid-row-gap: 20px;
  padding-bottom: 20px;
  grid-template-columns: repeat(2, 1fr);
}

.ps-block--walkscore .ps-block__bottom p {
  font-size: 16px;
  color: var(--color-1st);
  margin-bottom: 25px;
}

.ps-block--walkscore .ps-block__bottom p a {
  text-decoration: underline;
}

.ps-block--walkscore .ps-block__bottom p i {
  margin-right: 20px;
}

.ps-block--walkscore .ps-block__bottom .form-group {
  position: relative;
}

.ps-block--walkscore .ps-block__bottom .form-group > i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

@media screen and (min-width: 1204px) {
  .ps-block--walkscore .ps-block__top {
    padding-bottom: 40px;
  }
  .ps-block--walkscore .ps-block__top p {
    font-size: 20px;
  }
  .ps-block--walkscore .ps-block__links {
    margin-bottom: 40px;
  }
  .ps-block--walkscore .ps-block__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding-bottom: 40px;
  }
}

.ps-block--mortgage-chart {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 10px;
}

.ps-block--mortgage-chart .ps-block__chart {
  position: relative;
}

.ps-block--mortgage-chart .ps-block__chart .ps-block__chart-stat {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.ps-block--mortgage-chart .ps-block__chart .ps-block__chart-stat strong {
  line-height: 1em;
  font-size: 24px;
  font-weight: 500;
}

.ps-block--mortgage-chart .ps-block__chart .ps-block__chart-stat span {
  font-size: 14px;
  color: var(--color-text);
}

.ps-block--mortgage-chart .ps-block__item {
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.ps-block--mortgage-chart .ps-block__item:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-1st);
}

.ps-block--mortgage-chart .ps-block__item span {
  display: block;
  line-height: 1em;
  margin-bottom: 10px;
}

.ps-block--mortgage-chart .ps-block__item strong {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-block--gallery-image {
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.ps-block--user-dashboard {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 16px;
}

.ps-block--user-dashboard .ps-block__content {
  align-self: center;
}

.ps-block--user-dashboard .ps-block__content h4 {
  font-size: var(--font-size-default);
  font-weight: 400;
}

.ps-block--user-dashboard .ps-block__content h4 strong {
  font-weight: 500;
}

.ps-block--notification .ps-block__icon {
  padding: 0;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps-block--notification .ps-block__icon i {
  font-size: 30px;
  color: var(--color-text-2);
}

.ps-block--notification h4 small {
  margin-left: 20px;
  font-size: 0.6em;
  text-transform: uppercase;
  color: var(--color-text-2);
}

.ps-block--notification .ps-block__actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.ps-block--notification .ps-block__actions a {
  padding: 0 16px;
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-block--notification .ps-block__actions .ps-btn--danger {
  background-color: hsla(var(--color-danger-h), var(--color-danger-s), var(--color-danger-l), 0.15);
  color: var(--color-danger);
}

.ps-block--notification .ps-block__actions .ps-btn--danger:hover {
  color: #fff;
  background-color: hsla(var(--color-danger-h), var(--color-danger-s), var(--color-danger-l), 1);
}

@media screen and (min-width: 1024px) {
  .ps-block--notification {
    display: grid;
    grid-template-columns: 80px minmax(0, 1fr);
  }
}

.ps-rating i {
  margin-right: 4px;
  font-size: 14px;
  color: var(--color-text);
}

.ps-rating i.active {
  color: #faa500;
}

.ps-rating i:last-child {
  margin-right: 0;
}

#back2top {
  position: fixed;
  bottom: 60px;
  right: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  background-color: #fff;
  transition: all 0.25s ease;
  z-index: 999;
  cursor: pointer;
}

#back2top i {
  font-size: 14px;
  color: var(--color-heading);
}

#back2top:hover, #back2top:focus {
  background-color: var(--color-1st);
}

#back2top:hover i, #back2top:focus i {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  #back2top {
    display: none;
  }
}

.ps-site-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all .25s ease;
}

.ps-site-overlay.active {
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 1200px) {
  .w-2 {
    width: 50%;
  }
  .w-3 {
    width: 33.33333%;
  }
  .w-4 {
    width: 25%;
  }
  .w-5 {
    width: 20%;
  }
  .w-6 {
    width: 16.66667%;
  }
  .w-7 {
    width: 14.28571%;
  }
  .w-8 {
    width: 12.5%;
  }
  .w-9 {
    width: 11.11111%;
  }
  .w-10 {
    width: 10%;
  }
  .w-11 {
    width: 9.09091%;
  }
  .w-12 {
    width: 8.33333%;
  }
}

.ps-panel--sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 470px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(100%);
  border-left: 1px solid #eaeaea;
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.ps-panel--sidebar .ps-panel__close {
  font-size: 30px;
  color: var(--color-text);
}

.ps-panel--sidebar .ps-panel__header {
  position: relative;
  padding: 20px 30px 0;
}

.ps-panel--sidebar .ps-panel__content {
  height: 100%;
  padding: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.ps-panel--sidebar .ps-panel__content > * {
  height: 100%;
  width: 100%;
}

.ps-panel--sidebar .ps-panel__bottom {
  max-height: 100px;
}

.ps-panel--sidebar.active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

@media (min-width: 768px) {
  .ps-panel--sidebar .ps-panel__close {
    font-size: 40px;
  }
  .ps-panel--sidebar .ps-panel__header {
    padding: 20px 30px 0;
  }
  .ps-panel--sidebar .ps-panel__content {
    padding: 50px;
  }
}

.ps-panel--box {
  padding: 16px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
}

.ps-panel--box .ps-panel__heading {
  font-size: 24px;
}

.ps-panel--box .ps-panel__top {
  padding-bottom: 24px;
}

.ps-panel--box .ps-panel__pagination {
  padding-top: 30px;
}

@media screen and (min-width: 1024px) {
  .ps-panel--box {
    padding: 32px;
  }
  .ps-panel--box .ps-panel__top {
    padding-bottom: 48px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .ps-panel--box .ps-panel__top > * {
    align-self: center;
  }
  .ps-panel--box .ps-panel__top .ps-panel__heading {
    margin-bottom: 0;
  }
  .ps-panel--box .ps-panel__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ps-widget--footer a:hover {
  text-decoration: underline;
}

.ps-widget--footer .ps-widget__links a {
  display: block;
  color: #fff;
  line-height: 18px;
  padding: 8px 0;
  text-transform: capitalize;
  font-size: var(--font-size-default);
}

.ps-widget--footer .ps-widget__links a:hover {
  text-decoration: underline;
}

.ps-widget--footer .ps-form--widget-subscribe {
  max-width: 455px;
}

.ps-widget-footer-contact p {
  margin-bottom: 20px;
  color: #ffffff;
}

.ps-widget-footer-contact p:last-child {
  margin-bottom: 0;
}

.ps-widget--popular-posts .ps-post--horizontal {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-border);
}

.ps-widget--popular-posts .ps-post--horizontal:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.ps-widget--blog .ps-widget__heading {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 18px;
}

@media screen and (min-width: 1024px) {
  .ps-widget--blog .ps-widget__heading {
    font-size: 24px;
  }
}

.ps-widget--deparments .ps-widget__links li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  padding: 10px 0;
  font-size: 14px;
  color: var(--color-text);
}

.ps-widget--deparments .ps-widget__links li a:hover {
  color: var(--color-1st);
}

@media screen and (min-width: 1024px) {
  .ps-widget--deparments .ps-widget__links li a {
    font-size: 16px;
    line-height: 20px;
  }
}

.ps-widget--project .ps-widget__heading {
  font-size: 18px;
  margin-bottom: 20px;
}

.ps-widget--project .ps-widget__attributes .ps-checkbox {
  margin-bottom: 12px;
}

.ps-widget--project .ps-widget__attributes .ps-checkbox label {
  color: var(--color-heading);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}

.ps-widget--project .ps-widget__attributes .ps-checkbox label span {
  color: var(--color-text-2);
}

.ps-widget--project .ps-widget__attributes .ps-checkbox input[type=checkbox]:checked ~ label {
  color: var(--color-1st);
}

.ps-widget--project .ps-widget__attributes .ps-checkbox input[type=checkbox]:checked ~ label:before {
  background-color: var(--color-1st);
  border-color: var(--color-1st);
}

.ps-widget--project .ps-widget__attributes .ps-checkbox input[type=checkbox]:checked ~ label:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .ps-widget--project .ps-widget__heading {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

.ps-carousel .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all .4s ease;
}

.ps-carousel .owl-nav .owl-next, .ps-carousel .owl-nav .owl-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-medium);
  background-color: #f2f6f9 !important;
  transform: translateY(-50%);
}

.ps-carousel .owl-nav .owl-next i, .ps-carousel .owl-nav .owl-prev i {
  font-size: 16px;
  color: var(--color-1st);
}

.ps-carousel .owl-nav .owl-next:hover, .ps-carousel .owl-nav .owl-prev:hover {
  background-color: var(--color-1st) !important;
}

.ps-carousel .owl-nav .owl-next:hover i, .ps-carousel .owl-nav .owl-prev:hover i {
  color: #ffffff;
}

.ps-carousel .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10px;
}

.ps-carousel .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.2);
}

.ps-carousel .owl-dots .owl-dot.active {
  border-radius: 10px;
  width: 20px;
  background-color: var(--color-1st);
}

.ps-carousel:hover .owl-nav {
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 1024px) {
  .ps-carousel .owl-dots {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .ps-carousel .owl-nav {
    display: none;
  }
}

.ps-banner {
  position: relative;
  border-radius: var(--border-radius-large);
}

.ps-banner .ps-banner__image {
  overflow: hidden;
  border-radius: var(--border-radius-large);
}

.ps-banner p {
  font-size: 16px;
  color: var(--color-heading);
  font-weight: 300;
  margin-bottom: 0;
}

.ps-banner h3 {
  font-size: 48px;
  font-weight: 600;
  color: var(--color-heading);
}

.ps-banner h5 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 400;
}

.ps-banner h5 del {
  color: var(--color-danger);
  font-size: 0.75em;
}

.ps-banner .ps-btn {
  max-width: 160px;
}

@media screen and (min-width: 1200px) {
  .ps-banner .ps-banner__content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
  }
}

.ps-table .ps-table__actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.ps-table .ps-table__actions a {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.05);
  border-radius: var(--border-radius-medium);
}

.ps-table .ps-table__actions a:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.25);
}

.ps-table thead > tr > th {
  padding: 16px 0;
  font-size: var(--font-size-default);
  font-weight: 500;
  color: var(--color-heading);
  border-bottom: 1px solid var(--color-border);
  border-top: none;
}

.ps-table tbody > tr > td {
  vertical-align: middle;
  padding: 16px 0;
  border: none;
  font-size: var(--font-size-default);
  color: var(--color-text);
  border-bottom: 1px solid #ddd;
}

.ps-table tbody > tr:last-child td {
  border-bottom: none;
}

@media (max-width: 768px) {
  .ps-table {
    border: none;
  }
  .ps-table thead {
    display: none;
  }
  .ps-table tbody {
    display: block;
  }
  .ps-table tbody tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid var(--color-border);
  }
  .ps-table tbody tr td[data-label] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 12px 8px;
    border-top: 1px solid var(--color-border);
  }
  .ps-table tbody tr td[data-label]:before {
    content: attr(data-label) ":";
    font-weight: 400;
  }
  .ps-table tbody tr td[data-label]:first-child {
    border-top: none;
  }
}

@media screen and (min-width: 1200px) {
  .ps-table.ps-table--fixed {
    table-layout: fixed;
  }
}

.ps-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease;
}

.ps-dialog__wrapper {
  background-color: #fff;
  overflow: auto;
}

@media screen and (max-width: 1280px) {
  .ps-dialog__wrapper {
    max-height: 500px;
  }
}

@media screen and (max-width: 1366px) {
  .ps-dialog__wrapper {
    max-height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .ps-dialog {
    padding: 0 16px;
  }
}

.ps-dialog.active {
  visibility: visible;
  opacity: 1;
}

.ps-dialog--search .ps-dialog__wrapper {
  background-color: transparent;
}

.ps-accordion {
  margin-bottom: 20px;
}

.ps-accordion__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.ps-accordion__heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-accordion__heading p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.ps-accordion__header {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 50px;
  padding: 6px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--color-border);
}

.ps-accordion__header p {
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 700;
  color: var(--color-heading);
}

.ps-accordion__header > span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 40px;
  height: 40px;
  background-color: #222;
}

.ps-accordion__header > span i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.ps-accordion__content {
  padding-top: 30px;
  display: none;
}

.ps-accordion__content > * {
  margin-bottom: 25px;
}

.ps-accordion__content p {
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .ps-accordion__heading p {
    font-size: 24px;
  }
  .ps-accordion__content p {
    font-size: 16px;
  }
}

.ps-accordion--floor-plan .ps-accordion__icon {
  width: 30px;
  height: 30px;
}

.ps-accordion--floor-plan .ps-accordion__header {
  padding: 10px 24px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
}

.ps-accordion--floor-plan .ps-accordion__header p {
  margin-bottom: 0;
  color: var(--color-text-2);
  align-self: center;
}

.ps-accordion--floor-plan .ps-accordion__header p strong {
  font-weight: 500;
  color: var(--color-heading);
}

.ps-accordion--floor-plan .ps-accordion__header .ps-accordion__params {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 15px;
}

.ps-accordion--floor-plan .ps-accordion__header-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.ps-accordion--floor-plan .ps-accordion__content {
  padding: 10px 24px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
  margin-top: 20px;
}

.ps-tab {
  display: none;
}

.ps-tab.active {
  display: block;
}

.ps-layout--grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.ps-layout--grid.with-skeleton .ps-layout__item {
  padding: 10px;
  /*     border: none;*/
}

.ps-layout--grid.with-skeleton .ps-layout__item .ps-skeleton--product {
  margin-bottom: 0;
}

@media screen and (min-width: 480px) {
  .ps-layout--grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .ps-layout--grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .ps-layout--grid {
    grid-gap: 30px;
  }
  .ps-layout--grid[data-columns="2"] {
    grid-template-columns: repeat(2, 1fr);
  }
  .ps-layout--grid[data-columns="3"] {
    grid-template-columns: repeat(3, 1fr);
  }
  .ps-layout--grid[data-columns="4"] {
    grid-template-columns: repeat(4, 1fr);
  }
  .ps-layout--grid[data-columns="5"] {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
  }
  .ps-layout--grid[data-columns="6"] {
    grid-template-columns: repeat(6, 1fr);
  }
}

.ps-layout--list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

@media screen and (min-width: 768px) {
  .ps-layout--list {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.ps-layout--with-sidebar {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

@media screen and (min-width: 1024px) {
  .ps-layout--with-sidebar {
    grid-template-columns: minmax(0, 1fr) 255px;
    grid-gap: 30px;
    min-height: 80vh;
  }
  .ps-layout--with-sidebar .ps-layout__left {
    max-width: calc(1140px - 285px);
  }
  .ps-layout--with-sidebar.ps-reverse {
    grid-template-columns: 255px 1fr;
  }
  .ps-layout--with-sidebar.ps-reverse .ps-layout__left {
    grid-column-start: 2;
  }
  .ps-layout--with-sidebar.ps-reverse .ps-layout__right {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .ps-layout--with-sidebar.ps-reverse .ps-layout__right {
    max-width: 992px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-layout--with-sidebar {
    grid-gap: 60px;
    grid-template-columns: 1fr 355px;
  }
  .ps-layout--with-sidebar .ps-layout__left {
    max-width: calc(1240px - 415px);
  }
  .ps-layout--with-sidebar.ps-reverse {
    grid-template-columns: 355px 1fr;
  }
}

@media screen and (min-width: 1600px) {
  .ps-layout--with-sidebar {
    grid-gap: 100px;
  }
  .ps-layout--with-sidebar .ps-layout__left {
    max-width: calc(1510px - 455px);
  }
}

@media screen and (max-width: 480px) {
  .ps-layout--with-sidebar .ps-layout__right {
    max-width: 100vw;
  }
}

.ps-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
}

@media screen and (min-width: 768px) {
  .ps-grid[data-columns='2'] {
    grid-template-columns: repeat(2, 1fr);
  }
  .ps-grid[data-columns='3'] {
    grid-template-columns: repeat(2, 1fr);
  }
  .ps-grid[data-columns='4'] {
    grid-template-columns: repeat(3, 1fr);
  }
  .ps-grid[data-columns='5'] {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .ps-grid[data-columns='3'] {
    grid-template-columns: repeat(3, 1fr);
  }
  .ps-grid[data-columns='4'] {
    grid-template-columns: repeat(4, 1fr);
  }
  .ps-grid[data-columns='5'] {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1366px) {
  .ps-grid[data-columns='5'] {
    grid-template-columns: repeat(5, 1fr);
  }
}

.ps-skeleton--product {
  overflow: hidden;
  margin-bottom: 30px;
}

.ps-skeleton-product-detail .row > * {
  overflow: hidden;
}

.ps-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 320px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  grid-row-gap: 10px;
  background-color: #fff;
  transform: translateX(100%);
  padding: 0 16px;
  border-left: 1px solid #eaeaea;
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.ps-drawer .ps-drawer__close {
  color: var(--color-text);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps-drawer .ps-drawer__close i {
  font-size: 20px;
}

.ps-drawer .ps-drawer__heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.ps-drawer .ps-drawer__top {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.ps-drawer.active {
  transform: translateX(0);
}

.ps-theme-settings-toggle {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 999;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-1st);
}

.ps-project {
  transition: all 0.25s ease;
  cursor: default;
}

.ps-project__image {
  overflow: hidden;
}

.ps-project__image img {
  width: 100%;
  transition: all 0.25s ease-out;
}

.ps-project__badge {
  display: inline-block;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff;
  vertical-align: top;
  border-radius: var(--border-radius-small);
}

.ps-project__badge.featured {
  background-color: var(--color-2nd);
}

.ps-project__badge:hover {
  color: #fff;
}

.ps-project__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.ps-project__actions a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-small);
  background-color: rgba(0, 0, 0, 0.25);
  transition: all .25s ease;
  color: #ffffff;
}

.ps-project__actions a:hover {
  background-color: var(--color-2nd);
  color: #ffffff;
}

.ps-project__name {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  color: var(--color-heading);
  line-height: 1.4em;
}

.ps-project__name > a {
  font: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.ps-project__name:hover {
  text-decoration: underline;
}

.ps-project__addess p {
  margin-bottom: 0;
  font-size: 13px;
}

.ps-project__price {
  line-height: 1em;
}

.ps-project__price strong {
  font-size: 18px;
  font-weight: 500;
  color: #669900;
}

.ps-project__price span {
  text-transform: capitalize;
  font-size: 14px;
  color: var(--color-text-2);
}

.ps-project__meta {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.ps-project__meta figcaption {
  font-size: 13px;
  color: var(--color-text-2);
}

.ps-project__meta p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-project__services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.ps-project__services p {
  margin: 0;
}

.ps-project__services span {
  font-size: 13px;
}

.ps-project__services span i {
  margin-right: 10px;
  font-size: 14px;
}

.ps-project__thumbnail {
  position: relative;
  z-index: 9;
}

.ps-project__thumbnail .ps-project__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 1;
}

.ps-project__thumbnail .ps-project__actions {
  position: absolute;
  top: 20px;
  right: 26px;
  z-index: 9;
}

.ps-project__thumbnail .ps-project__badges {
  position: absolute;
  top: 20px;
  left: 26px;
  z-index: 9;
}

.ps-project:hover .ps-project__overlay {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.25);
}

.ps-project:hover .ps-project__image img {
  transform: scale3d(1.05, 1.05, 1.05);
}

@media screen and (min-width: 480px) {
  .ps-project__name {
    font-size: 24px;
  }
}

.ps-project--grid {
  border: 1px solid #ccc;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.ps-project--grid .ps-project__address {
  margin-bottom: 20px;
  font-size: 13px;
}

.ps-project--grid .ps-project__price {
  margin-bottom: 26px;
}

.ps-project--grid .ps-project__meta {
  padding-bottom: 26px;
  margin-bottom: 26px;
  border-bottom: 1px dashed var(--color-border);
}

.ps-project--grid .ps-project__content {
  padding: 16px;
  background-color: #fff;
}

.ps-project--grid.with-simple .ps-project__meta, .ps-project--grid.with-simple .ps-project__services {
  grid-template-columns: 1fr 1fr;
}

@media screen and (min-width: 480px) {
  .ps-project--grid.with-simple .ps-project__name {
    font-size: 20px;
  }
}

@media screen and (min-width: 480px) {
  .ps-project--grid.with-full .ps-project__name {
    font-size: 20px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-project--grid.with-full .ps-project__meta, .ps-project--grid.with-full .ps-project__services {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1600px) {
  .ps-project--grid .ps-project__content {
    padding: 26px;
    background-color: #fff;
  }
}

@media screen and (max-width: 479px) {
  .ps-project--grid .ps-project__content {
    padding: 26px 16px;
  }
}

.ps-project--list {
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
}

.ps-project--list .ps-project__meta {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px dashed var(--color-border);
}

.ps-project--list .ps-project__name {
  margin-bottom: 0;
}

.ps-project--list .ps-project__top {
  display: grid;
  grid-template-columns: 1fr;
}

.ps-project--list .ps-project__top .ps-project__address {
  margin-bottom: 0;
}

.ps-project--list .ps-project__wrapper {
  padding: 30px 30px 25px;
}

.ps-project--list.without-thumbnail {
  grid-template-columns: 1fr;
}

.ps-project--list.without-thumbnail .ps-project__thumbnail {
  display: none;
}

@media screen and (min-width: 768px) {
  .ps-project--list .ps-project__top {
    display: grid;
    grid-template-columns: 1fr 200px;
    padding-bottom: 30px;
    grid-column-gap: 10px;
  }
  .ps-project--list .ps-project__top .ps-project__top-right {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1024px) {
  .ps-project--list {
    grid-template-columns: 350px 1fr;
  }
  .ps-project--list .ps-project__image {
    height: 100%;
  }
  .ps-project--list .ps-project__image img {
    height: 100%;
    object-fit: cover;
  }
  .ps-project--list .ps-project__meta {
    grid-template-columns: repeat(5, 1fr);
  }
  .ps-project--list .ps-project__services {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .ps-project--list .ps-project__top-right {
    padding-top: 20px;
  }
}

.ps-projects__layout-switchers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-projects__layout-switchers a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  color: var(--color-text);
  border-radius: var(--border-radius-medium);
  margin-right: 10px;
}

.ps-projects__layout-switchers a i {
  display: block;
  line-height: 1em;
  font-size: 18px;
}

.ps-projects__layout-switchers a:last-child {
  margin-right: 0;
}

.ps-projects__layout-switchers a:hover, .ps-projects__layout-switchers a.active {
  color: var(--color-heading);
}

.ps-projects__layout-switchers a:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
}

.ps-projects__sorting {
  max-width: 250px;
}

.ps-projects__sorting select {
  height: 36px;
  border: none;
}

.ps-projects__sorting select:focus + i {
  visibility: visible;
  opacity: 1;
}

.ps-projects__top-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-projects__top-left p {
  margin-bottom: 0;
}

.ps-projects__top-left p strong {
  font-weight: 500;
  color: var(--color-1st);
}

.ps-projects__top-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-projects__top-right .ps-projects__sorting {
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid var(--color-border);
}

.ps-projects__top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 20px;
}

.ps-projects__pagination {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps-projects__items .ps-project {
  height: 100%;
}

.ps-projects__items .ps-project--list {
  margin-bottom: 40px;
}

.ps-projects__items.ps-grid {
  grid-gap: 20px;
}

.ps-projects__items[data-columns='5'] {
  grid-gap: 16px;
}

@media screen and (min-width: 768px) {
  .ps-projects__items {
    grid-gap: 30px;
  }
  .ps-projects__top {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-projects__items {
    grid-gap: 16px;
  }
  .ps-projects__items[data-columns='5'] .ps-project {
    height: 100%;
  }
  .ps-projects__pagination {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-projects__items {
    grid-gap: 24px;
  }
  .ps-projects__items[data-columns='5'] {
    grid-gap: 24px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1600px) {
  .ps-projects__items {
    grid-gap: 40px;
  }
  .ps-projects__items[data-columns='5'] {
    grid-gap: 16px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media screen and (max-width: 479px) {
  .ps-projects__top {
    display: grid;
    display: flex;
    flex-flow: column nowrap;
  }
  .ps-projects__top .ps-projects__sorting {
    margin-right: 0;
    padding-right: 10px;
  }
  .ps-projects__top .ps-projects__sorting select {
    padding-left: 0;
  }
  .ps-projects__top .ps-projects__top-left {
    padding-bottom: 10px;
  }
  .ps-projects__top .ps-projects__top-right {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.ps-projects--with-sidebar {
  display: grid;
}

.ps-projects--with-sidebar .ps-widget--project {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--color-border);
}

.ps-projects--with-sidebar .ps-widget--project:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

@media screen and (min-width: 1024px) {
  .ps-projects--with-sidebar {
    grid-template-columns: 255px 1fr;
    grid-column-gap: 30px;
  }
  .ps-projects--with-sidebar.ps-reverse {
    grid-template-columns: 1fr 255px;
  }
  .ps-projects--with-sidebar.ps-reverse .ps-projects__left {
    grid-column-start: 2;
  }
  .ps-projects--with-sidebar.ps-reverse .ps-projects__right {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

@media screen and (min-width: 1366px) {
  .ps-projects--with-sidebar {
    grid-column-gap: 60px;
  }
}

@media screen and (min-width: 1600px) {
  .ps-projects--with-sidebar {
    grid-column-gap: 100px;
  }
}

.ps-projects--with-map .ps-projects__left {
  padding: 16px;
}

.ps-projects--with-map .ps-projects__pagination {
  padding-top: 40px;
}

.ps-projects--with-map #map {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

@media screen and (min-width: 1024px) {
  .ps-projects--with-map {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .ps-projects--with-map .ps-projects__pagination {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1440px) {
  .ps-projects--with-map .ps-projects__left {
    padding: 40px;
  }
}

@media screen and (max-width: 479px) {
  .ps-projects--with-map {
    display: flex;
    flex-flow: column-reverse wrap;
  }
  .ps-projects--with-map .ps-projects__right {
    margin-bottom: 30px;
  }
}

.ps-project--detail .ps-project__gallery {
  display: block;
  max-width: 100vw;
  margin-bottom: 40px;
}

.ps-project--detail .ps-project__gallery .img {
  max-width: 100%;
}

.ps-project--detail .ps-project__actions {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-project--detail .ps-project__actions a {
  width: 50px;
  height: 50px;
  color: var(--color-heading);
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
}

.ps-project--detail .ps-project__actions a:hover {
  color: #ffffff;
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 1);
}

.ps-project--detail .ps-project__price strong {
  font-size: 30px;
}

.ps-project--detail .ps-project__price span {
  font-size: 24px;
}

.ps-project--detail .ps-project__name {
  font-size: 24px;
}

.ps-project--detail .ps-project__name:hover {
  text-decoration: none;
}

.ps-project--detail .ps-project__meta {
  margin-bottom: 30px;
}

.ps-project--detail .ps-project__services {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--color-border);
}

.ps-project--detail .ps-project__services p {
  padding: 24px;
  width: 150px;
  min-height: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1em;
  color: var(--color-heading);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-large);
}

.ps-project--detail .ps-project__services p i {
  display: block;
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 1em;
}

.ps-project--detail .ps-project__heading {
  margin-bottom: 36px;
  font-size: 18px;
  line-height: 1em;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-project--detail .ps-project__desc > * {
  margin-bottom: 40px;
}

.ps-project--detail .ps-project__header {
  display: grid;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 40px;
}

.ps-project--detail .ps-project__header .ps-project__address {
  margin-bottom: 40px;
}

.ps-project--detail .ps-project__nearby .ps-block--project-nearby {
  margin-bottom: 50px;
}

.ps-project--detail .ps-project__review {
  padding-bottom: 20px;
}

.ps-project--detail .ps-project__amenities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 20px;
}

.ps-project--detail .ps-project__amenities a {
  color: var(--color-heading);
  line-height: 20px;
}

.ps-project--detail .ps-project__amenities a:hover {
  color: var(--color-1st);
}

.ps-project--detail .ps-project__content > * {
  margin-bottom: 32px;
}

.ps-project--detail .ps-project__map {
  overflow: hidden;
  border-radius: var(--border-radius-medium);
}

@media screen and (min-width: 768px) {
  .ps-project--detail .ps-project__heading {
    font-size: 24px;
  }
  .ps-project--detail .ps-project__name {
    font-size: 36px;
  }
  .ps-project--detail .ps-project__amenities {
    grid-template-columns: repeat(4, 1fr);
  }
  .ps-project--detail .ps-project__meta, .ps-project--detail .ps-project__services {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .ps-project--detail .ps-project__gallery {
    margin-bottom: 80px;
  }
  .ps-project--detail .ps-project__meta, .ps-project--detail .ps-project__services {
    grid-template-columns: repeat(5, 1fr);
  }
  .ps-project--detail .ps-project__meta {
    margin-bottom: 65px;
  }
  .ps-project--detail .ps-project__heading {
    font-size: 30px;
  }
  .ps-project--detail .ps-project__services {
    padding-bottom: 65px;
    margin-bottom: 65px;
  }
  .ps-project--detail .ps-project__name {
    font-size: 48px;
  }
  .ps-project--detail .ps-project__header {
    grid-template-columns: 4fr 1fr;
    padding-bottom: 45px;
    margin-bottom: 65px;
  }
  .ps-project--detail .ps-project__content > * {
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 1200px) {
  .ps-project--detail .ps-project__gallery {
    max-width: calc(1170px - 385px);
  }
}

@media screen and (min-width: 1366px) {
  .ps-project--detail .ps-project__gallery {
    max-width: calc(1250px - 415px);
  }
  .ps-project--detail .ps-project__services {
    grid-column-gap: 20px;
  }
  .ps-project--detail .ps-project__services p {
    min-height: 130px;
  }
  .ps-project--detail .ps-project__nearby .row {
    margin: 0 -50px;
  }
  .ps-project--detail .ps-project__nearby .row > * {
    padding: 0 50px;
  }
  .ps-project--detail .ps-project__nearby .ps-block--project-nearby {
    margin-bottom: 50px;
  }
  .ps-project--detail .ps-project__meta, .ps-project--detail .ps-project__services {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1600px) {
  .ps-project--detail .ps-project__gallery {
    max-width: calc(1510px - 455px);
  }
  .ps-project--detail .ps-project__gallery .ps-project__services p {
    width: 200px;
  }
}

@media screen and (max-width: 479px) {
  .ps-project--detail .ps-project__services {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 100px;
  }
}

#single-map {
  height: 500px;
}

.ps-form--widget-subscribe p {
  margin-bottom: 0;
  color: #fff;
}

.ps-form--widget-subscribe .ps-form__top {
  padding-bottom: 30px;
}

.ps-form--widget-subscribe .ps-form__content {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  padding: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
}

.ps-form--widget-subscribe .ps-form__content .ps-form__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--color-heading);
}

.ps-form--widget-subscribe .ps-form__content input {
  padding: 0;
  height: 38px;
  border: none;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-heading);
}

.ps-form--widget-subscribe .ps-form__content input::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-subscribe .ps-form__content input::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-subscribe .ps-form__content input:-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-subscribe .ps-form__content input:-ms-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-subscribe .ps-form__content button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: var(--border-radius-medium);
  border: none;
  background-color: transparent;
}

.ps-form--widget-subscribe .ps-form__content button:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
}

.ps-form--widget-subscribe .ps-form__bottom a {
  text-decoration: underline;
}

.ps-form--widget-subscribe .ps-form__bottom a:hover {
  color: var(--color-1st);
}

.ps-form--blog-search {
  position: relative;
}

.ps-form--blog-search button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  background-color: transparent;
  border: none;
}

.ps-form--blog-search input {
  border: none;
  color: var(--color-text);
  font-size: 16px;
  border-radius: 0;
  border-bottom: 1px solid var(--color-border);
  padding-left: 0;
  font-weight: 400;
}

.ps-form--blog-search input::-webkit-input-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--blog-search input::-moz-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--blog-search input:-moz-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--blog-search input:-ms-input-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--widget-newsletter {
  padding: 40px;
  border-radius: var(--border-radius-medium);
  background-color: #f3f9ff;
}

.ps-form--widget-newsletter p {
  margin-bottom: 0;
  color: var(--color-text);
  font-size: 16px;
}

.ps-form--widget-newsletter .ps-form__header {
  padding-bottom: 40px;
}

.ps-form--widget-newsletter .ps-form__content {
  display: grid;
  grid-template-columns: 40px 1fr;
  padding: 10px;
  margin-bottom: 12px;
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
}

.ps-form--widget-newsletter .ps-form__content .ps-form__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--color-heading);
}

.ps-form--widget-newsletter .ps-form__content input {
  padding: 0;
  height: 32px;
  border: none;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-heading);
}

.ps-form--widget-newsletter .ps-form__content input::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-newsletter .ps-form__content input::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-newsletter .ps-form__content input:-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-newsletter .ps-form__content input:-ms-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text);
}

.ps-form--widget-newsletter .ps-form__content button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 52px;
  border-radius: var(--border-radius-medium);
  border: none;
  background-color: transparent;
}

.ps-form--widget-newsletter .ps-form__content button:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
}

.ps-form--widget-newsletter .ps-form__bottom a {
  text-decoration: underline;
}

.ps-form--widget-newsletter .ps-form__bottom a:hover {
  color: var(--color-1st);
}

.ps-form--post-comment {
  padding-bottom: 32px;
}

.ps-form--post-comment .ps-form__top {
  padding-bottom: 32px;
}

.ps-form--post-comment .ps-form__top h3 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 24px;
}

.ps-form--post-comment .ps-form__bottom {
  padding-top: 30px;
}

@media screen and (min-width: 1024px) {
  .ps-form--post-comment {
    padding-bottom: 64px;
  }
  .ps-form--post-comment .ps-form__top {
    padding-bottom: 64px;
  }
  .ps-form--post-comment .ps-form__top h3 {
    font-size: 30px;
  }
}

.ps-form--contact-us .ps-form__heading {
  margin-bottom: 0;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-text-2);
  font-weight: 400;
}

.ps-form--contact-us .form-group {
  margin-bottom: 20px;
}

.ps-form--contact-us .ps-form__top {
  padding-bottom: 30px;
}

.ps-form--contact-us .ps-form__bottom {
  padding-top: 50px;
}

@media screen and (min-width: 1024px) {
  .ps-form--contact-us .ps-form__top {
    padding-bottom: 60px;
  }
}

.ps-form--agent-search {
  padding: 40px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
}

.ps-form--agent-search .ps-form__submit {
  border-bottom: none;
}

.ps-form--agent-search .ps-form__submit .ps-btn {
  width: 100%;
}

.ps-form--agent-review .ps-form__top {
  padding-bottom: 35px;
}

.ps-form--agent-review .ps-form__top h4 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

.ps-form--agent-review .ps-form__top p {
  margin-bottom: 0;
}

.ps-form--agent-review .ps-form__top p sup {
  color: var(--color-danger);
}

.ps-form--agent-review .ps-form__bottom {
  padding-top: 50px;
}

.ps-form--agent-contact .ps-checkbox label a {
  color: var(--color-1st);
}

.ps-form--agent-contact .ps-checkbox label a:hover {
  text-decoration: underline;
}

.ps-form--agent-contact .ps-form__bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}

.ps-form--project-schedule-tour {
  border: 1px solid var(--color-border);
  padding: 16px;
  border-radius: var(--border-radius-medium);
}

.ps-form--project-schedule-tour .ps-form__bottom {
  padding-top: 50px;
}

.ps-form--project-schedule-tour .form-control {
  color: var(--color-text-2);
}

.ps-form--project-schedule-tour .form-control::-webkit-input-placeholder {
  visibility: visible;
  opacity: 1;
}

.ps-form--project-schedule-tour .form-control::-moz-placeholder {
  visibility: visible;
  opacity: 1;
}

.ps-form--project-schedule-tour .form-control:-moz-placeholder {
  visibility: visible;
  opacity: 1;
}

.ps-form--project-schedule-tour .form-control:-ms-input-placeholder {
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 1024px) {
  .ps-form--project-schedule-tour {
    padding: 40px;
  }
}

.ps-form--mortgage_caculator .ps-form label {
  color: var(--color-text);
}

.ps-form--mortgage_caculator .ps-form .ps-form__icon {
  left: auto;
  right: 0;
  font-style: normal;
  color: var(--color-text-2);
}

.ps-form--mortgage_caculator .ps-form select {
  color: var(--color-text);
  font-weight: 400;
}

.ps-form--mortgage_caculator .ps-form .form-control {
  text-indent: 0;
}

.ps-form--projects-search .form-group--with-slider > label {
  display: flex;
  justify-content: flex-start;
  grid-column-gap: 10px;
  margin-bottom: 25px;
}

.ps-form--projects-search .form-group--with-slider > label i {
  display: block;
}

.ps-form--projects-search .ps-form__top-left {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.ps-form--projects-search .ps-form__top-left .ps-btn {
  border-radius: 0;
  height: 100%;
}

.ps-form--projects-search .ps-form__top-right {
  grid-column-gap: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ps-form--projects-search .ps-form__items {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 40px;
}

.ps-form--projects-search .ps-form__top {
  display: grid;
  /* grid-template-columns: 200px 1fr 340px;
         grid-column-gap: 40px;*/
}

.ps-form--projects-search .ps-form__top .ps-btn {
  height: 52px;
}

.ps-form--projects-search .ps-form__top .ps-form__top-left .ps-btn {
  height: 50px;
}

.ps-form--projects-search .ps-form__top .ps-form__actions {
  grid-column-gap: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ps-form--projects-search .ps-form__top .ps-form__actions .ps-btn--gray {
  padding: 0 10px;
  color: var(--color-heading);
  background-color: transparent;
}

.ps-form--projects-search .ps-form__top .ps-form__actions .ps-btn--gray i {
  margin-left: 0.5em;
}

.ps-form--projects-search .ps-form__top .ps-form__actions .ps-btn--gray:hover {
  background-color: var(--color-bg-gray);
}

.ps-form--projects-search .ps-form__feature-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.ps-form--projects-search .ps-form__features > p {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-heading);
  margin-bottom: 25px;
}

.ps-form--projects-search .ps-form__block {
  margin-bottom: 15px;
}

.ps-form--projects-search .ps-form__submit {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 20px;
  padding-top: 25px;
}

.ps-form--projects-search .ps-form__submit .ps-btn {
  max-width: 200px;
}

.ps-form--projects-search .ps-form__bottom {
  display: none;
  padding-top: 30px;
}

@media screen and (min-width: 768px) {
  .ps-form--projects-search .ps-form__feature-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .ps-form--projects-search .ps-form__features > p {
    margin-bottom: 50px;
  }
  .ps-form--projects-search .ps-form__submit {
    padding-top: 50px;
    grid-column-gap: 40px;
  }
  .ps-form--projects-search .ps-form__submit .ps-btn {
    min-width: 230px;
    height: 50px;
  }
  .ps-form--projects-search .ps-form__block {
    margin-bottom: 35px;
  }
  .ps-form--projects-search .ps-form__bottom {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1280px) {
  .ps-form--projects-search .ps-form__top-right {
    grid-template-columns: 1fr 340px;
    grid-column-gap: 40px;
  }
  .ps-form--projects-search .ps-form__top {
    grid-template-columns: 200px 1fr;
    grid-column-gap: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .ps-form--projects-search .ps-form__top-right {
    grid-template-columns: 1fr 340px;
    grid-column-gap: 40px;
  }
  .ps-form--projects-search .ps-form__top {
    grid-template-columns: 200px 1fr;
    grid-column-gap: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .ps-form--projects-search .ps-form__top-left {
    max-width: 200px;
  }
  .ps-form--projects-search .ps-form__items {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .ps-form--projects-search .ps-form__items .ps-form:first-child {
    grid-column: 1 / -1;
  }
  .ps-form--projects-search .ps-form__top-right {
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 30px;
  }
  .ps-form--projects-search .ps-form__top-right .ps-form__actions {
    display: flex;
    flex-flow: column-reverse wrap;
    justify-content: flex-end;
    align-items: flex-end;
    grid-column-gap: 0px;
  }
  .ps-form--projects-search .ps-form__top-right .ps-form__actions > * {
    width: 100%;
  }
  .ps-form--projects-search .ps-form__top-right .ps-form__actions .ps-btn--gray {
    margin-top: 10px;
    background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
  }
}

@media screen and (max-width: 480px) {
  .ps-form--projects-search .ps-form__top-right {
    grid-template-columns: 1fr;
  }
}

.ps-form--projects-search-2 .ps-form .ps-form__icon {
  font-size: 20px;
  color: #999999;
}

.ps-form--projects-search-2 .ps-form .second {
  left: auto;
  right: 0;
}

.ps-form--projects-search-2 .ps-form__top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 4px;
}

.ps-form--projects-search-2 .ps-form__top .ps-btn {
  width: 110px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px 6px 0 0;
}

.ps-form--projects-search-2 .ps-form__top .ps-btn.active {
  background-color: #fff;
  color: var(--color-1st);
}

.ps-form--projects-search-2 .ps-form__bottom {
  padding: 30px;
  background-color: #fff;
  border-radius: 0 6px 6px 6px;
}

.ps-form--projects-search-2 .ps-form__bottom .ps-btn {
  height: 48px;
  margin-bottom: 10px;
}

.ps-form--projects-search-2 .ps-form__bottom .ps-form__toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 46px;
  max-width: 180px;
  color: var(--color-heading);
}

.ps-form--projects-search-2 .ps-form__bottom .ps-form__toggle i {
  margin-left: 20px;
}

@media screen and (min-width: 1440px) {
  .ps-form--projects-search-2 .ps-form__bottom .ps-btn {
    font-size: 18px;
    height: 60px;
  }
}

.ps-form--projects-search-3 .ps-form {
  padding-left: 0;
}

.ps-form--projects-search-3 .ps-form input {
  text-indent: 0 !important;
  padding-left: 0 !important;
}

.ps-form--projects-search-3 .ps-form .ps-form__icon {
  font-size: 20px;
  color: #999999;
}

.ps-form--projects-search-3 .ps-form .second {
  left: auto;
  right: 0;
}

.ps-form--projects-search-3 .ps-form__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ps-form--projects-search-3 .ps-form__actions > * {
  align-self: center;
}

.ps-form--projects-search-3 .ps-form__fields .form-group {
  margin-bottom: 0;
}

.ps-form--projects-search-3 .ps-form__top {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 4px;
  position: relative;
  top: 1px;
}

.ps-form--projects-search-3 .ps-form__top .ps-btn {
  width: 150px;
  color: var(--color-text);
  background-color: #efefef;
  border-radius: 6px 6px 0 0;
  border: 1px solid var(--color-border);
  border-bottom: none;
}

.ps-form--projects-search-3 .ps-form__top .ps-btn.active {
  background-color: #fff;
  color: var(--color-1st);
}

.ps-form--projects-search-3 .ps-form__bottom {
  display: grid;
  grid-template-columns: 3fr 2fr;
  padding: 30px;
  background-color: #fff;
  grid-gap: 30px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
}

.ps-form--projects-search-3 .ps-form__bottom .ps-btn {
  height: 48px;
  margin-bottom: 10px;
}

.ps-form--projects-search-3 .ps-form__bottom .ps-form__toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 46px;
  max-width: 180px;
  color: var(--color-heading);
}

.ps-form--projects-search-3 .ps-form__bottom .ps-form__toggle i {
  margin-left: 20px;
}

@media screen and (min-width: 1440px) {
  .ps-form--projects-search-3 .ps-form__bottom .ps-btn {
    font-size: 18px;
    height: 60px;
  }
}

@media screen and (max-width: 479px) {
  .ps-form--projects-search-3 .ps-form__bottom {
    grid-template-columns: 1fr;
  }
  .ps-form--projects-search-3 .ps-form__actions {
    display: flex;
    flex-flow: column-reverse nowrap;
    grid-template-columns: 1fr;
  }
}

.ps-form--projects-search-extra .ps-form__top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 4px;
}

.ps-form--projects-search-extra .ps-form__top .ps-btn {
  width: 110px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px 6px 0 0;
}

.ps-form--projects-search-extra .ps-form__top .ps-btn.active {
  background-color: #fff;
  color: var(--color-1st);
}

.ps-form--projects-search-extra .ps-form__extra {
  padding: 30px;
  border-radius: 0 6px 6px 6px;
  background-color: #fff;
}

@media screen and (max-width: 480px) {
  .ps-form--projects-search-extra .ps-form__actions {
    padding-top: 30px;
  }
  .ps-form--projects-search-extra .ps-form__actions .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
}

.ps-form--auth {
  margin: 0 auto;
  max-width: 500px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
  padding: 30px 16px;
}

.ps-form--auth > * {
  margin-bottom: 16px;
}

.ps-form--auth .ps-form__top {
  margin: 0;
  padding-bottom: 40px;
}

.ps-form--auth .ps-form__top h3 {
  font-size: 28px;
  line-height: 1em;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.ps-form--auth .ps-form__top p {
  margin-bottom: 0;
}

.ps-form--auth .ps-form__methods {
  display: grid;
  grid-row-gap: 16px;
}

.ps-form--auth .ps-form__methods a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-weight: 500;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.075);
}

.ps-form--auth .ps-form__methods a i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 16px;
}

.ps-form--auth .ps-form__methods a span {
  font-weight: inherit;
}

.ps-form--auth .ps-form__links {
  text-align: center;
}

.ps-form--auth .ps-form__links a {
  font-weight: 500;
  color: var(--color-1st);
  text-transform: capitalize;
}

.ps-form--auth .ps-form__links a:hover {
  text-decoration: underline;
}

.ps-form--auth .ps-form__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ps-form--auth .ps-form__actions .ps-checkbox label {
  text-transform: capitalize;
}

.ps-form--auth .ps-form__actions a {
  justify-self: end;
  font-weight: 500;
  color: var(--color-1st);
}

.ps-form--auth .ps-form__actions a:hover {
  text-decoration: underline;
}

.ps-form--auth .ps-form__submit .ps-btn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ps-form--auth {
    padding: 60px 30px;
  }
  .ps-form--auth .ps-form__top h3 {
    font-size: 36px;
  }
  .ps-form--auth .ps-form__submit .ps-btn {
    height: 64px;
    font-size: 18px;
  }
}

.ps-form--user-information .ps-form__genders {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 16px;
}

.ps-form--user-information .ps-form__avatar {
  max-width: 200px;
  margin: 0 auto;
}

.ps-form--user-information .ps-form__avatar .ps-form__image {
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: var(--border-radius-medium);
}

.ps-form--user-information .ps-form__avatar .ps-btn {
  width: 100%;
  height: 60px;
}

.ps-form--user-information .ps-form__submit {
  display: grid;
  grid-gap: 8px;
}

@media screen and (min-width: 768px) {
  .ps-form--user-information .ps-form__submit {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .ps-form--user-information .ps-form__submit .ps-btn {
    min-width: 200px;
  }
}

@media screen and (max-width: 480px) {
  .ps-form--user-information .ps-form__avatar {
    margin-bottom: 30px;
  }
  .ps-form--user-information .ps-form__fields .row {
    display: flex;
    flex-flow: column-reverse wrap;
  }
}

.ps-form--dashboard-search {
  position: relative;
}

.ps-form--dashboard-search button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  background-color: transparent;
  border: none;
}

.ps-form--dashboard-search input {
  border: none;
  color: var(--color-text);
  font-size: 16px;
  border-radius: 0;
  border-bottom: 1px solid var(--color-border);
  padding-left: 0;
  font-weight: 400;
}

.ps-form--dashboard-search input::-webkit-input-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--dashboard-search input::-moz-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--dashboard-search input:-moz-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--dashboard-search input:-ms-input-placeholder {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
}

.ps-form--dashboard .ps-form__btn {
  max-width: 200px;
}

.ps-form--dashboard .ps-form__btn i {
  margin-right: 16px;
}

.ps-form--dashboard .ps-form__block figcaption {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-form--dashboard .ps-form__submit {
  padding-top: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.ps-form--dashboard .ps-form__submit .ps-btn {
  min-width: 200px;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .ps-form--dashboard .ps-form__submit {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 480px) {
  .ps-form--dashboard .ps-form__submit {
    flex-flow: column nowrap;
  }
  .ps-form--dashboard .ps-form__submit .ps-btn {
    width: 100%;
  }
}

.ps-form--join-us .dropzone {
  min-height: 50px;
  border-radius: var(--border-radius-medium);
  border-width: 1px;
  border-color: var(--color-border);
}

.ps-form--join-us .dropzone .dz-message {
  margin: 0;
}

.ps-form--join-us .ps-form__top {
  padding-bottom: 32px;
}

.ps-form--join-us .ps-form__top h3 {
  font-size: 24px;
}

.ps-form--join-us .ps-form__upload {
  height: auto;
}

.ps-form--join-us .ps-form__upload .custom-file-label {
  height: 48px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
}

.ps-form--join-us .ps-form__upload .custom-file-label:after {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding: 0 16px;
}

.ps-form--join-us .ps-form__bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding-top: 32px;
}

.ps-form--join-us .ps-form__bottom .ps-btn {
  min-width: 140px;
}

@media screen and (min-width: 1024px) {
  .ps-form--join-us .ps-form__top h3 {
    font-size: 36px;
  }
}

.ps-section__heading {
  margin-bottom: 0;
  line-height: 1em;
  font-weight: 500;
  color: var(--color-heading);
}

.ps-section__heading .ps-section__morelink {
  font-size: 16px;
  padding-left: 50px;
  color: var(--color-text);
  text-transform: capitalize;
}

.ps-section__heading .ps-section__morelink i {
  margin-left: 25px;
}

.ps-section__heading .ps-section__morelink:hover, .ps-section__heading .ps-section__morelink.active {
  color: var(--color-1st);
}

.ps-section__header {
  padding-bottom: var(--section-header-padding-bottom);
}

.ps-section__carousel-navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-section__carousel-navigation .ps-btn--carouse-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  font-size: 16px;
  color: var(--color-1st);
  border-radius: var(--border-radius-medium);
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
}

.ps-section__carousel-navigation .ps-btn--carouse-arrow:hover {
  color: #ffffff;
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 1);
}

.ps-section__carousel-navigation .prev {
  margin-right: 8px;
}

.ps-section.with-carousel .ps-section__header {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.ps-section.with-carousel .owl-carousel .owl-nav {
  display: none;
}

.ps-section.with-bg {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-top);
}

@media screen and (min-width: 1024px) {
  .ps-section__heading {
    font-size: 40px;
  }
  .ps-section__heading .ps-section__morelink {
    font-size: 18px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-section__heading {
    font-size: 48px;
  }
}

@media screen and (max-width: 479px) {
  .ps-section__heading .ps-section__morelink {
    display: block;
    padding-left: 0;
    line-height: 1em;
    padding-top: 0.75em;
  }
  .ps-section__heading .ps-section__morelink i {
    margin-left: 10px;
  }
}

.ps-top-destinations {
  background-color: #fff;
}

.ps-top-destinations .ps-section__columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.ps-top-destinations .ps-section__content {
  min-height: 300px;
}

.ps-top-destinations.with-bg--color {
  background-color: #f8fafc;
}

@media screen and (min-width: 768px) {
  .ps-top-destinations .ps-section__columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1040px) {
  .ps-top-destinations .ps-section__columns {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-top-destinations .ps-section__columns {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1440px) {
  .ps-top-destinations .ps-section__columns {
    grid-gap: 30px;
  }
}

.ps-property-types .ps-carousel .owl-stage-outer {
  padding-bottom: 60px;
}

.ps-property-types .owl-nav, .ps-property-types .owl-dots {
  display: none;
}

.ps-neighborhoods .row {
  grid-row-gap: 50px;
}

@media screen and (max-width: 479px) {
  .ps-neighborhoods .row {
    grid-row-gap: 30px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .ps-neighborhoods .row .col-lg-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.ps-testimonials .ps-section__wrapper {
  display: grid;
  border-bottom: 1px solid var(--color-border);
  padding: 60px 0;
}

@media screen and (min-width: 1024px) {
  .ps-testimonials .ps-section__left {
    padding-right: 45px;
    border-right: 1px solid var(--color-border);
  }
  .ps-testimonials .ps-section__right {
    padding-left: 45px;
  }
  .ps-testimonials .ps-section__wrapper {
    grid-template-columns: 4fr 8fr;
    padding: 90px 0;
  }
}

@media screen and (min-width: 1366px) {
  .ps-testimonials .ps-section__left {
    padding-right: 90px;
  }
  .ps-testimonials .ps-section__right {
    padding-left: 90px;
  }
  .ps-testimonials .ps-section__wrapper {
    padding: 130px 0;
  }
}

@media screen and (min-width: 1600px) {
  .ps-testimonials .ps-section__left {
    padding-right: 130px;
    border-right: 1px solid var(--color-border);
  }
  .ps-testimonials .ps-section__right {
    padding-left: 130px;
  }
}

@media screen and (max-width: 768px) {
  .ps-testimonials .ps-section__left {
    padding: 0 16px 60px;
  }
}

.ps-site-stats .ps-section__mission h3 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2em;
}

.ps-site-stats .ps-section__desc p {
  max-width: 420px;
  margin-bottom: 35px;
}

.ps-site-stats .ps-section__desc p:last-child {
  margin-bottom: 0;
}

.ps-site-stats .ps-section__brands p {
  font-size: 13px;
}

.ps-site-stats .ps-section__brands a {
  display: block;
  margin-bottom: 30px;
}

.ps-site-stats .ps-section__brands a img {
  width: auto;
}

.ps-site-stats .ps-section__brands a:last-child {
  margin-bottom: 0;
}

.ps-site-stats .ps-section__intro {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--color-border);
}

.ps-site-stats .ps-section__content {
  display: grid;
}

.ps-site-stats .ps-section__wrapper {
  display: grid;
}

@media screen and (min-width: 768px) {
  .ps-site-stats .ps-section__mission h3 {
    font-size: 48px;
  }
  .ps-site-stats .ps-section__desc p {
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-site-stats .ps-section__mission h3 {
    font-size: 36px;
  }
  .ps-site-stats .ps-section__desc p {
    font-size: 20px;
  }
  .ps-site-stats .ps-section__content {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .ps-site-stats .ps-section__intro {
    grid-template-columns: 1fr 255px;
    grid-gap: 30px;
    padding-bottom: 100px;
    margin-bottom: 100px;
  }
  .ps-site-stats .ps-section__wrapper {
    grid-column-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1366px) {
  .ps-site-stats .ps-section__wrapper {
    grid-template-columns: 3.5fr 3fr 2.5fr;
  }
}

@media screen and (min-width: 1440px) {
  .ps-site-stats .ps-section__mission h3 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1600px) {
  .ps-site-stats .ps-section__mission h3 {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  .ps-site-stats .ps-section__wrapper {
    grid-row-gap: 16px;
  }
  .ps-site-stats .ps-section__mission {
    margin-bottom: 30px;
  }
  .ps-site-stats .ps-section__content {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .ps-site-stats .ps-section__mission h3 {
    font-size: 30px;
  }
  .ps-site-stats .ps-section__mission h3 br {
    display: none;
  }
  .ps-site-stats .ps-section__brands {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }
  .ps-site-stats .ps-section__brands > a {
    align-self: center;
    margin-bottom: 0;
    padding: 10px 0;
  }
  .ps-site-stats .ps-section__brands p {
    grid-column: 1/4;
  }
  .ps-site-stats .ps-section__content {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    padding-bottom: 30px;
  }
  .ps-site-stats .ps-section__wrapper {
    grid-row-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.ps-leaderships .ps-block--team-member {
  margin-bottom: 20px;
}

.ps-leaderships .ps-section__columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-row-gap: 0;
}

@media screen and (min-width: 768px) {
  .ps-leaderships .ps-section__columns {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-leaderships .ps-block--team-member {
    margin-bottom: 80px;
  }
  .ps-leaderships .ps-section__columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1366px) {
  .ps-leaderships .ps-section__columns {
    grid-template-columns: repeat(5, 1fr);
  }
}

.ps-our-services .ps-block--service {
  margin-bottom: 30px;
}

.ps-our-services .ps-section__columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media screen and (min-width: 768px) {
  .ps-our-services .ps-section__columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .ps-our-services .ps-section__columns {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-our-services .ps-section__columns {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 90px;
  }
}

.ps-blog .ps-blog__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.ps-blog .ps-blog__links a {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  font-size: 13px;
  text-transform: uppercase;
  color: var(--color-heading);
  font-weight: 500;
}

.ps-blog .ps-blog__links a.active, .ps-blog .ps-blog__links a:hover {
  color: var(--color-1st);
}

.ps-blog .ps-blog__bottom {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .ps-blog .ps-blog__bottom {
    padding-top: 50px;
  }
}

.ps-blog--grid .ps-blog__links {
  flex-flow: row wrap;
}

.ps-blog--grid .ps-blog__header {
  display: grid;
  padding-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  .ps-blog--grid .ps-blog__header {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding-bottom: 60px;
  }
  .ps-blog--grid .ps-blog__bottom {
    padding-top: 50px;
  }
}

.ps-blog--list .ps-blog__links {
  padding-bottom: 30px;
}

.ps-blog--list .ps-blog__bottom {
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
}

.ps-blog--list .ps-blog__items {
  margin-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  .ps-blog--list .ps-blog__links {
    padding-bottom: 60px;
  }
  .ps-blog--list .ps-blog__items .ps-post--horizontal {
    margin-bottom: 60px;
  }
  .ps-blog--list .ps-blog__bottom {
    padding-top: 10px;
  }
}

.ps-blog--feature-posts {
  display: grid;
}

.ps-blog--feature-posts .ps-post--only-text {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border);
}

.ps-blog--feature-posts .ps-post--only-text:last-child {
  margin: 0;
  padding: 0;
  border-bottom: none;
}

.ps-blog--feature-posts .ps-blog__left {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--color-border);
}

@media screen and (min-width: 1024px) {
  .ps-blog--feature-posts {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
  .ps-blog--feature-posts .ps-post--only-text {
    margin-bottom: 46px;
    padding-bottom: 46px;
  }
  .ps-blog--feature-posts .ps-blog__left {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-right: 40px;
    border-bottom: none;
    border-right: 1px solid var(--color-border);
  }
}

@media screen and (min-width: 1366px) {
  .ps-blog--feature-posts {
    grid-gap: 85px;
  }
  .ps-blog--feature-posts .ps-blog__left {
    padding-right: 85px;
  }
}

.ps-related-posts .ps-section__heading {
  font-size: 24px;
}

@media screen and (min-width: 1024px) {
  .ps-related-posts .ps-section__heading {
    font-size: 30px;
  }
}

.ps-contact-map .ps-section__map {
  padding-bottom: 50px;
}

.ps-contact-map .ps-section__information {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 50px;
}

.ps-contact-map .ps-section__information h3 {
  margin-bottom: 36px;
  font-size: 30px;
}

.ps-contact-map .ps-section__information h5 {
  margin-bottom: 25px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--color-text-2);
}

.ps-contact-map .ps-section__information p {
  font-size: 16px;
}

.ps-contact-map .ps-section__information p strong {
  font-size: inherit;
  color: #000000;
  font-weight: 500;
}

.ps-contact-map .ps-section__information p a {
  font-size: inherit;
  color: inherit;
}

.ps-contact-map .ps-section__information p a:hover {
  color: var(--color-1st);
  text-decoration: underline;
}

.ps-contact-map .ps-section__information .ps-section__link {
  font-size: 16px;
  margin-bottom: 0;
  color: var(--color-text);
  text-decoration: underline;
}

.ps-contact-map .ps-section__information .ps-section__link:hover {
  color: var(--color-1st);
}

@media screen and (min-width: 768px) {
  .ps-contact-map .ps-section__information h3 {
    font-size: 36px;
  }
  .ps-contact-map .ps-section__information p {
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-contact-map .ps-section__information {
    padding-bottom: 100px;
  }
  .ps-contact-map .ps-section__information h3 {
    margin-bottom: 75px;
    font-size: 48px;
  }
  .ps-contact-map .ps-section__information h5 {
    margin-bottom: 45px;
  }
  .ps-contact-map .ps-section__information p {
    font-size: 24px;
  }
  .ps-contact-map .ps-section__information .ps-section__link {
    font-size: 24px;
  }
  .ps-contact-map .ps-section__map {
    padding-bottom: 100px;
  }
}

.ps-our-team {
  padding-top: 0;
}

.ps-join-us {
  min-height: 380px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding-top: 80px;
}

.ps-join-us h3 {
  margin-bottom: 25px;
  font-size: 24px;
}

.ps-join-us p {
  margin-bottom: 40px;
  font-size: 16px;
}

.ps-join-us .ps-btn {
  margin: 0 auto;
  max-width: 230px;
}

@media screen and (min-width: 768px) {
  .ps-join-us {
    min-height: 640px;
  }
  .ps-join-us h3 {
    margin-bottom: 50px;
    font-size: 48px;
  }
  .ps-join-us p {
    margin-bottom: 80px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-join-us {
    padding-top: 170px;
    min-height: 780px;
  }
}

.ps-faqs .ps-block--faqs {
  margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
  .ps-faqs .ps-block--faqs {
    margin-bottom: 100px;
  }
}

.ps-career-intro .ps-section__wrapper {
  display: grid;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--color-border);
}

.ps-career-intro .ps-section__left h3 {
  margin-bottom: 40px;
  font-size: 24px;
}

.ps-career-intro .ps-section__left .ps-btn {
  max-width: 230px;
}

.ps-career-intro .ps-section__right p {
  margin-bottom: 30px;
}

.ps-career-intro .ps-section__right p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .ps-career-intro .ps-section__left h3 {
    margin-bottom: 80px;
    font-size: 36px;
  }
  .ps-career-intro .ps-section__right p {
    margin-bottom: 40px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-career-intro .ps-section__wrapper {
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-career-intro .ps-section__left h3 {
    font-size: 48px;
  }
  .ps-career-intro .ps-section__wrapper {
    padding-bottom: 100px;
  }
}

.ps-career-images .ps-section__image {
  border-radius: var(--border-radius-medium);
  margin-bottom: 30px;
}

.ps-career-jobs .ps-block--job {
  margin-bottom: 40px;
}

.ps-career-jobs .ps-section__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
}

.ps-career-jobs .ps-section__locations a {
  font-size: 24px;
}

.ps-career-jobs .ps-section__locations li {
  margin-right: 30px;
}

.ps-career-jobs .ps-section__locations li.active {
  color: var(--color-1st);
}

.ps-career-jobs .ps-section__jobs a {
  font-size: 14px;
}

.ps-career-jobs .ps-section__jobs li {
  margin-right: 30px;
}

.ps-career-jobs .ps-section__jobs li:last-child {
  margin-right: 0;
}

.ps-career-jobs .ps-section__header .ps-section__links {
  padding-bottom: 20px;
}

.ps-career-jobs .ps-section__bottom {
  padding-top: 40px;
}

.ps-career-jobs .ps-section__bottom h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

.ps-career-jobs .ps-section__bottom p {
  max-width: 600px;
}

.ps-career-jobs .ps-section__bottom p a {
  font-size: inherit;
}

@media screen and (min-width: 768px) {
  .ps-career-jobs .ps-block--job {
    margin-bottom: 80px;
  }
  .ps-career-jobs .ps-section__locations a {
    font-size: 36px;
  }
  .ps-career-jobs .ps-section__jobs a {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-career-jobs .ps-section__header figcaption {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-text-2);
  }
  .ps-career-jobs .ps-section__header figure {
    display: grid;
    grid-template-columns: 285px 1fr;
    margin-bottom: 40px;
  }
  .ps-career-jobs .ps-section__header .ps-section__links {
    padding-bottom: 40px;
    border-bottom: 1px solid var(--color-border);
  }
  .ps-career-jobs .ps-section__content {
    padding-left: 285px;
  }
  .ps-career-jobs .ps-section__bottom {
    padding-top: 80px;
  }
  .ps-career-jobs .ps-section__bottom h4 {
    font-size: 24px;
  }
  .ps-career-jobs .ps-section__bottom p {
    font-size: 16px;
  }
}

.ps-agents .ps-block--agent {
  margin-bottom: 50px;
}

.ps-agents .ps-section__top {
  padding-bottom: 25px;
}

.ps-agents .ps-section__top p {
  margin: 0;
}

.ps-agents .ps-section__top p strong {
  font-weight: 500;
  color: var(--color-1st);
}

.ps-agents .ps-section__bottom {
  padding-top: 20px;
}

@media screen and (min-width: 1024px) {
  .ps-agents .ps-section__top {
    padding-bottom: 50px;
  }
  .ps-agents .ps-section__top p {
    font-size: 16px;
  }
  .ps-agents .ps-section__bottom {
    padding-top: 40px;
  }
}

.ps-agent-information .ps-section__image {
  border-radius: var(--border-radius-medium);
}

.ps-agent-information .ps-section__top {
  margin-bottom: 40px;
}

.ps-agent-information .ps-section__desc > * {
  margin-bottom: 20px;
}

.ps-agent-information .ps-section__content h4 {
  font-size: 24px;
}

.ps-agent-information .ps-section__bottom figure {
  margin-bottom: 25px;
}

.ps-agent-information .ps-section__bottom figure figcaption {
  font-weight: 500;
  color: var(--color-heading);
}

.ps-agent-information .ps-section__bottom figure p {
  margin-bottom: 0;
}

.ps-agent-information .ps-section__bottom figure:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .ps-agent-information .ps-section__top {
    display: grid;
    grid-template-columns: 355px 1fr;
    grid-column-gap: 30px;
    margin-bottom: 80px;
  }
  .ps-agent-information .ps-section__desc > * {
    margin-bottom: 40px;
  }
  .ps-agent-information .ps-section__content h4 {
    font-size: 30px;
  }
  .ps-agent-information .ps-section__bottom figure {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-agent-information .ps-section__top {
    grid-column-gap: 44px;
  }
}

@media screen and (max-width: 480px) {
  .ps-agent-information .ps-section__thumbnail {
    padding-bottom: 30px;
  }
}

.ps-agent-reviews .ps-form--agent-review {
  padding-top: 35px;
}

.ps-agent-reviews .ps-section__top {
  padding-bottom: 20px;
}

.ps-agent-reviews .ps-section__top h3 {
  margin-bottom: 25px;
  font-size: 24px;
}

.ps-agent-reviews .ps-section__top p strong {
  color: var(--color-success);
  margin-right: 10px;
  font-weight: 400;
}

.ps-agent-reviews .ps-section__top p span {
  font-size: 13px;
}

.ps-agent-reviews .ps-section__top p .ps-rating {
  margin-right: 10px;
}

.ps-agent-reviews .ps-section__top p .ps-rating i {
  font-size: 13px;
}

.ps-agent-reviews .ps-section__content .ps-block--agent-review {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-border);
}

.ps-agent-reviews .ps-section__content .ps-block--agent-review:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

@media screen and (min-width: 1024px) {
  .ps-agent-reviews .ps-form--agent-review {
    padding-top: 70px;
  }
  .ps-agent-reviews .ps-section__top {
    padding-bottom: 40px;
  }
  .ps-agent-reviews .ps-section__top h3 {
    margin-bottom: 40px;
    font-size: 30px;
  }
  .ps-agent-reviews .ps-section__top p {
    font-size: 20px;
  }
}

.ps-section--single-project .ps-widget--project {
  margin-bottom: 50px;
  padding: 24px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
}

@media screen and (min-width: 1024px) {
  .ps-section--single-project {
    display: grid;
    grid-template-columns: 1fr 355px;
    grid-column-gap: 30px;
  }
  .ps-section--single-project .ps-section__left {
    max-width: calc(1280px - 355px);
  }
}

@media screen and (min-width: 1366px) {
  .ps-section--single-project {
    grid-column-gap: 60px;
    grid-template-columns: 1fr 355px;
  }
}

@media screen and (min-width: 1440px) {
  .ps-section--single-project .ps-section__left {
    max-width: calc(1280px - 355px);
  }
}

@media screen and (min-width: 1600px) {
  .ps-section--single-project {
    grid-column-gap: 100px;
  }
  .ps-section--single-project .ps-section__left {
    max-width: calc(1510px - 355px);
  }
}

.ps-home-search {
  min-height: calc(100vh + 80px);
  display: grid;
  position: relative;
  z-index: 10;
}

.ps-home-search:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.25);
}

.ps-home-search .ps-section__stats {
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  grid-column-gap: 30px;
  color: #ffffff;
  font-size: 14px;
}

.ps-home-search .ps-section__stats i {
  margin-right: 12px;
}

.ps-home-search .ps-section__top {
  padding-bottom: 40px;
}

.ps-home-search .ps-section__top h3 {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 50px;
  color: #ffffff;
  line-height: 1.1em;
  text-transform: capitalize;
}

.ps-home-search .ps-section__top p {
  margin-bottom: 0;
  color: #fff;
}

.ps-home-search .ps-section__form {
  padding-bottom: 20px;
}

.ps-home-search .ps-section__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .ps-home-search .ps-section__content {
    max-width: 700px;
  }
  .ps-home-search .ps-section__top {
    padding-bottom: 40px;
  }
  .ps-home-search .ps-section__top h3 {
    margin-bottom: 10px;
    font-size: 50px;
    max-width: 500px;
  }
  .ps-home-search .ps-section__top p {
    margin-bottom: 0;
  }
  .ps-home-search .ps-section__form {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .ps-home-search .ps-section__top {
    padding-bottom: 40px;
  }
  .ps-home-search .ps-section__top h3 {
    font-size: 48px;
    max-width: 400px;
  }
  .ps-home-search .ps-section__wrapper {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-home-search .ps-section__top {
    padding-bottom: 60px;
  }
  .ps-home-search .ps-section__wrapper {
    align-items: center;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .ps-home-search .ps-section__top {
    padding-bottom: 60px;
  }
  .ps-home-search .ps-section__top h3 {
    font-size: 48px;
    max-width: 500px;
  }
  .ps-home-search .ps-section__top p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1600px) {
  .ps-home-search .ps-section__top {
    padding-bottom: 80px;
  }
  .ps-home-search .ps-section__top h3 {
    font-size: 80px;
    max-width: 100%;
  }
}

.ps-submit-property {
  min-height: 500px;
  display: grid;
  text-align: center;
}

.ps-submit-property .ps-btn {
  max-width: 300px;
  margin: 0 auto;
}

.ps-submit-property h3 {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.2em;
  color: var(--color-heading);
}

.ps-submit-property h3 strong {
  font-weight: inherit;
  color: var(--color-success);
}

.ps-submit-property p {
  line-height: 1.4em;
  margin-bottom: 40px;
}

.ps-submit-property .container {
  height: 100%;
  padding-top: 75px;
}

@media screen and (min-width: 768px) {
  .ps-submit-property .container {
    padding-top: 90px;
  }
  .ps-submit-property p {
    font-size: 18px;
    margin-bottom: 80px;
  }
  .ps-submit-property .ps-btn {
    height: 60px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-submit-property .container {
    padding-top: 120px;
  }
  .ps-submit-property h3 {
    margin-bottom: 20px;
    font-size: 72px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-submit-property .container {
    padding-top: 150px;
  }
  .ps-submit-property .ps-btn {
    height: 74px;
    font-size: 22px;
  }
}

@media screen and (min-width: 1680px) {
  .ps-submit-property {
    min-height: 770px;
  }
}

@media screen and (max-width: 480px) {
  .ps-submit-property h3 {
    margin: 0 auto 10px;
    max-width: 280px;
  }
  .ps-submit-property p {
    font-size: 14px;
  }
  .ps-submit-property .ps-btn {
    max-width: 240px;
  }
}

.ps-trending-seacrch .ps-section__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}

.ps-trending-seacrch .ps-section__link {
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: var(--color-text);
  border-radius: var(--border-radius-medium);
  background-color: #f0f5fb;
  height: 36px;
}

.ps-trending-seacrch .ps-section__link:hover {
  color: #ffffff;
  background-color: var(--color-1st);
}

.ps-trending-seacrch .ps-section__header p {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .ps-trending-seacrch .ps-section__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .ps-trending-seacrch .ps-section__header .ps-section__heading {
    margin-right: 40px;
  }
}

.ps-section--home-posts .ps-section__heading {
  font-weight: 400;
  font-size: 24px;
}

.ps-section--home-posts .ps-section__morelink {
  color: var(--color-text);
}

.ps-section--home-posts .ps-section__morelink i {
  margin-left: 1em;
}

.ps-section--home-posts .ps-section__morelink:hover {
  color: var(--color-1st);
}

@media screen and (min-width: 768px) {
  .ps-section--home-posts .ps-section__heading {
    font-size: 36px;
  }
  .ps-section--home-posts .ps-section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1366px) {
  .ps-section--home-posts .ps-section__heading {
    font-size: 48px;
  }
}

@media screen and (max-width: 479px) {
  .ps-section--home-posts .ps-section__heading {
    margin-bottom: 10px;
    font-weight: 500;
  }
}

.ps-home-search-2 {
  display: grid;
  position: relative;
  z-index: 10;
  grid-template-columns: 1fr;
  text-align: center;
}

.ps-home-search-2 > img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}

.ps-home-search-2 h3 {
  font-size: 48px;
  line-height: 1.1em;
}

.ps-home-search-2 .ps-section__top {
  margin-bottom: 30px;
}

.ps-home-search-2 .ps-section__top p {
  margin-bottom: 0;
}

.ps-home-search-2 .ps-section__content {
  max-width: 1030px;
}

.ps-home-search-2 .ps-section__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-top: 100px;
}

.ps-home-search-2 .ps-section__wrapper .ps-section__content {
  flex-basis: 100%;
}

@media screen and (min-width: 768px) {
  .ps-home-search-2 > img {
    min-height: 650px;
  }
}

@media screen and (min-width: 992px) {
  .ps-home-search-2 h3 {
    font-size: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .ps-home-search-2 .ps-section__wrapper {
    padding-top: 220px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-home-search-2 h3 {
    font-size: 60px;
  }
  .ps-home-search-2 .ps-section__top p {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .ps-home-search-2 h3 {
    margin-bottom: 30px;
    font-size: 72px;
  }
  .ps-home-search-2 .ps-section__top {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 479px) {
  .ps-home-search-2 img {
    min-height: 100vh;
  }
  .ps-home-search-2 .ps-section__top p {
    max-width: 260px;
    margin: 0 auto;
    font-size: 14px;
  }
}

.ps-testimonials-and-stats .ps-site-stats, .ps-testimonials-and-stats .ps-testimonials {
  padding: 50px 0;
}

@media screen and (min-width: 1040px) {
  .ps-testimonials-and-stats .ps-site-stats, .ps-testimonials-and-stats .ps-testimonials {
    padding: 80px 0;
  }
}

@media screen and (min-width: 1366px) {
  .ps-testimonials-and-stats .ps-site-stats, .ps-testimonials-and-stats .ps-testimonials {
    padding: 100px 0;
  }
}

@media screen and (max-width: 479px) {
  .ps-testimonials-and-stats {
    padding: 0 16px;
  }
  .ps-testimonials-and-stats .ps-testimonials .ps-section__left {
    padding-bottom: 30px;
  }
}

.ps-project-listing .ps-carousel .owl-stage-outer {
  padding-bottom: 30px;
}

.ps-project-listing .ps-carousel .owl-dots {
  display: none;
}

.ps-project-listing.with-bg .ps-project--grid {
  border: none;
}

.ps-project-listing.with-bg .ps-project--grid:hover {
  box-shadow: 0px 16px 20px 0 rgba(92, 117, 134, 0.15);
}

.ps-agent-properties .ps-section__links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 10px;
}

.ps-agent-properties .ps-section__links a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 32px;
  border-radius: var(--border-radius-medium);
  color: var(--color-text);
}

.ps-agent-properties .ps-section__links a.active, .ps-agent-properties .ps-section__links a:hover {
  background-color: #f2f6f9;
  color: var(--color-1st);
}

.ps-agent-properties .ps-section__header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ps-agent-properties .ps-section__header-left .ps-section__heading {
  margin-right: 50px;
}

@media screen and (min-width: 1024px) {
  .ps-agent-properties .ps-section__heading {
    font-size: 30px;
  }
  .ps-agent-properties .ps-section__header {
    padding-bottom: 45px;
    display: grid;
    grid-template-columns: 1fr 100px;
  }
}

@media screen and (max-width: 479px) {
  .ps-agent-properties .ps-section__header-left {
    display: block;
  }
  .ps-agent-properties .ps-section__header-left .ps-section__heading {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.ps-notification-listing {
  display: grid;
  grid-row-gap: 30px;
}

.ps-navigation--sidebar {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.ps-navigation--sidebar > * {
  flex-basis: 100%;
  height: 100%;
}

.ps-navigation--sidebar .ps-navigation__top {
  overflow: auto;
}

.ps-navigation--sidebar .ps-navigation__bottom {
  max-height: 100px;
}

.ps-navigation--sidebar .ps-navigation__bottom figcaption {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #222;
}

.ps-navigation--sidebar .ps-navigation__bottom p {
  margin-bottom: 0;
  font-size: 16px;
}

.navigation--primary .container {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.navigation--primary .navigation__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navigation--bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid var(--color-border);
  z-index: 999;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation--bottom .navigation__item {
  justify-self: center;
  align-self: center;
}

.navigation--bottom .navigation__item a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: var(--border-radius-medium);
}

.navigation--bottom .navigation__item a.active {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.1);
}

.navigation--bottom .navigation__wrapper {
  max-width: 300px;
  margin: 0 auto;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (min-width: 1024px) {
  .navigation--bottom {
    display: none;
  }
}

.ps-page--default {
  padding: 75px 0 50px;
}

.ps-page--default .ps-page__header {
  text-align: center;
  padding-bottom: 60px;
}

.ps-page--default .ps-page__header h3 {
  font-size: 32px;
}

@media (min-width: 1200px) {
  .ps-page--default {
    padding: 90px 0 100px;
  }
  .ps-page--default .ps-page__header {
    padding-bottom: 80px;
  }
  .ps-page--default .ps-page__header h3 {
    font-size: 48px;
  }
}

.ps-page--about {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .ps-page--about {
    padding-top: 100px;
  }
}

@media (min-width: 1200px) {
  .ps-page--about {
    padding-top: 150px;
  }
}

.ps-page--404 {
  padding: 50px 0;
}

@media screen and (min-width: 1024px) {
  .ps-page--404 {
    padding: 100px 0;
  }
}

.ps-page--contact {
  padding-top: 0;
}

.ps-page--contact .ps-page__header {
  padding: 100px 0;
}

@media (min-width: 1200px) {
  .ps-page--contact .ps-page__header {
    padding: 150px 0;
  }
}

.ps-page--blog {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ps-page--blog .ps-page__header {
  padding-bottom: 35px;
}

.ps-page--blog .ps-page__header h1 {
  font-size: 28px;
}

.ps-page--blog .ps-page__header .breadcrumb {
  margin-bottom: 0;
}

.ps-page--blog .ps-blog--feature-posts {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--color-border);
}

@media screen and (min-width: 480px) {
  .ps-page--blog .ps-page__header h1 {
    font-size: 36px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-page--blog {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .ps-page--blog .ps-blog--feature-posts {
    padding-bottom: 85px;
    margin-bottom: 85px;
  }
  .ps-page--blog .ps-page__header {
    padding-bottom: 70px;
  }
  .ps-page--blog .ps-page__header h1 {
    font-size: 48px;
  }
}

.ps-page--post-single {
  padding-top: 32px;
}

.ps-page--post-single .breadcrumb {
  margin-bottom: 25px;
}

.ps-page--post-single .ps-page__header {
  padding-bottom: 50px;
}

.ps-page--post-single .ps-related-posts {
  padding-top: var(--section-padding-bottom);
  margin-bottom: var(--section-padding-bottom);
  border-top: 1px solid var(--color-border);
}

@media screen and (min-width: 1024px) {
  .ps-page--post-single {
    padding-top: 64px;
  }
  .ps-page--post-single .ps-page__header {
    padding-bottom: 100px;
  }
  .ps-page--post-single .breadcrumb {
    margin-bottom: 50px;
  }
}

.ps-page--inner {
  padding-top: 32px;
}

.ps-page--inner .ps-page__heading {
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 1em;
  font-weight: 500;
}

.ps-page--inner .breadcrumb {
  margin-bottom: 25px;
}

.ps-page--inner .ps-page__hero {
  padding-bottom: 50px;
}

.ps-page--inner .ps-page__hero img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ps-page--inner .ps-page__heading {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .ps-page--inner {
    padding-top: 64px;
  }
  .ps-page--inner .ps-page__heading {
    font-size: 48px;
    margin-bottom: 20px;
  }
  .ps-page--inner .ps-page__hero {
    padding-bottom: 100px;
  }
  .ps-page--inner .breadcrumb {
    margin-bottom: 50px;
  }
}

.ps-page--product {
  padding: 30px 0 0;
}

.ps-page--product .breadcrumb {
  background-color: transparent;
}

.ps-page--product .breadcrumb li a {
  color: var(--color-text);
}

.ps-page--product .ps-page__header {
  display: flex;
  flex-flow: column wrap;
  padding-bottom: 50px;
}

.ps-page--product .ps-page__nav {
  display: none;
}

.ps-page--product .ps-page__nav button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #bbb;
  padding: 0;
}

.ps-page--product .ps-page__nav button i {
  font-size: 12px;
  line-height: 1em;
}

.ps-page--product .ps-page__nav button:hover {
  border-color: var(--color-1st);
  color: var(--color-1st);
}

.ps-page--product .ps-related-products {
  padding: 50px 0;
}

.ps-page--product .ps-shop--sidebar {
  padding-top: 0;
}

.ps-page--product .ps-product--detail {
  margin-bottom: 60px;
}

@media (min-width: 1200px) {
  .ps-page--product .ps-related-products {
    padding: 80px 0;
  }
}

.ps-shop--search {
  background-color: #fff;
}

.ps-page--shopping .ps-page__header {
  margin: 30px 0;
  padding: 60px 0 30px;
}

.ps-page--shopping .ps-page__header h3 {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 600;
  color: var(--color-heading);
}

.ps-page--shopping .ps-page__header .breadcrumb {
  margin-bottom: 0;
  background: none;
  padding-left: 0;
}

.ps-page--shopping .ps-page__header.with-background {
  margin: 30px 0 50px;
  padding: 90px 30px 30px;
  background-color: var(--color-1st);
  border-radius: var(--border-radius-large);
  box-shadow: 0 2.3px 3.2px -16px rgba(0, 0, 0, 0.053), 0 9.2px 10.7px -16px rgba(0, 0, 0, 0.065), 0 30px 48px -16px rgba(0, 0, 0, 0.12);
}

.ps-page--shopping .ps-page__header.with-background h3 {
  color: #fff;
}

.ps-page--shopping .ps-page__header.with-background .breadcrumb {
  margin-bottom: 10px;
}

.ps-page--shopping .ps-page__header.with-background .breadcrumb li {
  color: #fff;
}

.ps-page--shopping .ps-page__header.with-background .breadcrumb li a {
  color: #e6e6e6;
}

.ps-page--shopping .ps-page__header.with-background .breadcrumb li a:hover {
  color: #fff;
}

.ps-page--projects .ps-form--projects-search {
  margin-bottom: 50px;
}

.ps-page--projects .ps-page__content {
  padding-bottom: var(--section-padding-bottom);
}

@media screen and (min-width: 1200px) {
  .ps-page--projects .container-fluid {
    padding: 0 40px;
  }
}

.ps-page--projects.ps-page--fullwidth .ps-page__header {
  padding-top: 40px;
}

.ps-page--single-property .ps-page__header {
  padding-top: 30px;
  padding-bottom: 15px;
}

.ps-page--single-property .ps-section--single-project {
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-border);
}

.ps-page--single-property .ps-project-listing {
  margin-bottom: 20px;
}

.ps-page--single-property .ps-project-listing .container {
  padding: 0;
}

@media screen and (min-width: 1024px) {
  .ps-page--single-property .ps-page__header {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .ps-page--single-property .ps-project-listing {
    margin-bottom: 30px;
  }
  .ps-page--single-property .ps-project-listing .ps-section__heading {
    font-size: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-page--single-property .ps-project-listing {
    margin-bottom: 50px;
  }
  .ps-page--single-property .ps-project-listing .ps-section__header {
    padding-bottom: 50px;
    align-items: flex-end;
  }
  .ps-page--single-property .ps-section--single-project {
    margin-bottom: 80px;
  }
}

.ps-page--auth .ps-page__content {
  padding-bottom: var(--section-padding-bottom);
}

.ps-page--account {
  padding-bottom: 40px;
}

@media screen and (min-width: 1024px) {
  .ps-page--account {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-page--account {
    padding-bottom: 80px;
  }
}

#homepage-one > * {
  margin-bottom: 65px;
}

#homepage-one .with-bg {
  margin-bottom: 0;
}

#homepage-one .ps-top-banners {
  margin-top: 40px;
}

#homepage-one .ps-home-search {
  margin-bottom: 0;
}

#homepage-one .ps-site-stats {
  padding-top: 65px;
}

#homepage-one .ps-property-types {
  margin-bottom: 0px;
}

#homepage-one .ps-project-listing {
  margin-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  #homepage-one > * {
    margin-bottom: 90px;
  }
  #homepage-one .ps-home-search {
    padding-top: 150px;
  }
  #homepage-one .ps-site-stats {
    position: relative;
    z-index: 999;
    top: -80px;
    margin-bottom: -80px;
    padding-top: 90px;
    padding-bottom: 90px;
    border-radius: 50px 50px 0 0;
    background-color: #fff;
    overflow: hidden;
  }
  #homepage-one .ps-property-types {
    margin-bottom: 30px;
  }
  #homepage-one .ps-highlight-posts, #homepage-one .ps-project-listing {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #homepage-one > * {
    margin-bottom: 130px;
  }
  #homepage-one .ps-site-stats {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  #homepage-one .ps-property-types {
    margin-bottom: 70px;
  }
  #homepage-one .ps-highlight-posts, #homepage-one .ps-project-listing {
    margin-bottom: 100px;
  }
}

#homepage-two > * {
  margin-bottom: 65px;
}

#homepage-two .ps-property-types .owl-stage-outer {
  padding-bottom: 0;
}

#homepage-two .ps-property-types .ps-section__header {
  padding-bottom: 35px;
}

#homepage-two .ps-submit-property {
  margin-bottom: 0;
}

#homepage-two .ps-submit-property .container {
  border-top: 1px solid var(--color-border);
}

#homepage-two > .ps-section.with-background {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);
}

#homepage-two .ps-testimonials-and-stats {
  margin-bottom: 0;
}

#homepage-two .ps-neighborhoods {
  padding-bottom: 20px;
}

#homepage-two .ps-project-listing.with-bg {
  padding-bottom: calc(var(--section-padding-top) - 30px);
}

#homepage-two .ps-testimonials {
  padding: 0;
}

@media screen and (min-width: 1024px) {
  #homepage-two > * {
    margin-bottom: 90px;
  }
  #homepage-two .ps-property-types .ps-section__header {
    padding-bottom: 70px;
  }
  #homepage-two .ps-testimonials-and-stats {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1366px) {
  #homepage-two > * {
    margin-bottom: 130px;
  }
  #homepage-two .ps-testimonials-and-stats {
    margin-bottom: 0;
  }
  #homepage-two .ps-project-listing:not(.with-bg) {
    margin-bottom: 100px;
  }
}

#about-us .ps-testimonials {
  max-width: 1540px;
  margin: 0 auto;
}

#about-us .ps-page__content > * {
  margin-bottom: var(--section-padding-bottom);
}

#about-us .ps-page__content .ps-our-services {
  margin-bottom: 0;
}

#contact-us .ps-contact-information {
  padding: 50px 0;
}

@media screen and (min-width: 1024px) {
  #contact-us .ps-contact-information {
    padding: 100px 0;
  }
}

#services .ps-page__header .breadcrumb {
  margin-bottom: 0;
}

#services .ps-testimonials {
  max-width: 1540px;
  margin: 0 auto;
  padding-top: 0;
}

#services .ps-faqs {
  padding-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  #services .ps-faqs {
    padding-bottom: 50px;
  }
}

#careers .ps-career-detail {
  padding-bottom: var(--section-padding-bottom);
}

#careers .ps-page__content > * {
  margin-bottom: 50px;
}

#careers .ps-page__content .ps-career-images {
  margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  #careers .ps-page__content > * {
    margin-bottom: 80px;
  }
  #careers .ps-page__content .ps-career-images {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1440px) {
  #careers .ps-page__content > * {
    margin-bottom: 100px;
  }
  #careers .ps-page__content .ps-career-images {
    margin-bottom: 70px;
  }
}

#agents .ps-agents {
  padding-bottom: var(--section-padding-bottom);
}

#agents .ps-page__header .ps-form--agent-search {
  margin-bottom: 50px;
}

#agents .ps-agent-information {
  padding-bottom: var(--section-padding-bottom);
}

#agents .ps-agent-properties {
  margin-bottom: 50px;
}

#agents .ps-layout--with-sidebar {
  padding-bottom: var(--section-padding-bottom);
}

@media screen and (min-width: 1024px) {
  #agents .ps-page__header .ps-form--agent-search {
    margin-bottom: 70px;
  }
  #agents .ps-agent-properties {
    margin-bottom: 80px;
  }
}

.sub-toggle {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;
  transform-style: preserve-3d;
}

.sub-toggle:before, .sub-toggle:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 1px;
  background-color: var(--color-heading);
}

.sub-toggle:before {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.sub-toggle:after {
  -webkit-transform: translateX(-50%) rotate(90deg);
  -moz-transform: translateX(-50%) rotate(90deg);
  -ms-transform: translateX(-50%) rotate(90deg);
  -o-transform: translateX(-50%) rotate(90deg);
  transform: translateX(-50%) rotate(90deg);
}

.sub-toggle.active:after {
  display: none;
}

.sub-menu {
  min-width: 240px;
  z-index: 999;
  transition: all 0.25s ease;
  background-color: #fff;
  border: 1px solid #efefef;
  visibility: hidden;
  opacity: 0;
}

.sub-menu:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 0;
  width: 0;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  display: none;
}

.sub-menu > li {
  display: block;
}

.sub-menu > li > a {
  display: inline-block;
  padding: 10px 20px;
  width: 100%;
  font-size: var(--font-size-default);
  color: var(--color-text);
  text-transform: capitalize;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.sub-menu > li > a:hover {
  color: var(--color-1st);
}

.sub-menu > li:last-child a {
  border-bottom: 1px solid #eee;
}

.sub-menu > li:last-child {
  border-bottom: none;
}

.sub-menu .menu-item-has-children {
  position: relative;
}

.sub-menu .menu-item-has-children > .sub-toggle {
  display: none;
}

.sub-menu .menu-item-has-children > .sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.sub-menu .menu-item-has-children > .sub-menu:before {
  display: none;
}

.sub-menu .menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

@media screen and (max-width: 1023px) {
  .sub-menu .menu-item-has-children > .sub-menu {
    position: relative;
    visibility: visible;
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

.mega-menu {
  background-color: #fff;
  border: 1px solid #efefef;
  transition: all 0.25s ease;
}

.mega-menu .sub-menu--mega {
  display: none;
  background-color: transparent;
}

.mega-menu .mega-menu__column > h4 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-heading);
}

.mega-menu .menu--single li a {
  color: var(--color-text);
}

.mega-menu .menu--single li a:hover {
  color: var(--color-1st);
}

.mega-menu.with-4-columns .mega-menu__wrapper {
  grid-template-columns: repeat(4, 1fr);
}

.mega-menu.with-5-columns .mega-menu__wrapper {
  grid-template-columns: repeat(6, 1fr);
}

.mega-menu.with-6-columns .mega-menu__wrapper {
  grid-template-columns: repeat(6, 1fr);
}

@media screen and (min-width: 1024px) {
  .mega-menu {
    box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.25);
    border-radius: 0 0 5px 5px;
  }
  .mega-menu .mega-menu__wrapper {
    padding: 30px;
    display: grid;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    width: 100%;
    margin: 0 auto;
  }
  .mega-menu .mega-menu__wrapper[data-columns="4"] {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .mega-menu .mega-menu__wrapper[data-columns="5"] {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .mega-menu .mega-menu__wrapper[data-columns="6"] {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (max-width: 479px) {
  .mega-menu .mega-menu__column h4 {
    margin-bottom: 0;
  }
}

.menu--desktop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu--desktop > li {
  margin-right: 40px;
}

.menu--desktop > li > a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 19px 0 53px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-heading);
  text-transform: capitalize;
  transform-style: preserve-3d;
}

.menu--desktop > li > a:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-1st);
  transform: translateY(-100%) scaleX(0);
  transform-origin: 100% 50%;
  transition: transform 0.75s cubic-bezier(0.2, 1, 0.3, 1);
}

.menu--desktop > li > a:hover:after {
  transform: translateY(-100%) scaleX(1);
  transform-origin: 0%;
}

.menu--desktop > li:first-child {
  padding-left: 0;
}

.menu--desktop > li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.menu--desktop > li .sub-toggle {
  display: none;
}

.menu--desktop > li.menu-item-has-children {
  position: relative;
}

.menu--desktop > li.menu-item-has-children .sub-toggle {
  margin-left: 5px;
  display: none;
}

.menu--desktop > li.menu-item-has-children > .sub-menu, .menu--desktop > li.menu-item-has-children .mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  border-radius: 0 0 5px 5px;
  -webkit-transform: scale3d(1, 1, 0);
  -moz-transform: scale3d(1, 1, 0);
  -ms-transform: scale3d(1, 1, 0);
  -o-transform: scale3d(1, 1, 0);
  transform: scale3d(1, 1, 0);
}

.menu--desktop > li.menu-item-has-children:hover .sub-toggle {
  color: #ffffff;
}

.menu--desktop > li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.menu--desktop > li.has-mega-menu {
  position: static;
}

.menu--desktop > li.has-mega-menu .mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-transform: scale3d(1, 1, 0);
  -moz-transform: scale3d(1, 1, 0);
  -ms-transform: scale3d(1, 1, 0);
  -o-transform: scale3d(1, 1, 0);
  transform: scale3d(1, 1, 0);
  visibility: hidden;
  opacity: 0;
}

.menu--desktop > li.has-mega-menu:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.menu--single li a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 6px 0;
  font-size: 16px;
  color: var(--color-1st);
  background-color: transparent;
}

.menu--single li a:hover {
  color: var(--color-2nd);
}

.menu--single.bold li a {
  font-weight: 600;
}

.menu--topbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu--topbar > li {
  border-right: 1px solid #d9dee8;
  padding: 0 25px;
}

.menu--topbar > li > a {
  text-transform: capitalize;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-gray);
}

.menu--topbar > li > a:hover {
  color: var(--color-2nd);
}

.menu--accordion {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu--accordion > li {
  padding-right: 0;
}

.menu--accordion > li > a {
  padding: 10px 0;
}

.menu--accordion .menu__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.menu--accordion .menu__toggle > * {
  flex-basis: 100%;
}

.menu--accordion .menu__toggle i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  max-width: 30px;
  margin-right: 0;
}

.menu--accordion .menu__toggle:hover {
  background-color: rgba(228, 228, 228, 0.1);
}

.menu--accordion .menu__title {
  display: block;
  padding: 10px 20px;
  font-size: 11px;
  color: var(--color-text);
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
}

.menu--accordion .menu__text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-heading);
}

.menu--accordion .menu__text .menu__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  border-radius: 6px;
  margin-right: 0.5em;
}

.menu--accordion .sub-menu {
  position: relative;
  top: 0;
  left: 0;
  list-style-type: none;
  max-width: 100%;
  width: 100%;
  border: none;
  box-shadow: none;
  padding-left: 10px;
  transform: translate(0, 0) scale3d(1, 1, 1) !important;
  display: block !important;
  visibility: visible;
  opacity: 1;
}

.menu--accordion .sub-menu .menu__text {
  position: relative;
  font-size: 16px;
}

.menu--accordion .sub-menu .menu__text:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
}

.menu--accordion .sub-menu .menu__icon--down {
  color: var(--color-heading);
}

.menu--accordion .sub-menu > li > a {
  padding: 10px 12px;
}

.menu--accordion .sub-menu > li > a:hover {
  background-color: rgba(204, 204, 204, 0.1);
}

.menu--accordion .sub-menu > li:last-child a {
  border: none;
}

.menu--accordion .sub-menu-wrapper > .sub-menu {
  background-color: transparent;
  box-shadow: none;
}

.menu--accordion .sub-menu,
.menu--accordion .sub-menu-wrapper {
  transition: height 0.25s cubic-bezier(0.7, 0, 0.3, 1);
}

.menu--accordion .menu__item {
  display: block;
}

.menu--accordion .menu__item a:after {
  display: none;
}

.menu--accordion .menu__item--has-children span > a {
  color: #fff;
}

.menu--accordion.menu--accordion-white .sub-menu .menu__text {
  color: var(--color-heading);
}

.menu--accordion.menu--accordion-white > .menu__item > a {
  width: 100%;
  color: var(--color-heading);
  border-radius: 4px;
}

.menu--accordion.menu--accordion-white > .menu__item > a:hover {
  background-color: rgba(204, 204, 204, 0.1);
}

.menu--accordion.menu--accordion-white > .menu__item > .menu__toggle {
  color: var(--color-heading);
}

.menu--mobile .sub-toggle:before, .menu--mobile .sub-toggle:after {
  background-color: var(--color-text);
}

.menu--mobile .sub-menu, .menu--mobile .mega-menu {
  display: none;
  position: relative;
  padding-left: 20px;
  transform: translate(0, 0);
  transition: all 0s ease;
  border: none;
  visibility: visible;
  opacity: 1;
}

.menu--mobile .sub-menu > li > a, .menu--mobile .mega-menu > li > a {
  border: none;
  padding: 10px 20px;
}

.menu--mobile .menu--single {
  padding-left: 16px;
  display: none;
}

.menu--mobile .menu--single li a {
  color: var(--color-text);
}

.menu--mobile .sub-menu > li > a {
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  color: var(--color-text);
}

.menu--mobile .mega-menu {
  padding-left: 16px;
}

.menu--mobile .mega-menu .mega-menu__column h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.menu--mobile > li {
  border-bottom: 1px solid var(--color-border);
}

.menu--mobile > li > a {
  position: relative;
  z-index: 10;
  display: block;
  padding: 15px 20px 15px 0;
  line-height: 20px;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-heading);
  text-transform: capitalize;
}

.menu--mobile > li.menu-item-has-children:not(.has-mega-menu) {
  position: relative;
}

.menu--mobile > li.menu-item-has-children:not(.has-mega-menu) .sub-toggle {
  position: absolute;
  top: 5px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 20;
  display: inline-block;
}

.menu--mobile > li.has-mega-menu {
  position: relative;
}

.menu--mobile > li.has-mega-menu > .sub-toggle {
  position: absolute;
  top: 5px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 20;
  display: inline-block;
}

.menu--mobile > li.has-mega-menu .mega-menu {
  display: none;
}

.menu--mobile > li:last-child {
  border: none;
}

.ps-sidebar--shop .ps-widget {
  margin-bottom: 30px;
}

.ps-sidebar--blog .ps-widget--blog {
  margin-bottom: 35px;
}

@media screen and (min-width: 1024px) {
  .ps-sidebar--blog .ps-widget--blog {
    margin-bottom: 70px;
  }
}

/*
    CSS for shop pages
*/
.ps-shop__layout-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-medium);
  border: 2px solid var(--color-border);
}

.ps-shop__layout-switcher i {
  font-size: 20px;
  color: var(--color-text);
}

.ps-shop__layout-switcher:hover, .ps-shop__layout-switcher.active {
  border-color: var(--color-border-active);
}

.ps-shop__layout-switcher:hover i, .ps-shop__layout-switcher.active i {
  color: var(--color-heading);
}

.ps-shop__layout-switchers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-shop__layout-switchers a:first-child {
  margin-right: 10px;
}

.ps-shop__actions-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.ps-shop__actions-left p {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--color-heading);
}

.ps-shop__actions-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-shop__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ps-shop__actions .ps-shop__sortby {
  margin-right: 10px;
}

.ps-shop__header {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--color-border);
}

.ps-dashboard .menu--single .active a {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.075);
}

.ps-dashboard .menu--single .active a span {
  color: var(--color-1st);
}

.ps-dashboard .ps-dashboard__heading {
  font-size: 36px;
  text-transform: capitalize;
}

.ps-dashboard .ps-dashboard__left .ps-block--user-dashboard {
  margin-bottom: 32px;
  grid-grap: 16px;
}

.ps-dashboard .ps-dashboard__sidebar {
  padding: 16px;
  background-color: var(--color-bg-gray);
  border-radius: var(--border-radius-medium);
}

.ps-dashboard .ps-dashboard__sidebar .menu--single a {
  color: var(--color-heading);
  height: 48px;
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--border-radius-medium);
}

.ps-dashboard .ps-dashboard__sidebar .menu--single a i {
  margin-right: 16px;
}

.ps-dashboard .ps-dashboard__sidebar .menu--single a span {
  font-weight: 400;
}

.ps-dashboard .ps-dashboard__sidebar .menu--single a:hover {
  color: var(--color-1st);
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.075);
}

.ps-dashboard .ps-dashboard__right {
  min-height: 600px;
}

@media screen and (min-width: 1024px) {
  .ps-dashboard {
    display: grid;
    grid-template-columns: 280px minmax(0, 1fr);
    margin-bottom: var(--section-padding-bottom);
    grid-gap: 16px;
  }
}

@media screen and (min-width: 1280px) {
  .ps-dashboard {
    grid-gap: 36px;
  }
}

@media screen and (min-width: 1366px) {
  .ps-dashboard {
    grid-gap: 48px;
  }
}

@media screen and (min-width: 1440px) {
  .ps-dashboard {
    grid-gap: 36px;
  }
}

@media screen and (max-width: 768px) {
  .ps-dashboard .ps-dashboard__left {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .ps-my-properties .ps-panel__actions {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
  }
}

.header__account-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px;
  min-width: 180px;
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  z-index: 999;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all .4s ease;
}

.header__account-dropdown .menu--single > li > a {
  padding: 8px 16px;
  color: var(--color-heading);
  border-radius: var(--border-radius-medium);
}

.header__account-dropdown .menu--single > li > a:hover {
  background-color: hsla(var(--color-1st-h), var(--color-1st-s), var(--color-1st-l), 0.075);
}

.header__account > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  padding: 0 30px;
  color: var(--color-heading);
  border-radius: var(--border-radius-medium);
  font-weight: 500;
}

.header__account > a:hover {
  color: #fff;
  background-color: var(--color-1st);
}

.header__account i {
  margin-right: 16px;
  color: inherit;
}

.header__account.logged-in {
  position: relative;
}

.header__account.logged-in:hover .header__account-dropdown {
  visibility: visible;
  opacity: 1;
}

.header__submit-listing .ps-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
}

.header__submit-listing .ps-btn i {
  margin-left: 16px;
}

.header__actions {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.header--sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  padding: 10px 0;
  background-color: #fff;
  border-bottom: 1px solid var(--color-border);
}

.header--sticky .menu > li > a {
  padding-bottom: 16px;
}

.header--sticky .header__logo .ps-logo.light {
  display: none;
}

.header--desktop .ps-logo img {
  max-height: 46px;
}

.header--desktop .ps-logo.light {
  display: none;
}

.header--desktop .header__right {
  display: flex;
  justify-content: flex-end;
}

.header--desktop .header__right .header__actions {
  margin-right: 10px;
}

.header--desktop .header__social-links {
  padding: 0 10px;
  border-right: 1px solid #d9dee8;
}

.header--desktop .header__social-links li {
  margin-right: 0;
}

.header--desktop .header__social-links li a {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0;
}

.header--desktop .header__social-links li a i {
  color: var(--color-gray);
  font-size: 14px;
  transition: all 0.4s ease;
}

.header--desktop .header__social-links li a:hover {
  background-color: transparent;
}

.header--desktop .header__social-links li a:hover i {
  color: var(--color-2nd);
}

.header--desktop .ps-dropdown--fullscreen {
  position: static;
}

.header--desktop .header__categories-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  height: 40px;
  padding: 0 16px;
  min-width: 200px;
  line-height: 30px;
  text-transform: capitalize;
  border-radius: var(--border-radius-medium);
  color: #fff;
  background-color: var(--color-1st);
}

.header--desktop .header__categories-toggle i {
  margin-right: 6px;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  transition: all 0.4s ease;
}

.header--desktop .header__categories-toggle span {
  font-weight: 500;
  font-size: 15px;
  font-size: inherit;
  line-height: inherit;
  transition: all 0.4s ease;
}

.header--desktop .header__left .header__toggle-btn {
  display: none;
}

.header--desktop .header__top {
  padding: 20px 0;
}

.header--desktop .header__top > .container {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
}

.header--desktop .header__bottom {
  padding: 5px 0;
  /* border-top: 1px solid var(--color-border);
         border-bottom: 1px solid var(--color-border);*/
  background-color: var(--color-bg-gray);
}

.header--desktop .header__bottom .header__categories {
  margin-right: 20px;
}

.header--desktop .header__wrapper {
  position: relative;
}

@media screen and (max-width: 1199px) {
  .header--desktop {
    display: none;
  }
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: transparent;
}

.header--fixed.header--sticky {
  background-color: #fff;
  border-bottom: none;
  box-shadow: 1px 1px rgba(204, 204, 204, 0.25);
}

.header--transparent:not(.header--sticky) .menu--desktop > li > a {
  color: #fff;
}

.header--transparent:not(.header--sticky) .menu--desktop > li > a:after {
  background-color: #fff;
}

.header--transparent:not(.header--sticky) .header__submit-listing .ps-btn {
  background-color: rgba(255, 255, 255, 0.25);
  color: #ffffff;
}

.header--transparent:not(.header--sticky) .header__submit-listing .ps-btn:hover {
  background-color: var(--color-1st);
}

.header--transparent:not(.header--sticky) .header__account a {
  color: #ffffff;
}

.header--transparent:not(.header--sticky) .ps-logo:not(.light) {
  display: none;
}

.header--transparent:not(.header--sticky) .ps-logo.light {
  display: block;
}

.header--one .header__left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.header--one .header__left .ps-logo {
  margin-right: 145px;
}

.header--one .header__actions .header__account {
  margin-right: 10px;
}

.header--one .header__wrapper {
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  border-bottom: 1px solid var(--color-border);
  padding-top: 36px;
}

.header--one.header--sticky .header__account a {
  height: 46px;
}

.header--one.header--sticky .header__submit-listing .ps-btn {
  height: 46px;
}

.header--one.header--sticky .header__left, .header--one.header--sticky .header__right {
  align-items: center;
}

.header--one.header--sticky .header__wrapper {
  padding: 0;
  border-bottom: none;
}

@media screen and (max-width: 1440px) {
  .header--one .header__wrapper {
    grid-template-columns: 5fr 2fr;
  }
}

.header--mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 0 none;
  padding: 20px 16px;
  background-color: #fff;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border-bottom: 1px solid var(--color-border);
}

.header--mobile .ps-logo {
  line-height: 20px;
}

.header--mobile .ps-logo img {
  max-height: 30px;
}

.header--mobile .header__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header--mobile .header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header--mobile.header--sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 16px;
  border-bottom: 1px solid var(--color-gray);
}

@media (min-width: 1200px) {
  .header--mobile {
    display: none;
  }
}

.ps-footer .ps-widget--footer {
  margin-bottom: 30px;
}

.ps-footer .ps-widget--footer .ps-social-links a:hover {
  text-decoration: none;
}

.ps-footer__copyright {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.ps-footer__copyright p {
  margin-bottom: 0;
  color: #fff;
  line-height: 1em;
}

@media screen and (max-width: 1024px) {
  .ps-footer {
    margin-bottom: 60px;
  }
}

.ps-footer--default {
  background-color: var(--color-bg-primary);
}

.ps-footer--default .ps-footer__copyright {
  padding-bottom: 60px;
  padding-top: 32px;
}

.ps-footer--default .ps-footer__top {
  display: grid;
  padding-top: 80px;
  padding-bottom: 50px;
}

.ps-footer--default.light {
  background-color: #fff;
}

.ps-footer--default.light .ps-widget--footer p {
  color: var(--color-heading);
}

.ps-footer--default.light .ps-widget--footer .ps-social-links a {
  color: var(--color-heading);
}

.ps-footer--default.light .ps-widget--footer .ps-widget__links li a {
  color: var(--color-heading);
}

.ps-footer--default.light .ps-widget--footer .ps-widget__links li a:hover {
  color: var(--color-text);
}

.ps-footer--default.light .ps-footer__copyright p {
  color: var(--color-heading);
}

.ps-footer--default.light .ps-footer__bottom {
  border-top: 1px solid rgba(6, 5, 5, 0.1);
}

.ps-footer--default.light .ps-form--widget-subscribe .ps-form__bottom p {
  color: var(--color-text);
}

.ps-footer--default.light .ps-form--widget-subscribe .ps-form__bottom p a {
  color: var(--color-heading);
}

.ps-footer--default.light .ps-form--widget-subscribe .ps-form__bottom p a:hover {
  color: var(--color-1st);
}

@media screen and (min-width: 768px) {
  .ps-footer--default .ps-widget-footer-contact {
    max-width: 255px;
  }
  .ps-footer--default .ps-footer__widgets {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr;
  }
}

@media screen and (min-width: 1040px) {
  .ps-footer--default .ps-footer__top {
    grid-template-columns: 7fr 3fr;
    grid-gap: 30px;
    padding-top: 120px;
    padding-bottom: 70px;
  }
  .ps-footer--default .ps-footer__copyright {
    padding-top: 65px;
    padding-bottom: 122px;
  }
}

@media screen and (min-width: 1440px) {
  .ps-footer--default .ps-footer__top {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

html .bg--parallax {
  position: relative;
  z-index: 10;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50%;
}

.bg--cover {
  background-position: 50% 50% !important;
  background-size: cover !important;
}

.bg--top {
  background-position: 0% 0% !important;
  background-size: cover !important;
}

.bg--top-right {
  background-position: 100% 100% !important;
  background-size: cover !important;
}

.bg--top-left {
  background-position: 0% 100% !important;
  background-size: cover !important;
}

.bg--bottom {
  background-position: 100% 100% !important;
  background-size: cover !important;
}

.ps-panel--sidebar ul, .ps-widget--footer ul, .ps-widget--deparments ul, .ps-drawer ul, .ps-career-jobs .ps-section__header ul, .ps-page--default .ps-page__header ul, .ps-page--product .ps-page__header ul, .ps-dashboard ul, .header--desktop ul, .ps-panel--sidebar ol, .ps-widget--footer ol, .ps-widget--deparments ol, .ps-drawer ol, .ps-career-jobs .ps-section__header ol, .ps-page--default .ps-page__header ol, .ps-page--product .ps-page__header ol, .ps-dashboard ol, .header--desktop ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

/*# sourceMappingURL=style.css.map */
