.ps-section {
    //padding-top: var(--section-padding-top);
    //padding-bottom: var(--section-padding-bottom);

    &__heading {
        margin-bottom: 0;
        line-height: 1em;
        font-weight: 500;
        color: var(--color-heading);

        .ps-section__morelink {
            font-size: 16px;
            padding-left: 50px;
            color: var(--color-text);
            text-transform: capitalize;

            i {
                margin-left: 25px;
            }

            &:hover, &.active {
                color: var(--color-1st);
            }
        }
    }

    &__header {
        padding-bottom: var(--section-header-padding-bottom);
    }

    &__carousel-navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ps-btn--carouse-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border: none;
            font-size: 16px;
            color: var(--color-1st);
            border-radius: var(--border-radius-medium);
            background-color: alpha(var(--color-1st), 0.1);

            &:hover {
                color: #ffffff;
                background-color: alpha(var(--color-1st), 1);
            }
        }

        .prev {
            margin-right: 8px;
        }

    }

    &.with-carousel {
        .ps-section__header {
            display: grid;
            grid-template-columns: 3fr 1fr;
        }

        .owl-carousel {
            .owl-nav {
                display: none;
            }
        }
    }

    &.with-bg {
        padding-top: var(--section-padding-top);
        padding-bottom: var(--section-padding-top);
    }

    @media screen and (min-width: 1024px) {
        &__heading {
            font-size: 40px;

            .ps-section__morelink {
                font-size: 18px;
            }
        }
    }

    @media screen and (min-width: 1366px) {
        &__heading {
            font-size: 48px;
        }
    }

    @media screen and (max-width: 479px) {
        &__heading {

            .ps-section__morelink {
                display: block;
                padding-left: 0;
                line-height: 1em;
                padding-top: 0.75em;

                i {
                    margin-left: 10px;
                }
            }
        }
    }
}

.ps-top-destinations {
    background-color: #fff;

    .ps-section__columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
    }

    .ps-section__content {
        min-height: 300px;

    }

    &.with-bg--color {
        //background-color: var(--color-bg-gray);
        background-color: #f8fafc;
    }

    @media screen and (min-width: 768px) {
        .ps-section__columns {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (min-width: 1040px) {
        .ps-section__columns {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__columns {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-section__columns {
            grid-gap: 30px;
        }
    }
}

.ps-property-types {
    .ps-carousel {

        .owl-stage-outer {
            padding-bottom: 60px;
        }
    }

    .owl-nav, .owl-dots {
        display: none;
    }
}

.ps-neighborhoods {
    .row {
        grid-row-gap: 50px;
    }

    @media screen and (max-width: 479px) {
        .row {
            grid-row-gap: 30px;
        }
    }

    @media screen and (min-width: 1366px) and (max-width: 1439px) {
        .row {

            .col-lg-4 {
                flex: 0 0 33.333%;
                max-width: 33.333%;
            }
        }
    }
}

.ps-testimonials {
    .ps-section__wrapper {
        display: grid;
        border-bottom: 1px solid var(--color-border);
        padding: 60px 0;
    }

    @media screen and (min-width: 1024px) {
        .ps-section__left {
            padding-right: 45px;
            border-right: 1px solid var(--color-border);
        }

        .ps-section__right {
            padding-left: 45px;
        }

        .ps-section__wrapper {
            grid-template-columns: 4fr 8fr;
            padding: 90px 0;
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__left {
            padding-right: 90px;
        }

        .ps-section__right {
            padding-left: 90px;
        }

        .ps-section__wrapper {
            padding: 130px 0;
        }
    }

    @media screen and (min-width: 1600px) {
        .ps-section__left {
            padding-right: 130px;
            border-right: 1px solid var(--color-border);
        }

        .ps-section__right {
            padding-left: 130px;
        }
    }

    @media screen and (max-width: 768px) {
        .ps-section__left {
            padding: 0 16px 60px;
        }
    }
}

.ps-site-stats {
    .ps-section__mission {
        h3 {
            margin-bottom: 0;
            font-size: 30px;
            font-weight: 500;
            line-height: 1.2em;
        }
    }

    .ps-section__desc {
        p {
            max-width: 420px;
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .ps-section__brands {
        p {
            font-size: 13px;
        }

        a {
            display: block;
            margin-bottom: 30px;

            img {
                width: auto;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .ps-section__intro {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid var(--color-border);
    }

    .ps-section__content {
        display: grid;
    }

    .ps-section__wrapper {
        display: grid;
    }

    @media screen and (min-width: 768px) {
        .ps-section__mission {
            h3 {
                font-size: 48px;
            }
        }

        .ps-section__desc {
            p {
                font-size: 20px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-section__mission {
            h3 {
                font-size: 36px;
            }
        }

        .ps-section__desc {
            p {
                font-size: 20px;
            }
        }

        .ps-section__content {
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
        }

        .ps-section__intro {
            grid-template-columns: 1fr 255px;
            grid-gap: 30px;
            padding-bottom: 100px;
            margin-bottom: 100px;
        }

        .ps-section__wrapper {
            grid-column-gap: 16px;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__wrapper {

            grid-template-columns: 3.5fr 3fr 2.5fr
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-section__mission {
            h3 {
                font-size: 40px;
            }
        }
    }

    @media screen and (min-width: 1600px) {
        .ps-section__mission {
            h3 {
                font-size: 48px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .ps-section__wrapper {
            grid-row-gap: 16px;
        }
        .ps-section__mission {
            margin-bottom: 30px;
        }
        .ps-section__content {
            margin-bottom: 30px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .ps-section__mission {
            h3 {
                font-size: 30px;

                br {
                    display: none;
                }
            }
        }
        .ps-section__brands {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 16px;

            > a {
                align-self: center;
                margin-bottom: 0;
                padding: 10px 0;
            }

            p {
                grid-column: 1/4;
            }
        }
        .ps-section__content {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 30px;
            padding-bottom: 30px;
        }

        .ps-section__wrapper {
            grid-row-gap: 16px;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.ps-leaderships {

    .ps-block--team-member {
        margin-bottom: 20px;
    }

    .ps-section__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        grid-row-gap: 0;
    }

    @media screen and (min-width: 768px) {
        .ps-section__columns {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-block--team-member {
            margin-bottom: 80px;
        }

        .ps-section__columns {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__columns {
            grid-template-columns: repeat(5, 1fr);

        }
    }
}

.ps-our-services {
    .ps-block--service {
        margin-bottom: 30px;
    }

    .ps-section__columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }

    @media screen and (min-width: 768px) {
        .ps-section__columns {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (min-width: 1024px) {

        .ps-section__columns {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 60px;
        }
    }

    @media screen and (min-width: 1366px) {

        .ps-section__columns {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 90px;
        }
    }
}

//blog

.ps-blog {

    .ps-blog__links {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;


        a {
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
            font-size: 13px;
            text-transform: uppercase;
            color: var(--color-heading);
            font-weight: 500;

            &.active, &:hover {
                color: var(--color-1st);
            }
        }
    }

    .ps-blog__bottom {
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: 1024px) {


        .ps-blog__bottom {
            padding-top: 50px;
        }
    }
}

.ps-blog--grid {
    .ps-blog__links {
        flex-flow: row wrap;
    }

    .ps-blog__header {
        display: grid;
        padding-bottom: 30px;
    }

    @media screen and (min-width: 1024px) {
        .ps-blog__header {
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
            padding-bottom: 60px;
        }

        .ps-blog__bottom {
            padding-top: 50px;
        }
    }
}

.ps-blog--list {
    .ps-blog__links {
        padding-bottom: 30px;
    }

    .ps-blog__bottom {
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 10px;
    }

    .ps-blog__items {
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1024px) {
        .ps-blog__links {
            padding-bottom: 60px;
        }

        .ps-blog__items {
            .ps-post--horizontal {
                margin-bottom: 60px;
            }
        }
        .ps-blog__bottom {
            padding-top: 10px;
        }
    }
}

.ps-blog--feature-posts {
    display: grid;

    .ps-post--only-text {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--color-border);

        &:last-child {
            margin: 0;
            padding: 0;
            border-bottom: none;
        }
    }

    .ps-blog__left {
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid var(--color-border);
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;

        .ps-post--only-text {
            margin-bottom: 46px;
            padding-bottom: 46px;
        }

        .ps-blog__left {
            margin-bottom: 0;
            padding-bottom: 0;
            padding-right: 40px;
            border-bottom: none;
            border-right: 1px solid var(--color-border);
        }
    }

    @media screen and (min-width: 1366px) {
        grid-gap: 85px;
        .ps-blog__left {
            padding-right: 85px;
        }
    }
}

.ps-related-posts {
    .ps-section__heading {
        font-size: 24px;
    }

    @media screen and (min-width: 1024px) {
        .ps-section__heading {
            font-size: 30px;
        }
    }
}

.ps-contact-information {

}

.ps-contact-map {

    .ps-section__map {
        padding-bottom: 50px;
    }

    .ps-section__information {
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 50px;

        h3 {
            margin-bottom: 36px;
            font-size: 30px;
        }

        h5 {
            margin-bottom: 25px;
            font-size: 14px;
            text-transform: uppercase;
            color: var(--color-text-2);
        }

        p {
            font-size: 16px;

            strong {
                font-size: inherit;
                color: #000000;
                font-weight: 500;
            }

            a {
                font-size: inherit;
                color: inherit;

                &:hover {
                    color: var(--color-1st);
                    text-decoration: underline;
                }
            }
        }

        .ps-section__link {
            font-size: 16px;
            margin-bottom: 0;
            color: var(--color-text);
            text-decoration: underline;

            &:hover {
                color: var(--color-1st);
            }
        }
    }

    @media screen and (min-width: 768px) {
        .ps-section__information {
            h3 {
                font-size: 36px;
            }

            p {
                font-size: 20px;
            }

        }
    }

    @media screen and (min-width: 1024px) {

        .ps-section__information {
            padding-bottom: 100px;

            h3 {
                margin-bottom: 75px;
                font-size: 48px;
            }

            h5 {
                margin-bottom: 45px;
            }

            p {
                font-size: 24px;
            }

            .ps-section__link {
                font-size: 24px;
            }
        }

        .ps-section__map {
            padding-bottom: 100px;
        }
    }
}

.ps-our-team {
    padding-top: 0;
}

.ps-join-us {
    min-height: 380px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding-top: 80px;

    h3 {
        margin-bottom: 25px;
        font-size: 24px;
    }

    p {
        margin-bottom: 40px;
        font-size: 16px;
    }

    .ps-btn {
        margin: 0 auto;
        max-width: 230px;
    }

    @media screen and (min-width: 768px) {
        min-height: 640px;
        h3 {
            margin-bottom: 50px;
            font-size: 48px;
        }
        p {
            margin-bottom: 80px;
            font-size: 18px;
        }
    }

    @media screen and (min-width: 1366px) {
        padding-top: 170px;
        min-height: 780px;
    }
}

.ps-faqs {
    .ps-block--faqs {
        margin-bottom: 50px;
    }

    @media screen and (min-width: 1024px) {
        .ps-block--faqs {
            margin-bottom: 100px;
        }
    }
}

.ps-career-intro {

    .ps-section__wrapper {
        display: grid;
        padding-bottom: 50px;
        border-bottom: 1px solid var(--color-border);
    }

    .ps-section__left {
        h3 {
            margin-bottom: 40px;
            font-size: 24px;
        }

        .ps-btn {
            max-width: 230px;
        }
    }

    .ps-section__right {
        p {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .ps-section__left {
            h3 {
                margin-bottom: 80px;
                font-size: 36px;
            }
        }
        .ps-section__right {
            p {
                margin-bottom: 40px;
                font-size: 16px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-section__wrapper {
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
            padding-bottom: 100px;
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__left {
            h3 {
                font-size: 48px;
            }
        }

        .ps-section__wrapper {
            padding-bottom: 100px;
        }
    }
}

.ps-career-images {

    .ps-section__image {
        border-radius: var(--border-radius-medium);
        margin-bottom: 30px;
    }
}

.ps-career-jobs {
    .ps-section__links {

    }

    .ps-block--job {
        margin-bottom: 40px;
    }

    .ps-section__links {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 20px;
    }

    .ps-section__locations {
        a {
            font-size: 24px;
        }

        li {
            margin-right: 30px;

            &.active {
                color: var(--color-1st);
            }
        }
    }

    .ps-section__jobs {
        a {
            font-size: 14px;
        }

        li {
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ps-section__header {
        @extend %list-reset;

        .ps-section__links {
            padding-bottom: 20px;
        }
    }

    .ps-section__bottom {
        padding-top: 40px;

        h4 {
            font-size: 18px;
            margin-bottom: 20px;

        }

        p {
            max-width: 600px;

            a {
                font-size: inherit;
            }
        }
    }

    @media screen and (min-width: 768px) {
        figure {

        }

        .ps-block--job {
            margin-bottom: 80px;
        }

        .ps-section__locations {
            a {
                font-size: 36px;
            }
        }

        .ps-section__jobs {
            a {
                font-size: 18px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-section__header {
            figcaption {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                text-transform: uppercase;
                color: var(--color-text-2);
            }

            figure {
                display: grid;
                grid-template-columns: 285px 1fr;
                margin-bottom: 40px;

            }

            .ps-section__links {

                padding-bottom: 40px;
                border-bottom: 1px solid var(--color-border);
            }
        }

        .ps-section__content {
            padding-left: 285px;
        }

        .ps-section__bottom {
            padding-top: 80px;

            h4 {
                font-size: 24px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

.ps-agents {
    .ps-block--agent {
        margin-bottom: 50px;
    }

    .ps-section__top {
        padding-bottom: 25px;

        p {
            margin: 0;

            strong {
                font-weight: 500;
                color: var(--color-1st);
            }
        }
    }

    .ps-section__bottom {
        padding-top: 20px;
    }

    @media screen and (min-width: 1024px) {
        .ps-section__top {
            padding-bottom: 50px;

            p {
                font-size: 16px;
            }
        }

        .ps-section__bottom {
            padding-top: 40px;
        }
    }
}

.ps-agent-information {
    .ps-section__image {
        border-radius: var(--border-radius-medium);
    }

    .ps-section__top {
        margin-bottom: 40px;
    }

    .ps-section__desc {
        > * {
            margin-bottom: 20px;
        }
    }

    .ps-section__content {
        h4 {
            font-size: 24px;
        }
    }

    .ps-section__bottom {
        figure {
            margin-bottom: 25px;

            figcaption {
                font-weight: 500;
                color: var(--color-heading);
            }

            p {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .ps-section__top {
            display: grid;
            grid-template-columns: 355px 1fr;
            grid-column-gap: 30px;
            margin-bottom: 80px;
        }

        .ps-section__desc {
            > * {
                margin-bottom: 40px;
            }
        }

        .ps-section__content {


            h4 {
                font-size: 30px;
            }
        }

        .ps-section__bottom {
            figure {
                margin-bottom: 50px;

            }
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__top {
            grid-column-gap: 44px;
        }
    }


    @media screen and (max-width: 480px) {
        .ps-section__thumbnail {
            padding-bottom: 30px;
        }
    }
}

.ps-agent-reviews {
    .ps-form--agent-review {
        padding-top: 35px;
    }

    .ps-section__top {
        padding-bottom: 20px;

        h3 {
            margin-bottom: 25px;
            font-size: 24px;
        }

        p {
            strong {
                color: var(--color-success);
                margin-right: 10px;
                font-weight: 400;
            }

            span {
                font-size: 13px;
            }

            .ps-rating {
                margin-right: 10px;

                i {
                    font-size: 13px;
                }
            }
        }
    }

    .ps-section__content {
        .ps-block--agent-review {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid var(--color-border);

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .ps-form--agent-review {
            padding-top: 70px;
        }

        .ps-section__top {
            padding-bottom: 40px;

            h3 {
                margin-bottom: 40px;
                font-size: 30px;
            }

            p {
                font-size: 20px;
            }
        }
    }
}

.ps-section--single-project {
    .ps-widget--project {
        margin-bottom: 50px;
        padding: 24px;
        border-radius: var(--border-radius-medium);
        border: 1px solid var(--color-border);
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 355px;
        grid-column-gap: 30px;
        .ps-section__left {
            max-width: calc(1280px - 355px);
        }
    }


    @media screen and (min-width: 1366px) {
        grid-column-gap: 60px;
        grid-template-columns:  1fr 355px;

    }

    @media screen and (min-width: 1440px) {
        .ps-section__left {
            max-width: calc(1280px - 355px);
        }
    }

    @media screen and (min-width: 1600px) {
        grid-column-gap: 100px;
        .ps-section__left {
            max-width: calc(1510px - 355px);
        }
    }
}

.ps-home-search {
    min-height: calc(100vh + 80px);
    display: grid;
    position: relative;
    z-index: 10;

    &:before {
        content: '';
        @include max-area();
        z-index: -1;
        background-color: rgba(#000, 0.25);
    }

    .ps-section__stats {
        margin-bottom: 0;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        grid-column-gap: 30px;
        color: #ffffff;
        font-size: 14px;

        i {
            margin-right: 12px;
        }
    }

    .ps-section__top {
        padding-bottom: 40px;

        h3 {
            margin-bottom: 25px;
            font-weight: 600;
            font-size: 50px;
            color: #ffffff;
            line-height: 1.1em;
            text-transform: capitalize;
        }

        p {
            margin-bottom: 0;
            color: #fff;
        }
    }

    .ps-section__form {
        padding-bottom: 20px;
    }

    .ps-section__wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }

    @media screen and (min-width: 1024px) {

        .ps-section__content {
            max-width: 700px;
        }

        .ps-section__top {
            padding-bottom: 40px;

            h3 {
                margin-bottom: 10px;
                font-size: 50px;
                max-width: 500px;
            }

            p {
                margin-bottom: 0;
            }
        }

        .ps-section__form {
            padding-bottom: 40px;
        }
    }

    @media screen and (min-width: 1200px) {
        .ps-section__top {
            padding-bottom: 40px;

            h3 {
                font-size: 48px;
                max-width: 400px;
            }
        }

        .ps-section__wrapper {

            padding-bottom: 20px;
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__top {
            padding-bottom: 60px;
        }

        .ps-section__wrapper {
            align-items: center;
            padding-bottom: 20px;
        }
    }

    @media screen and (min-width: 1440px) {
        .ps-section__top {
            padding-bottom: 60px;

            h3 {
                font-size: 48px;
                max-width: 500px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media screen and (min-width: 1600px) {
        .ps-section__top {
            padding-bottom: 80px;

            h3 {
                font-size: 80px;
                max-width: 100%;
            }
        }
    }
}

.ps-submit-property {
    min-height: 500px;

    display: grid;
    text-align: center;

    .ps-btn {
        max-width: 300px;
        margin: 0 auto;
    }

    h3 {
        font-size: 36px;
        font-weight: 500;
        line-height: 1.2em;
        color: var(--color-heading);

        strong {
            font-weight: inherit;
            color: var(--color-success);
        }
    }

    p {
        line-height: 1.4em;
        margin-bottom: 40px;
    }

    .container {
        height: 100%;
        padding-top: 75px;
    }

    @media screen and (min-width: 768px) {
        .container {
            padding-top: 90px;
        }
        p {
            font-size: 18px;
            margin-bottom: 80px;
        }
        .ps-btn {
            height: 60px;
            font-size: 18px;
        }
    }

    @media screen and (min-width: 1024px) {

        .container {
            padding-top: 120px;
        }

        h3 {
            margin-bottom: 20px;
            font-size: 72px;
        }
    }

    @media screen and (min-width: 1366px) {

        .container {
            padding-top: 150px;
        }

        .ps-btn {
            height: 74px;
            font-size: 22px;
        }
    }

    @media screen and (min-width: 1680px) {
        min-height: 770px;

    }

    @media screen and (max-width: 480px) {
        h3 {
            margin: 0 auto 10px;
            max-width: 280px;
        }
        p {
            font-size: 14px;
        }
        .ps-btn {
            max-width: 240px;
        }
    }
}

.ps-trending-seacrch {
    .ps-section__links {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        grid-gap: 10px;

    }

    .ps-section__link {
        display: flex;
        align-items: center;
        padding: 0 20px;
        color: var(--color-text);
        border-radius: var(--border-radius-medium);
        background-color: #f0f5fb;
        height: 36px;

        &:hover {
            color: #ffffff;
            background-color: var(--color-1st);
        }
    }

    .ps-section__header {
        p {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        .ps-section__header {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .ps-section__heading {

                margin-right: 40px;
            }
        }
    }
}

.ps-section--home-posts {
    .ps-section__heading {
        font-weight: 400;
        font-size: 24px;
    }

    .ps-section__morelink {
        color: var(--color-text);

        i {
            margin-left: 1em;
        }

        &:hover {
            color: var(--color-1st);
        }
    }

    @media screen and (min-width: 768px) {
        .ps-section__heading {
            font-size: 36px;
        }

        .ps-section__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-section__heading {
            font-size: 48px;
        }
    }

    @media screen and (max-width: 479px) {
        .ps-section__heading {
            margin-bottom: 10px;
            font-weight: 500;
        }
    }
}

.ps-home-search-2 {
    display: grid;
    position: relative;
    z-index: 10;
    grid-template-columns: 1fr;
    text-align: center;

    > img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        object-fit: cover;
    }

    h3 {
        font-size: 48px;
        line-height: 1.1em;
    }

    .ps-section__top {
        margin-bottom: 30px;

        p {
            margin-bottom: 0;
        }
    }

    .ps-section__content {
        max-width: 1030px;
    }

    .ps-section__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding-top: 100px;

        .ps-section__content {
            flex-basis: 100%;
        }
    }

    @media screen and (min-width: 768px) {
        > img {
            min-height: 650px;
        }
    }

    @media screen and (min-width: 992px) {
        h3 {
            font-size: 48px;
        }
    }

    @media screen and (min-width: 1200px) {
        .ps-section__wrapper {
            padding-top: 220px;
        }
    }

    @media screen and (min-width: 1366px) {
        h3 {
            font-size: 60px;
        }

        .ps-section__top {
            p {
                font-size: 18px;
            }
        }
    }

    @media screen and (min-width: 1440px) {
        h3 {
            margin-bottom: 30px;
            font-size: 72px;
        }
        .ps-section__top {
            margin-bottom: 60px;
        }
    }

    @media screen and (max-width: 479px) {
        img {
            min-height: 100vh;
        }
        .ps-section__top {
            p {
                max-width: 260px;
                margin: 0 auto;
                font-size: 14px;
            }
        }
    }
}

.ps-testimonials-and-stats {
    .ps-site-stats, .ps-testimonials {
        padding: 50px 0;
    }

    @media screen and (min-width: 1040px) {
        .ps-site-stats, .ps-testimonials {
            padding: 80px 0;
        }
    }

    @media screen and (min-width: 1366px) {
        .ps-site-stats, .ps-testimonials {
            padding: 100px 0;
        }
    }

    @media screen and (max-width: 479px) {
        padding: 0 16px;
        .ps-testimonials {
            .ps-section__left {
                padding-bottom: 30px;
            }
        }
    }
}

.ps-project-listing {
    .ps-carousel {
        .owl-stage-outer {
            padding-bottom: 30px;
        }

        .owl-dots {
            display: none;
        }
    }

    &.with-bg {
        .ps-project--grid {
            border: none;

            &:hover {
                box-shadow: 0px 16px 20px 0 rgba(92, 117, 134, 0.15);
            }
        }
    }
}

.ps-agent-properties {

    .ps-section__links {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        grid-column-gap: 10px;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 16px;
            height: 32px;
            border-radius: var(--border-radius-medium);
            color: var(--color-text);

            &.active, &:hover {
                background-color: #f2f6f9;
                color: var(--color-1st);
            }
        }
    }

    .ps-section__header-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ps-section__heading {
            margin-right: 50px;
        }
    }


    @media screen and (min-width: 1024px) {
        .ps-section__heading {
            font-size: 30px;
        }

        .ps-section__header {
            padding-bottom: 45px;
            display: grid;
            grid-template-columns: 1fr 100px;
        }
    }
    @media screen and (max-width: 479px) {
        .ps-section__header-left {
            display: block;

            .ps-section__heading {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}
